import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import KTSVG from "../../components/SvgLoader";
import CircularProgress from "../../components/CircularProgress";
import axios from "../../util/Api";
import { Table } from "antd";

const QuickContacts = () => {
  const [contacts, setContacts] = useState([]);
  const [subjectTeachers, setSubjectTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const intl = useIntl();

  const subjectColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Subject Name",
      dataIndex: "subject_full_name",
      key: "subject_full_name",
    },
    {
      title: "Number 1",
      dataIndex: "number1",
      key: "number1",
    },
  ];

  const contactsColumn = [
    {
      title: "Contact",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
    },
    {
      title: "Number 1",
      dataIndex: "number1",
      key: "number1",
    },
    {
      title: "Number 2",
      dataIndex: "number2",
      key: "number2",
    },
  ];

  useEffect(() => {
    axios.post("communication/quick_contacts").then(({ data }) => {
      setLoading(false);
      if (data.result) {
        setContacts(data.quick_contacts);
        setSubjectTeachers(data.subject_teacher);
      }
    });
  }, []);

  return (
    <>
      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "communication" })}
              <span className="h-20px border-gray-200 border-start mx-4"></span>
              <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li className="breadcrumb-item text-dark">
                  {intl.formatMessage({ id: "quick_contacts" })}
                </li>
              </ul>
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            <div className={`card mb-5 mb-xxl-8 card-xxl-streatch`}>
              <div className="card-body pt-5">
                <div className="table-responsive">
                  <Table
                    className="mt-5"
                    rowKey={"id"}
                    dataSource={contacts}
                    columns={contactsColumn}
                    pagination={false}
                  />
                </div>
              </div>

              {subjectTeachers.length != 0 && (
                <div className="card-body pt-5">
                  <div className="table-responsive">
                    <Table
                      className="mb-5"
                      rowKey={"teacher_id"}
                      dataSource={subjectTeachers}
                      columns={subjectColumns}
                      pagination={false}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QuickContacts;

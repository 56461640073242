import React from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import PayFees from "./PayFees";
import PaymentHistory from "./PaymentHistory";
import StudentFees from "./StudentFees";

const OnlineFeePayment = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/pay-fees`} component={PayFees} />
      <Route path={`${match.url}/history/payment-history`} component={PaymentHistory} />
      <Route path={`${match.url}/history/student-fees`} component={StudentFees} />
      <Redirect exact from={`${match.url}/history`} to={`${match.url}/history/student-fees`} />
    </Switch>
  );
};

export default OnlineFeePayment;

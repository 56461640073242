import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import KTSVG from "../../components/SvgLoader";
import CircularProgress from "../../components/CircularProgress";
import axios from "../../util/Api";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Table, Modal } from "antd";
import moment from "moment";
import { toAbsoluteUrl } from "../../util/AssetHelper";

const StudentProfile = () => {
  const history = useHistory();
  const [student, setStudent] = useState({});
  const [loading, setLoading] = useState(true);
  const [fatherEmail, setFatherEmail] = useState("");
  const [motherEmail, setMotherEmail] = useState("");
  const [emailError, setEmailError] = useState({ value: false, message: "" });
  const [success, setSuccess] = useState({ value: false, message: "" });
  const [activeLink, setActiveLink] = useState("school");
  const intl = useIntl();

  useEffect(() => {
    axios.post("student_profile/get_student_profile").then(({ data }) => {
      setLoading(false);
      if (data.result) {
        setStudent(data.student_info);
        setFatherEmail(data.student_info.parent_email);
        setMotherEmail(data.student_info.mother_email);
      }
    });
  }, []);

  const changeParentsDetail = () => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setEmailError({
      value: false,
      message: "",
    });
    if (
      !emailRegex.test(String(fatherEmail).toLowerCase()) &&
      !emailRegex.test(String(motherEmail).toLowerCase())
    ) {
      setEmailError({
        value: true,
        message: "Parents email is not a valid email.",
      });
    } else if (!emailRegex.test(String(fatherEmail).toLowerCase())) {
      setEmailError({
        value: true,
        message: "Father email is not a valid email.",
      });
    } else if (!emailRegex.test(String(motherEmail).toLowerCase())) {
      setEmailError({
        value: true,
        message: "Mother email is not a valid email.",
      });
    } else {
      axios
        .post("student_profile/submit_form", {
          parent_email: fatherEmail,
          mother_email: motherEmail,
        })
        .then(({ data }) => {
          if (data.result) {
            setSuccess({ value: true, message: data.message.message });
            setTimeout(() => {
              setSuccess({ value: false, message: "" });
            }, 3000);
          }
        });
    }
  };

  const cancelForm = () => {
    setFatherEmail(student.parent_email);
    setMotherEmail(student.mother_email);
  };

  return (
    <>
      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "student_profile" })}
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            <div className="">
              <div className="d-flex flex-lg-row flex-column">
                <div
                  className="flex-row-fluid ms-lg-8 d-block"
                  id="kt-inbox-list"
                >
                  <div className="card card-custom card-stretch">
                    <div className="card-body">
                      <div className="py-1 d-flex flex-wrap flex-sm-no-wrap mb-5">
                        <div className="me-7">
                          <img
                            src={
                              student.student_profile_image == ""
                                ? toAbsoluteUrl("/media/avatars/blank.png")
                                : `${process.env.REACT_APP_STORAGE_PATH}/uploads/student/thumbs/${student.student_profile_image}`
                            }
                            alt="Profile"
                            height="125px"
                            width="125px"
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                          <div className="d-flex flex-column">
                            <div className="d-flex align-items-center mb-0">
                              <div className="mb-2 text-gray-800 fs-2 fw-bolder">
                                {student.full_name}
                              </div>
                            </div>
                            <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                              <div className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                <span>
                                  <KTSVG
                                    path="/media/icons/duotone/Design/Layers.svg"
                                    className="svg-icon-1 me-2"
                                  />
                                </span>
                                {student.class_title +
                                  " - " +
                                  student.section_title}
                              </div>
                              <div className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                <span>
                                  <KTSVG
                                    path="/media/icons/duotone/Map/Marker1.svg"
                                    className="svg-icon-1 me-2"
                                  />
                                </span>
                                House - {student.house_name}
                              </div>
                            </div>
                            <button
                              onClick={() => history.push("/auth/new-password")}
                              className="btn btn-light-primary"
                            >
                              Change Password
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex overflow-auto h-55px">
                        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                          <li className="nav-item">
                            <div
                              style={{ cursor: "pointer" }}
                              className={`nav-link text-active-primary me-6 ${
                                activeLink == "school" ? "active" : "flase"
                              }`}
                              onClick={() => setActiveLink("school")}
                            >
                              School Information
                            </div>
                          </li>
                          <li className="nav-item">
                            <div
                              style={{ cursor: "pointer" }}
                              className={`nav-link text-active-primary me-6 ${
                                activeLink == "personal" ? "active" : "flase"
                              }`}
                              onClick={() => setActiveLink("personal")}
                            >
                              Personal Information
                            </div>
                          </li>
                          <li className="nav-item">
                            <div
                              style={{ cursor: "pointer" }}
                              className={`nav-link text-active-primary me-6 ${
                                activeLink == "parent" ? "active" : "flase"
                              }`}
                              onClick={() => setActiveLink("parent")}
                            >
                              Parent Information
                            </div>
                          </li>
                          <li className="nav-item">
                            <div
                              style={{ cursor: "pointer" }}
                              className={`nav-link text-active-primary me-6 ${
                                activeLink == "parent_info" ? "active" : "flase"
                              }`}
                              onClick={() => setActiveLink("parent_info")}
                            >
                              Edit Information
                            </div>
                          </li>
                        </ul>
                      </div>
                      {activeLink == "school" && (
                        <div className="col-sm-8 px-5 pt-10">
                          <dl className="row">
                            <dt className="col-sm-3 py-3">Class-Section</dt>
                            <dd className="col-sm-9 py-3">
                              {student.class_title +
                                " - " +
                                student.section_title}
                            </dd>
                            <dt className="col-sm-3 py-3">Roll No.</dt>
                            <dd className="col-sm-9 py-3">
                              {student.student_roll_no}
                            </dd>
                            <dt className="col-sm-3 py-3">School Bus</dt>
                            <dd className="col-sm-9 py-3">
                              {`${
                                student.bus_title != null
                                  ? student.bus_title +
                                    " (" +
                                    student.bus_driver +
                                    ")"
                                  : ""
                              }`}
                            </dd>
                            <dt className="col-sm-3 py-3">Join Date</dt>
                            <dd className="col-sm-9 py-3">
                              {moment(student.student_join_date).format(
                                "Do MMM, YYYY"
                              )}
                            </dd>
                          </dl>
                        </div>
                      )}

                      {activeLink == "personal" && (
                        <div className="col-sm-8 px-5 pt-10">
                          <dl className="row">
                            <dt className="col-sm-3 py-3">Civil ID</dt>
                            <dd className="col-sm-9 py-3">
                              {student.student_civil_number}
                            </dd>
                            <dt className="col-sm-3 py-3">Gender</dt>
                            <dd className="col-sm-9 py-3">
                              {student.student_gender}
                            </dd>
                            <dt className="col-sm-3 py-3">Birth Date</dt>
                            <dd className="col-sm-9 py-3">
                              {moment(student.student_bdate).format(
                                "Do MMM, YYYY"
                              )}
                            </dd>
                          </dl>
                        </div>
                      )}

                      {activeLink == "parent" && (
                        <div className="col-sm-8 px-5 pt-10">
                          <dl className="row">
                            <dt className="col-sm-3 py-3">Father Name</dt>
                            <dd className="col-sm-9 py-3">
                              {student.parent_first_name +
                                " " +
                                student.parent_middle_name +
                                " " +
                                student.parent_last_name}
                            </dd>
                            <dt className="col-sm-3 py-3">
                              Father's Occupation
                            </dt>
                            <dd className="col-sm-9 py-3">
                              {student.parent_occupation}
                            </dd>
                            <dt className="col-sm-3 py-3">
                              Father's Profession
                            </dt>
                            <dd className="col-sm-9 py-3">
                              {student.parent_profession_name}
                            </dd>
                            <dt className="col-sm-3 py-3">
                              Father's Nationality
                            </dt>
                            <dd className="col-sm-9 py-3">
                              {student.parent_nationality}
                            </dd>
                            <dt className="col-sm-3 py-3">Father Contact</dt>
                            <dd className="col-sm-9 py-3">
                              {student.parent_contact}
                            </dd>
                            <dt className="col-sm-3 py-3">Mother Name</dt>
                            <dd className="col-sm-9 py-3">
                              {student.mother_first_name +
                                " " +
                                student.mother_middle_name +
                                " " +
                                student.mother_last_name}
                            </dd>
                            <dt className="col-sm-3 py-3">
                              Mother's Occupation
                            </dt>
                            <dd className="col-sm-9 py-3">
                              {student.mother_occupation}
                            </dd>
                            <dt className="col-sm-3 py-3">
                              Mother's Profession
                            </dt>
                            <dd className="col-sm-9 py-3">
                              {student.mother_profession_name}
                            </dd>
                            <dt className="col-sm-3 py-3">
                              Mother's Nationality
                            </dt>
                            <dd className="col-sm-9 py-3">
                              {student.mother_nationality}
                            </dd>
                            <dt className="col-sm-3 py-3">Home Address</dt>
                            <dd className="col-sm-9 py-3">
                              {student.contact_address}
                            </dd>
                            <dt className="col-sm-3 py-3">Telephone Number</dt>
                            <dd className="col-sm-9 py-3">
                              {student.emergency_contact_name +
                                " - " +
                                student.emergency_contact}
                            </dd>
                            <dt className="col-sm-3 py-3">Official Address</dt>
                            <dd className="col-sm-9 py-3">{student.address}</dd>
                          </dl>
                        </div>
                      )}

                      {activeLink == "parent_info" && (
                        <div className="col-sm-8  pt-10">
                          <h2 className="pb-5">Edit Parent's Information</h2>
                          <div className="row px-5">
                            <div className="col-md-6">
                              <div className="mb-3">Father's Detail</div>
                              <h6 className="mb-3 text-bold">Email*</h6>
                              <input
                                className="form-control mb-3"
                                placeholder={"Father's Email"}
                                onChange={(e) =>
                                  setFatherEmail(e.currentTarget.value)
                                }
                                value={fatherEmail}
                              />
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">Mother's Detail</div>
                              <h6 className="mb-3 text-bold">Email*</h6>
                              <input
                                className="form-control mb-3"
                                placeholder={"Mother's Email"}
                                onChange={(e) =>
                                  setMotherEmail(e.currentTarget.value)
                                }
                                value={motherEmail}
                              />
                            </div>
                          </div>
                          {emailError.value && (
                            <div className="text-white bg-danger mt-3 py-5 px-5 mx-5">
                              {emailError.message}
                            </div>
                          )}
                          {success.value && (
                            <div className="text-white bg-success mt-3 py-5 px-5 mx-5">
                              {success.message}
                            </div>
                          )}
                          <div className="pt-5 px-5">
                            <button
                              className="btn btn-primary me-2"
                              onClick={changeParentsDetail}
                            >
                              Submit
                            </button>
                            <button
                              className="btn btn-danger ms-2"
                              onClick={cancelForm}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StudentProfile;

import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import AppLocale from "../../lngProvider";
import MainApp from "./MainApp";
import {
  getUser,
  setInitUrl,
  setToken,
  userSignOut,
} from "../../appRedux/actions/Auth";
import AuthPage from "../Auth";

const App = (props) => {
  const dispatch = useDispatch();
  const { locale } = useSelector(({ settings }) => settings);
  const { loading } = useSelector(({ common }) => common);
  const { authUser, xRF25, initURL, token, unauthorized } = useSelector(
    ({ auth }) => auth
  );
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const currentAppLocale = AppLocale[locale.locale];

  useEffect(() => {
    if (initURL === "") {
      dispatch(setInitUrl(location.pathname));
    }
  });

  useEffect(() => {
    if (token) {
      dispatch(setToken(token));
      if (xRF25) {
        history.push("/auth/two-step-verification");
      } else if (unauthorized === true) {
        dispatch(userSignOut());
      }
    } else {
      history.push("/auth/signin");
    }
  }, [token, xRF25, unauthorized]);

  useEffect(() => {
    if (token) {
      dispatch(setToken(token));
    }
    if (token && authUser == null && (xRF25 == false || xRF25 == null)) {
      dispatch(getUser());
    }
  }, [dispatch, token, authUser, getUser, setToken]);

  useEffect(() => {
    if (loading == true) {
      document.getElementById("splash-screen").style.display = "flex";
    } else {
      setTimeout(function () {
        document.getElementById("splash-screen").style.display = "none";
      }, 500);
    }
  }, [loading]);

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <Switch>
        <Route path="/auth" component={AuthPage} />
        {authUser ? <Route path={`${match.url}`} component={MainApp} /> : <></>}
      </Switch>
    </IntlProvider>
  );
};

export default memo(App);

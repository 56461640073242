import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CircularProgress from "../../../components/CircularProgress";
import axios from "../../../util/Api";

const Timetable = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [timetable, setTimetable] = useState([]);
  const [workingDays, setWorkingDays] = useState([]);

  useEffect(() => {
    axios.post(`academics/timetable/view_timetable`).then(({ data }) => {
      setLoading(false);
      if (data.result) {
        setTimetable(data.teacher_timetable);
        setWorkingDays(data.working_days);
      }
    });
  }, []);

  return (
    <>
      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "academics" })}
              <span className="h-20px border-gray-200 border-start mx-4"></span>
              <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li className="breadcrumb-item text-dark">
                  {intl.formatMessage({ id: "timetable" })}
                </li>
              </ul>
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            <div className={`card mb-5 mb-xxl-8 card-xxl-streatch`}>
              <div className="card-body pt-5 mt-5">
                <div className="table-responsive">
                  <table
                    className="table align-middle table-striped fs-6 gy-5"
                    id="kt_assignment_table"
                  >
                    <thead className="bg-secondary fw-bold">
                      <tr>
                        <th className="ps-5">{"Time & Day"}</th>
                        <th className="ps-5">
                          {timetable[0]?.timetable_start_time +
                            " - " +
                            timetable[0]?.timetable_end_time}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="fw-bold text-gray-600">
                      {workingDays.map((data, index) => {
                        let dayData = timetable.find(
                          (t) => t.timetable_day === data
                        );
                        return (
                          <tr key={index}>
                            <td className="ps-5">
                              {data.charAt(0).toUpperCase() + data.slice(1)}
                            </td>
                            <td className="ps-5">
                              {dayData ? dayData.subject_title : ""}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Timetable;

import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import KTSVG from "../../components/SvgLoader";
import CircularProgress from "../../components/CircularProgress";
import axios from "../../util/Api";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Table, Modal } from "antd";
import moment from "moment";
import { toAbsoluteUrl } from "../../util/AssetHelper";

const Feedback = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [modules, setModules] = useState({});
  const [moduleTitle, setModuleTitle] = useState("0");
  const [feedback, setFeedback] = useState("");
  const [error, setError] = useState({ value: false, message: "" });
  const [success, setSuccess] = useState({ value: false, message: "" });
  const intl = useIntl();

  useEffect(() => {
    axios.post("feedback/view_feedback").then(({ data }) => {
      setLoading(false);
      if (data.result) {
        setModules(data.feedbackTitle);
      }
    });
  }, []);

  const submitFeedback = () => {
    setError({ value: false, message: "" });
    if (moduleTitle == "0" && feedback.trim() == "") {
      setError({
        value: true,
        message: "Both fields are required.",
      });
    } else if (moduleTitle == "0") {
      setError({
        value: true,
        message: "Module title is required.",
      });
    } else if (feedback.trim() == "") {
      setError({
        value: true,
        message: "Feedback is required.",
      });
    } else {
      axios
        .post("feedback/submit_feedback", {
          module_title: moduleTitle,
          feedback: feedback,
        })
        .then(({ data }) => {
          if (data.result) {
            setSuccess({ value: true, message: data.message });
            setTimeout(() => {
              setSuccess({ value: false, message: "" });
            }, 3000);
          }
        });
    }
  };

  const cancelForm = () => {
    setFeedback("");
    setModuleTitle("0");
  };

  return (
    <>
      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "feedback" })}
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            <div className={`card mb-5 mb-xxl-8 card-xxl-streatch`}>
              <div className="card-body py-10">
                <div className="col-md-6 pb-5">
                  <h6 className="mb-4 text-bold">Module Title*</h6>
                  <select
                    className="form-select form-select-solid fw-bolder select2-hidden-accessible"
                    onChange={(e) => setModuleTitle(e.currentTarget.value)}
                    value={moduleTitle}
                  >
                    <option value={"0"} data-select2-id="select2-data-3-dyom">
                      Select Module Title
                    </option>
                    {Object.keys(modules).map((data, index) => {
                      return (
                        <option key={index} value={modules[data].toString()}>
                          {modules[data]}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-6 pb-5">
                  <h6 className="mb-4 text-bold">Feedback*</h6>
                  <textarea
                    className="form-control"
                    placeholder={"Feedback"}
                    rows="3"
                    onChange={(e) => setFeedback(e.currentTarget.value)}
                    value={feedback}
                  />
                </div>
                {error.value && (
                  <div className="text-white bg-danger py-5 px-5 mb-5">
                    {error.message}
                  </div>
                )}
                {success.value && (
                  <div className="text-white bg-success py-5 px-5 mb-5">
                    {success.message}
                  </div>
                )}
                <div className="d-flex justify-content-start">
                  <button
                    className="btn btn-primary me-2"
                    onClick={submitFeedback}
                  >
                    Submit
                  </button>
                  <button className="btn btn-danger ms-2" onClick={cancelForm}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Feedback;

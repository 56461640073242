/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { fetchStart, fetchSuccess } from "../../appRedux/actions/Common";
import { userSignOut} from "../../appRedux/actions/Auth";
import axios from "../../util/Api";

const loginSchema = Yup.object().shape({
    one: Yup.string().required("Provide Valid Code"),
    two: Yup.string().required("Provide Valid Code"),
    three: Yup.string().required("Provide Valid Code"),
    four: Yup.string().required("Provide Valid Code"),
    five: Yup.string().required("Provide Valid Code"),
    six: Yup.string().required("Provide Valid Code"),
});

const emailSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
});

const initialValues = {
    one: "",
    two: "",
    three: "",
    four: "",
    five: "",
    six: "",
};

const emailInitial = {
    email: "",
};

export function TwoStepVerification() {
    const { loading } = useSelector(({ common }) => common);
    const { token } = useSelector(({ auth }) => auth);
    const dispatch = useDispatch();

    const history = useHistory();
    const intl = useIntl();
    const [message, setMessage] = useState(null);
    const [emails, setEmails] = useState({});
    const [isEmail, setIsEmail] = useState(true);

    const secondRef = useRef(null);
    const thirdRef = useRef(null);
    const fourthRef = useRef(null);
    const fifthRef = useRef(null);
    const sixRef = useRef(null);

    const emailFormik = useFormik({
        initialValues: emailInitial,
        validationSchema: emailSchema,
        onSubmit: (values, { setSubmitting }) => {
            dispatch(fetchStart());
            axios
                .post("auth/send_2fa_code", { email: values.email })
                .then(({ data }) => {
                    dispatch(fetchSuccess());
                    if (data.result) {
                        setSubmitting(false);
                        setIsEmail(false);
                    }
                });
        },
    });
    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            let code =
                values.one +
                values.two +
                values.three +
                values.four +
                values.five +
                values.six;
            dispatch(fetchStart());
            axios
                .post("auth/verify2FA", { two_factor_code: code })
                .then(({ data }) => {
                    dispatch(fetchSuccess());
                    setSubmitting(false);
                    if (data.result) {
                        localStorage.setItem("xRF25", null);
                        history.push("/");
                    } else {
                        setStatus(data.error);
                    }
                });
        },
    });

    const resendCode = () => {
        axios.post("auth/resend2FA").then(({ data }) => {
            if (data.result) {
                setMessage(data.message);
                setTimeout(function () {
                    setMessage(null);
                }, 1500);
            }
        });
    };

    useEffect(() => {
        if (token != null) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            axios.post("auth/get_2fa_emails").then(({ data }) => {
                if (data.result) {
                    setEmails(data.student);
                }
            }).catch((error) => {
                if(error.response.status === 401){
                    dispatch(userSignOut());
                }
            });
        }
    }, [token]);

    return (
        <>
            {isEmail ? (
                <form
                    className="form w-100"
                    onSubmit={emailFormik.handleSubmit}
                    noValidate
                    id="kt_login_signin_form"
                >
                    <div className="text-center mb-10">
                        <h1 className="text-dark mb-3">
                            {intl.formatMessage({ id: "select_email" })}
                        </h1>

                        <div className="text-gray-400 fw-bold fs-4">
                            {intl.formatMessage({
                                id: "please_select_email_for_auth_code",
                            })}
                        </div>
                    </div>

                    {emailFormik.status ? (
                        <div className="mb-lg-15 alert alert-danger">
                            <div className="alert-text font-weight-bold">
                                {emailFormik.status}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}

                    {message ? (
                        <div className="mb-10 bg-light-info p-8 rounded">
                            <div className="text-info">{message}</div>
                        </div>
                    ) : (
                        <></>
                    )}

                    <div className="fv-row mb-10">
                        <label className="form-label fw-bolder text-gray-900 fs-6">
                            {intl.formatMessage({ id: "email" })}
                        </label>

                        {emails.parent_email != "" ? (
                            <label className="form-check form-check-custom form-check-solid form-check-inline mb-4">
                                <input
                                    className="form-check-input"
                                    style={{ marginRight: 8 }}
                                    {...emailFormik.getFieldProps("email")}
                                    type="radio"
                                    value={emails.parent_email}
                                    name="email"
                                    label={emails.parent_email}
                                />
                                <span className="form-check-label fw-bold text-gray-700 fs-6 mb-0">
                                    {emails.parent_email}
                                </span>
                            </label>
                        ) : (
                            <></>
                        )}
                        {emails.mother_email != "" ? (
                            <label className="form-check form-check-custom form-check-solid form-check-inline mb-4">
                                <input
                                    className="form-check-input"
                                    style={{ marginRight: 8 }}
                                    {...emailFormik.getFieldProps("email")}
                                    type="radio"
                                    value={emails.mother_email}
                                    name="email"
                                    label={emails.mother_email}
                                />
                                <span className="form-check-label fw-bold text-gray-700 fs-6 mb-0">
                                    {emails.mother_email}
                                </span>
                            </label>
                        ) : (
                            <></>
                        )}
                        {emails.student_email != "" ? (
                            <label className="form-check form-check-custom form-check-solid form-check-inline mb-4">
                                <input
                                    className="form-check-input"
                                    style={{ marginRight: 8 }}
                                    {...emailFormik.getFieldProps("email")}
                                    type="radio"
                                    value={emails.student_email}
                                    name="email"
                                    label={emails.student_email}
                                />
                                <span className="form-check-label fw-bold text-gray-700 fs-6 mb-0">
                                    {emails.student_email}
                                </span>
                            </label>
                        ) : (
                            <></>
                        )}
                        {emailFormik.touched.email && emailFormik.errors.email && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">{emailFormik.errors.email}</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="text-center">
                        <button
                            type="submit"
                            id="kt_sign_in_submit"
                            className="btn btn-lg btn-primary  mb-5"
                            disabled={emailFormik.isSubmitting || !emailFormik.isValid}
                        >
                            {!loading && (
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: "submit" })}
                                </span>
                            )}
                            {loading && (
                                <span
                                    className="indicator-progress"
                                    style={{ display: "block" }}
                                >
                                    {intl.formatMessage({ id: "please_wait" })}
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            ) : (
                <form
                    className="form w-100"
                    onSubmit={formik.handleSubmit}
                    noValidate
                    id="kt_login_signin_form"
                >
                    <div className="text-center mb-10">
                        <h1 className="text-dark mb-3">
                            {intl.formatMessage({ id: "two_step_verification" })}
                        </h1>
                    </div>

                    {formik.status ? (
                        <div className="mb-lg-15 alert alert-danger">
                            <div className="alert-text font-weight-bold">{formik.status}</div>
                        </div>
                    ) : (
                        <></>
                    )}

                    {message ? (
                        <div className="mb-10 bg-light-info p-8 rounded">
                            <div className="text-info">{message}</div>
                        </div>
                    ) : (
                        <></>
                    )}

                    <div className="fv-row mb-10">
                        <label className="form-label fs-6 fw-bolder text-dark">
                            {intl.formatMessage({ id: "type_six_digit_code" })}
                        </label>

                        <div className="d-flex flex-wrap flex-stack">
                            <input
                                type="text"
                                data-inputmask="'mask': '9', 'placeholder': ''"
                                maxLength={1}
                                className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                                inputMode="text"
                                name="one"
                                {...formik.getFieldProps("one")}
                                onInput={(e) =>
                                    e.target.value.length == 1 ? secondRef.current.focus() : null
                                }
                            />
                            <input
                                ref={secondRef}
                                type="text"
                                data-inputmask="'mask': '9', 'placeholder': ''"
                                maxLength={1}
                                className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                                inputMode="text"
                                name="two"
                                {...formik.getFieldProps("two")}
                                onInput={(e) =>
                                    e.target.value.length == 1 ? thirdRef.current.focus() : null
                                }
                            />
                            <input
                                ref={thirdRef}
                                type="text"
                                data-inputmask="'mask': '9', 'placeholder': ''"
                                maxLength={1}
                                className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                                inputMode="text"
                                name="three"
                                {...formik.getFieldProps("three")}
                                onInput={(e) =>
                                    e.target.value.length == 1 ? fourthRef.current.focus() : null
                                }
                            />
                            <input
                                ref={fourthRef}
                                type="text"
                                data-inputmask="'mask': '9', 'placeholder': ''"
                                maxLength={1}
                                className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                                inputMode="text"
                                name="four"
                                {...formik.getFieldProps("four")}
                                onInput={(e) =>
                                    e.target.value.length == 1 ? fifthRef.current.focus() : null
                                }
                            />

                            <input
                                ref={fifthRef}
                                type="text"
                                data-inputmask="'mask': '9', 'placeholder': ''"
                                maxLength={1}
                                className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                                inputMode="text"
                                name="five"
                                {...formik.getFieldProps("five")}
                                onInput={(e) =>
                                    e.target.value.length == 1 ? sixRef.current.focus() : null
                                }
                            />
                            <input
                                ref={sixRef}
                                type="text"
                                data-inputmask="'mask': '9', 'placeholder': ''"
                                maxLength={1}
                                className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                                inputMode="text"
                                name="six"
                                {...formik.getFieldProps("six")}
                            />
                        </div>
                    </div>

                    <div className="text-center">
                        <button
                            type="submit"
                            id="kt_sign_in_submit"
                            className="btn btn-lg btn-primary  mb-5"
                            disabled={formik.isSubmitting || !formik.isValid}
                        >
                            {!loading && (
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: "submit" })}
                                </span>
                            )}
                            {loading && (
                                <span
                                    className="indicator-progress"
                                    style={{ display: "block" }}
                                >
                                    {intl.formatMessage({ id: "please_wait" })}
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            )}
                        </button>
                    </div>

                    <div className="text-center fw-bold fs-5">
                        <span className="text-muted me-1">
                            {intl.formatMessage({ id: "did_not_get_code" })}
                        </span>
                        <a
                            onClick={resendCode}
                            style={{ cursor: "pointer" }}
                            className="link-primary fw-bolder fs-5 me-1"
                        >
                            {intl.formatMessage({ id: "resend" })}
                        </a>
                    </div>
                </form>
            )}
        </>
    );
}

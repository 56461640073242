import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import BookFeeHistory from "./BookFeeHistory";
import BookFeePayment from "./BookFeePayment";

const BooksFeePayment = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/payments`} component={BookFeePayment} />
      <Route path={`${match.url}/history`} component={BookFeeHistory} />
    </Switch>
  );
};

export default BooksFeePayment;

import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import KTSVG from "../../components/SvgLoader";
import CircularProgress from "../../components/CircularProgress";
import axios from "../../util/Api";

const BookFeePayment = () => {
  const [siblingInfo, setSiblingInfo] = useState([]);
  const [siblingPaid, setSiblinPaid] = useState([]);
  const [textBookDisable, setTextBookDisable] = useState();
  const [noteBookDisable, setNoteBookDisable] = useState();
  const [available, setAvailable] = useState(false);
  const [fees, setFees] = useState([]);
  const [notebookFees, setNoteBookFees] = useState([]);
  const [totalNoteBookFees, setTotalNotebookFees] = useState(0.0);
  const [totalTextBookFees, setTotalTextBookFees] = useState(0.0);
  const [loading, setLoading] = useState(true);
  const intl = useIntl();

  const titles = {
    islamic: "Islamic",
    non_islamic: "Non Islamic",
    science: "Science",
    commerce: "Commerce",
    humanities: "Humanities",
  };

  useEffect(() => {
    axios.post("notebook/fee_payment").then(({ data }) => {
      setLoading(false);
      if (data.result) {
        setSiblingInfo(data.data.siblings_info);
        setSiblinPaid(data.data.siblings_paid);
        setAvailable(data.data.siblings_info.length != 0 ? true : false);
      }
    });
  }, []);

  const payBookFees = () => {
    let payments = {};
    let student = {};
    let feedata = {};
    fees.map((element) => {
      for (const key in element) {
        if (element.hasOwnProperty(key)) {
          const id = key.match(/\d+/g)[0];
          feedata["textbook"] = element[key];
          siblingInfo.map((si) => {
            if (si.student_id.toString() == id) {
              si.fees.map((d) => {
                feedata[d.type] = d.textbook;
              });
            }

            feedata["paying_class"] = si.student_class_id;

            notebookFees.map((e) => {
              for (const k in e) {
                if (e.hasOwnProperty(k)) {
                  if (k.includes(id)) {
                    feedata["notebook"] = e[k];
                  }
                }
              }
            });
          });
          student[id] = feedata;
        }
      }
      payments = student;
    });

    let data = {
      students: payments,
      total: (
        parseFloat(totalTextBookFees) + parseFloat(totalNoteBookFees)
      ).toFixed(3),
    };

    axios.post("notebook/pay_notebook_fees", data).then(({ data }) => {
      if (data.result) {
        window.open(data.payment_link, "_blank");
      }
    });
  };

  const dataRow = (data) => {
    if (siblingPaid[data.student_id] != null) {
      if (parseFloat(siblingPaid[data.student_id].textbook) > 0) {
        setTextBookDisable(siblingPaid[data.student_id].fee_type);
      }
      if (parseFloat(siblingPaid[data.student_id].notebook) > 0) {
        setNoteBookDisable(true);
      }
      return (
        <tr>
          <td className="bg-paid lh28">Paid Fees</td>
          <td className="bg-paid paid-text-labels">
            {siblingPaid[data.student_id].textbook > 0 ? (
              <span className="paid-text">
                {`${titles[siblingPaid[data.student_id].fee_type]} (${
                  siblingPaid[data.student_id].textbook
                }) `}
              </span>
            ) : siblingPaid[data.student_id].notebook > 0 ? (
              <span className="paid-text">
                {`Notebook (${titles[siblingPaid[data.student_id].notebook]})`}
              </span>
            ) : (
              <></>
            )}
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "book_fee_payment" })}
              <span className="h-20px border-gray-200 border-start mx-4"></span>
              <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li className="breadcrumb-item text-dark">
                  {intl.formatMessage({ id: "fee_payment" })}
                </li>
              </ul>
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            <div className={`card mb-5 mb-xxl-8 card-xxl-streatch`}>
              <div className="card-body">
                <div className="table-responsive">
                  <table
                    className="table table-bordered table-striped mt-3"
                    style={{ verticalAlign: "middle" }}
                  >
                    {siblingInfo.map((data, index) => {
                      return (
                        <tbody key={index}>
                          <tr>
                            <td
                              className="bg-primary"
                              colSpan={2}
                              style={{
                                marginTop: 15,
                                paddingBottom: 12,
                                paddingTop: 12,
                                paddingRight: 10,
                                paddingLeft: 10,
                              }}
                            >
                              <div className="row">
                                <div className="col md-2">
                                  <strong>Addmission No: </strong>
                                  {data.student_register_no}
                                </div>
                                <div className="col md-2">
                                  <strong>Student: </strong>
                                  {data.full_name}
                                </div>
                                <div className="col md-2">
                                  <strong>Branch: </strong>
                                  {data.branch_title}
                                </div>
                                <div className="col md-2">
                                  <strong>Class Section: </strong>
                                  {data.class_title + " " + data.section_title}
                                </div>
                                <div className="col md-2">
                                  <strong>Paying For Class: </strong>
                                  {data.paying[0].class_title}
                                </div>
                              </div>
                            </td>
                          </tr>
                          {siblingPaid != null && dataRow(data)}
                          <tr>
                            <td className="bg-secondary  ps-3">Textbook</td>
                            <td
                              className={`${
                                data.fees.length == 0 ? "bg-secondary" : ""
                              }`}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                {data.fees.map((feeData, index) => {
                                  if (feeData.type != textBookDisable) {
                                    return (
                                      <div key={index} className="ms-3">
                                        <label className="form-check form-check-custom form-check-solid form-check-sm">
                                          <input
                                            className="form-check-input"
                                            style={{ marginRight: 8 }}
                                            type="radio"
                                            value={feeData.type}
                                            checked={
                                              fees.findIndex(
                                                (x) =>
                                                  x[
                                                    `students[${data.student_id}][textbook]`
                                                  ] == feeData.type
                                              ) > -1
                                                ? true
                                                : false
                                            }
                                            name={`students[${data.student_id}][textbook]`}
                                            onChange={() => null}
                                            onClick={(e) => {
                                              let cloneFees = [...fees];
                                              const index = cloneFees.findIndex(
                                                (f) =>
                                                  f.hasOwnProperty(
                                                    e.target.name
                                                  )
                                              );

                                              const isInArray =
                                                cloneFees.findIndex(
                                                  (f) =>
                                                    f[e.target.name] ==
                                                    e.target.value
                                                );

                                              if (index > -1) {
                                                if (isInArray > -1) {
                                                  cloneFees = cloneFees.filter(
                                                    (x) =>
                                                      x[e.target.name] !=
                                                      e.target.value
                                                  );
                                                } else {
                                                  cloneFees[index][
                                                    e.target.name
                                                  ] = e.currentTarget.value;
                                                }
                                              } else {
                                                cloneFees.push({
                                                  [e.target.name]:
                                                    e.target.value,
                                                });
                                              }

                                              let totalTBFees = 0.0;

                                              cloneFees.forEach((element) => {
                                                for (const key in element) {
                                                  if (
                                                    element.hasOwnProperty(key)
                                                  ) {
                                                    const textbookType =
                                                      element[key];
                                                    const current = key;

                                                    const id =
                                                      current.match(/\d+/g)[0];

                                                    siblingInfo.map((data) => {
                                                      if (
                                                        data.student_id.toString() ==
                                                        id
                                                      ) {
                                                        data.fees.map((d) => {
                                                          if (
                                                            d.type ==
                                                            textbookType
                                                          ) {
                                                            totalTBFees +=
                                                              parseFloat(
                                                                d.textbook
                                                              );
                                                          }
                                                        });
                                                      }
                                                    });
                                                  }
                                                }
                                              });

                                              setTotalTextBookFees(totalTBFees);
                                              setFees(cloneFees);
                                            }}
                                          />
                                          <span className="form-check-label fw-bold text-gray-700 fs-6 mb-0">
                                            {`${titles[feeData.type]} (${
                                              feeData.textbook
                                            })`}
                                          </span>
                                        </label>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </td>
                          </tr>
                          {parseFloat(data.fees[0].notebook) > 0 ? (
                            !noteBookDisable ? (
                              <tr>
                                <td className="bg-secondary ps-3">
                                  Notebook (Optional)
                                </td>
                                <td>
                                  <label className="form-check form-check-custom form-check-solid form-check-sm ms-3">
                                    <input
                                      className="form-check-input"
                                      style={{ marginRight: 8 }}
                                      type="checkbox"
                                      name={`students[${data.student_id}][notebook]`}
                                      value={data.fees[0].notebook}
                                      onChange={(e) => {
                                        let cloneFees = [...notebookFees];

                                        const index = cloneFees.findIndex((f) =>
                                          f.hasOwnProperty(e.target.name)
                                        );

                                        if (index > -1) {
                                          let tempData = cloneFees.filter(
                                            (x) =>
                                              !x.hasOwnProperty(e.target.name)
                                          );
                                          cloneFees = tempData;
                                        } else {
                                          cloneFees.push({
                                            [e.target.name]: e.target.value,
                                          });
                                        }

                                        let totalNBFees = 0.0;

                                        cloneFees.forEach((element) => {
                                          for (const key in element) {
                                            if (element.hasOwnProperty(key)) {
                                              const feeValue = element[key];
                                              totalNBFees +=
                                                parseFloat(feeValue);
                                            }
                                          }
                                        });

                                        setTotalNotebookFees(totalNBFees);
                                        setNoteBookFees(cloneFees);
                                      }}
                                    />
                                    <span className="form-check-label fw-bold text-gray-700 fs-6 mb-0">
                                      {`Notebook (${parseFloat(
                                        data.fees[0].notebook
                                      ).toFixed(3)})`}
                                    </span>
                                  </label>
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )
                          ) : (
                            <></>
                          )}
                        </tbody>
                      );
                    })}
                  </table>
                </div>

                {!available ? (
                  <div className={"text-center"}>
                    <h3 className="text-warning">Caution!</h3>
                    <p>
                      Sorry! Book fees is not applicable to your class. Please
                      contact the school for further queries!
                    </p>
                  </div>
                ) : (
                  <></>
                )}

                {available ? (
                  <div
                    className="col-md-12 col-sm-12 text-center text-danger"
                    style={{ fontSize: 18 }}
                  >
                    After successful payment, the payment receipt will be sent
                    to the father's email
                  </div>
                ) : (
                  <></>
                )}

                {available ? (
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <h1 className="m-0">
                      Total Amount:{" "}
                      {(
                        parseFloat(totalTextBookFees) +
                        parseFloat(totalNoteBookFees)
                      ).toFixed(3)}
                    </h1>
                    <button
                      onClick={payBookFees}
                      className={`ms-3 btn btn-primary ${
                        fees.length != 0 || notebookFees.length != 0
                          ? ""
                          : "disabled"
                      }`}
                    >
                      Pay Book Fees
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BookFeePayment;

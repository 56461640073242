import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
      <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-bold me-1">Copyright &copy; 2021</span>
          <Link
            to="#"
            target="_blank"
            className="text-gray-800 text-hover-primary"
          >
            Hala Campus
          </Link>
          <span className="text-muted fw-bold me-1"> All rights reserved.</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";

const CircularProgress = ({ className }) => (
  <div
    className={`loader ${className}`}
    style={{
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <img src="/media/logos/icsk-logo.png" alt="ICSK logo" />
    <div className="mt-4" style={{ fontSize: 18 }}>
      Loading...
    </div>
  </div>
);
export default CircularProgress;

import React from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import AcademicReport from "./AcademicReport";
import Assignment from "./Assignment";
import Attendance from "./Attendance";
import ClassNotes from "./ClassNotes";
import Emm from "./Emm";
import Emm2 from "./Emm2";
import ReferenceMaterial from "./ReferenceMaterial";
import AnswerKey from "./StudentCorner/AnswerKey";
import QuestionPaper from "./StudentCorner/QuestionPaper";
import QuestionPattern from "./StudentCorner/QuestionPattern";
import Syllabus from "./StudentCorner/Syllabus";
import Timetable from "./Timetable";

const Academics = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/academic-report`} component={AcademicReport} />
      <Route path={`${match.url}/assignments`} component={Assignment} />
      <Route
        path={`${match.url}/reference-material`}
        component={ReferenceMaterial}
      />
      <Route path={`${match.url}/class-notes`} component={ClassNotes} />
      <Route path={`${match.url}/timetable`} component={Timetable} />
      <Route path={`${match.url}/attendance`} component={Attendance} />
      <Route path={`${match.url}/emm`} component={Emm} />
      <Route path={`${match.url}/emm2`} component={Emm2} />

      <Redirect
        from={`${match.url}/student-corner`}
        exact={true}
        to={`${match.url}/student-corner/syllabus`}
      />

      <Route
        path={`${match.url}/student-corner/syllabus`}
        component={Syllabus}
      />
      <Route
        path={`${match.url}/student-corner/question-paper`}
        component={QuestionPaper}
      />
      <Route
        path={`${match.url}/student-corner/answerkey`}
        component={AnswerKey}
      />
      <Route
        path={`${match.url}/student-corner/question-pattern`}
        component={QuestionPattern}
      />
    </Switch>
  );
};

export default Academics;

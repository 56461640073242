import { Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CircularProgress from "../../components/CircularProgress";
import KTSVG from "../../components/SvgLoader";
import axios from "../../util/Api";
import _ from "lodash";

const PtmFeedback = () => {
  const intl = useIntl();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [studentInfo, setStudentInfo] = useState({});
  const [feedback, setFeedback] = useState("");
  const [error, setError] = useState({ value: false, message: "" });
  const [submittedMessage, setSubmittedMessage] = useState("");

  useEffect(() => {
    axios.post(`ptm_feedback/view_ptm_feedback`).then(({ data }) => {
      setLoading(false);
      if (data.result) {
        setQuestions(data.questions);
        setStudentInfo(data.student);
      } else {
        setSubmittedMessage(data.message);
      }
    });
  }, []);

  const setAnswer = (e, id) => {
    let cloneData = [...selectedAnswers];
    let index = cloneData.findIndex((x) =>
      x.hasOwnProperty(e.currentTarget.name)
    );
    if (index > -1) {
      cloneData[index][e.currentTarget.name] = e.currentTarget.value;
      cloneData[index].key = id;
    } else {
      cloneData.push({
        [e.currentTarget.name]: e.currentTarget.value,
        key: id,
      });
    }
    setSelectedAnswers(cloneData);
  };

  const cancelForm = () => {
    setSelectedAnswers([]);
    setFeedback("");
  };

  const submitFeedback = () => {
    setError({ value: false, message: "" });
    if (questions.length != selectedAnswers.length) {
      setError({ value: true, message: "Select all questions." });
    } else {
      let answers = [];
      let sorted = _.sortBy(selectedAnswers, [
        function (o) {
          return o.key;
        },
      ]);

      sorted.map((data) => {
        for (const key in data) {
          if (key != "key") answers.push(data[key]);
        }
      });

      let data = {
        suggestion: feedback,
        qust: answers,
      };

      axios.post("ptm_feedback/submit_feedback", data).then(({ data }) => {
        if (data.result) {
          setSubmittedMessage(data.message);
        } else {
          setError({ value: true, message: data.message });
        }
      });
    }
  };

  return (
    <>
      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "parent_feedback" })}
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            <div className={`card mb-5 mb-xxl-8 card-xxl-streatch`}>
              <div className="card-body pt-5">
                {submittedMessage == "" ? (
                  <>
                    <div className="py-3">
                      <p>Dear parent</p>
                      <p>
                        Thank you for choosing ICSK for education of your ward.
                        In an endeavour to provide better quality education,
                        ICSK is striving to upgrade its practices. In one of the
                        steps to achieve this, we provide you with this feedback
                        form and request you to kindly rate the following with
                        tick mark [√] and drop it in the feedback box kept in
                        front of the office. Your feedback is valuable to us and
                        will be kept confidential. We appreciate your
                        participation!
                      </p>
                    </div>
                    <div className="py-3">
                      <h4>
                        FEEDBACK FORM FOR PARENTS (Kindly rate each criterion
                        with click)
                      </h4>
                    </div>
                    <div className="table-responsive">
                      <table
                        className="table align-middle table-striped fs-6 gy-5"
                        id="kt_assignment_table"
                      >
                        <thead className="fw-bold text-gray-600">
                          <tr>
                            <td>Sl.No.</td>
                            <td>CRITERIA</td>
                            <td>EXCELLENT</td>
                            <td>GOOD</td>
                            <td>AVERAGE</td>
                            <td>POOR</td>
                          </tr>
                        </thead>
                        <tbody>
                          {questions.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td className="ps-2">{index + 1}</td>
                                <td>{data.question}</td>
                                <td>
                                  {
                                    <label className="form-check form-check-custom form-check-solid form-check-sm">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={data.qid}
                                        value={"E"}
                                        onChange={(e) => setAnswer(e, index)}
                                        checked={
                                          selectedAnswers.findIndex(
                                            (x) =>
                                              x.hasOwnProperty(data.qid) &&
                                              x[data.qid] == "E"
                                          ) > -1
                                            ? true
                                            : false
                                        }
                                      />
                                    </label>
                                  }
                                </td>
                                <td>
                                  {
                                    <label className="form-check form-check-custom form-check-solid form-check-sm">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={data.qid}
                                        value={"G"}
                                        onChange={(e) => setAnswer(e, index)}
                                        checked={
                                          selectedAnswers.findIndex(
                                            (x) =>
                                              x.hasOwnProperty(data.qid) &&
                                              x[data.qid] == "G"
                                          ) > -1
                                            ? true
                                            : false
                                        }
                                      />
                                    </label>
                                  }
                                </td>
                                <td>
                                  {
                                    <label className="form-check form-check-custom form-check-solid form-check-sm">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={data.qid}
                                        value={"A"}
                                        onChange={(e) => setAnswer(e, index)}
                                        checked={
                                          selectedAnswers.findIndex(
                                            (x) =>
                                              x.hasOwnProperty(data.qid) &&
                                              x[data.qid] == "A"
                                          ) > -1
                                            ? true
                                            : false
                                        }
                                      />
                                    </label>
                                  }
                                </td>
                                <td>
                                  {
                                    <label className="form-check form-check-custom form-check-solid form-check-sm">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={data.qid}
                                        value={"P"}
                                        onChange={(e) => setAnswer(e, index)}
                                        checked={
                                          selectedAnswers.findIndex(
                                            (x) =>
                                              x.hasOwnProperty(data.qid) &&
                                              x[data.qid] == "P"
                                          ) > -1
                                            ? true
                                            : false
                                        }
                                      />
                                    </label>
                                  }
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-6 py-5">
                      <h6 className="mb-4 text-bold">
                        Concern/Specific issues/Suggestion, if any:
                      </h6>
                      <textarea
                        className="form-control"
                        placeholder={"Enter..."}
                        rows="3"
                        onChange={(e) => setFeedback(e.currentTarget.value)}
                        value={feedback}
                      />
                    </div>

                    {error.value && (
                      <div className="text-white bg-danger col-md-6 py-5 px-5 mb-5">
                        {error.message}
                      </div>
                    )}

                    <div className="d-flex justify-content-start">
                      <button
                        className="btn btn-primary me-2"
                        onClick={submitFeedback}
                      >
                        Submit
                      </button>
                      <button
                        className="btn btn-danger ms-2"
                        onClick={cancelForm}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="mt-10 mb-3 d-flex">
                      <div className="d-flex col-md-4">
                        <h5>Parent’s/Guardian’s name: </h5>
                        <span className="ps-3 text-danger">
                          {studentInfo.parentname}
                        </span>
                      </div>
                      <div className="d-flex col-md-4">
                        <h5>Profession: </h5>
                        <span className="ps-3 text-danger">
                          {studentInfo.parent_occupation}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="d-flex col-md-4">
                        <h5>Name of the Student: </h5>
                        <span className="ps-3 text-danger">
                          {studentInfo.full_name}
                        </span>
                      </div>
                      <div className="d-flex col-md-4">
                        <h5>Class/ Division: </h5>
                        <span className="ps-3 text-danger">
                          {studentInfo.cls_sec}
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <h2 className="text-info text-center pt-5">
                    {submittedMessage}
                  </h2>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PtmFeedback;

import { DatePicker, Table } from "antd";
import moment from "../../../util/moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CircularProgress from "../../../components/CircularProgress";
import KTSVG from "../../../components/SvgLoader";
import axios from "../../../util/Api";
import { Link } from "react-router-dom";

const AcademicReport = () => {
  const intl = useIntl();
  const [tableLoader, setTableLoader] = useState(true);
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [tutionFeeMessage, setTutionFeeMessage] = useState("");
  const [transportFeeMessage, setTransportFeeMessage] = useState("");

  const columns = [
    {
      title: "Sl.No.",
      dataIndex: "sl_no",
      key: "sl_no",
      render: (index) => index + 1,
    },
    {
      title: "Exam Code",
      dataIndex: "exam_code",
      key: "exam_code",
    },
    {
      title: "Exam",
      dataIndex: "exam_title",
      key: "exam_title",
    },
    {
      title: "Print Report Card",
      render: (data) => {
        return data.display_pdf == "1" ? (
          <a
            className="btn btn-danger"
            target="_blank"
            href={`${process.env.REACT_APP_STORAGE_PATH}/storage/${data.pdf_link}`}
          >
            <span>Generate PDF</span>
          </a>
        ) : (
          <></>
        );
      },
    },
  ];

  useEffect(() => {
    axios.post(`academics/academic_report/list`).then(({ data }) => {
      setLoading(false);
      setTableLoader(false);
      if (data.result) {
        let examInfos = data.exam_infos;
        let previousExams = data.previous_exams_old;
        examInfos.map((d, i) => {
          previousExams.map((x, ind) => {
            if (x.exam_id == d.exam_id) {
              x["sl_no"] = i;
            }
          });
          d["sl_no"] = i;
        });
        let allReports = [...examInfos, ...previousExams];
        allReports.map((x, i) => {
          x["id"] = i;
        });
        setReports(allReports);
        setTutionFeeMessage(data.tution_fee);
        setTransportFeeMessage(data.transport_fee);
      }
    });
  }, []);

  return (
    <>
      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "academics" })}
              <span className="h-20px border-gray-200 border-start mx-4"></span>
              <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li className="breadcrumb-item text-dark">
                  {intl.formatMessage({ id: "academic_report" })}
                </li>
              </ul>
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            <div className={`card mb-5 mb-xxl-8 card-xxl-streatch`}>
              <div className="card-body">
                <div className="table-responsive py-3">
                  <Table
                    rowKey={"id"}
                    dataSource={reports}
                    columns={columns}
                    loading={tableLoader}
                    pagination={false}
                  />
                </div>
                {tutionFeeMessage != "" && (
                  <div className="d-flex justify-content-center pt-5">
                    <h3 className="text-danger">{tutionFeeMessage}</h3>
                  </div>
                )}
                {transportFeeMessage != "" && (
                  <div className="d-flex justify-content-center pt-5">
                    <h3 className="text-danger">{transportFeeMessage}</h3>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AcademicReport;

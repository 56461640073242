/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import axios from "../../util/Api";
import { fetchStart, fetchSuccess } from "../../appRedux/actions/Common";

const loginSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  confirm_password: Yup.string().required("Confirm password is required"),
  one: Yup.string().required("Provide Valid Code"),
  two: Yup.string().required("Provide Valid Code"),
  three: Yup.string().required("Provide Valid Code"),
  four: Yup.string().required("Provide Valid Code"),
  five: Yup.string().required("Provide Valid Code"),
  six: Yup.string().required("Provide Valid Code"),
});

const initialValues = {
  password: "",
  confirm_password: "",
  one: "",
  two: "",
  three: "",
  four: "",
  five: "",
  six: "",
};

export function ForgotPasswordVerifyCode() {
  const { loading } = useSelector(({ common }) => common);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const intl = useIntl();

  const secondRef = useRef(null);
  const thirdRef = useRef(null);
  const fourthRef = useRef(null);
  const fifthRef = useRef(null);
  const sixRef = useRef(null);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      let code =
        values.one +
        values.two +
        values.three +
        values.four +
        values.five +
        values.six;
      if (values.password === values.confirm_password) {
        dispatch(fetchStart());
        axios
          .post("auth/forgot_password_verify_code", {
            access_key: location.state.access_key,
            two_factor_code: code,
            password: values.password,
          })
          .then(({ data }) => {
            dispatch(fetchSuccess());
            setSubmitting(false);
            if (data.result) {
              history.push("/auth/signin");
            } else {
              setStatus(data.message);
            }
          });
      } else {
        setSubmitting(false);
        formik.setFieldError(
          "confirm_password",
          "Confirm password must match with password"
        );
      }
    },
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">
          {intl.formatMessage({ id: "set_up_new_password" })}
        </h1>
        <div className="text-gray-400 fw-bold fs-4">
          {intl.formatMessage({ id: "already_reset_password" })}
          <Link
            to="/auth/signin"
            className="link-primary fw-bolder"
            style={{ marginLeft: 5 }}
          >
            {intl.formatMessage({ id: "sign_in_here" })}
          </Link>
        </div>
      </div>

      {formik.status ? (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      ) : (
        <></>
      )}

      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bolder text-dark">
          {intl.formatMessage({ id: "type_six_digit_code" })}
        </label>

        <div className="d-flex flex-wrap flex-stack">
          <input
            type="text"
            data-inputmask="'mask': '9', 'placeholder': ''"
            maxLength={1}
            className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
            inputMode="text"
            name="one"
            {...formik.getFieldProps("one")}
            onInput={(e) =>
              e.target.value.length == 1 ? secondRef.current.focus() : null
            }
          />
          <input
            ref={secondRef}
            type="text"
            data-inputmask="'mask': '9', 'placeholder': ''"
            maxLength={1}
            className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
            inputMode="text"
            name="two"
            {...formik.getFieldProps("two")}
            onInput={(e) =>
              e.target.value.length == 1 ? thirdRef.current.focus() : null
            }
          />
          <input
            ref={thirdRef}
            type="text"
            data-inputmask="'mask': '9', 'placeholder': ''"
            maxLength={1}
            className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
            inputMode="text"
            name="three"
            {...formik.getFieldProps("three")}
            onInput={(e) =>
              e.target.value.length == 1 ? fourthRef.current.focus() : null
            }
          />
          <input
            ref={fourthRef}
            type="text"
            data-inputmask="'mask': '9', 'placeholder': ''"
            maxLength={1}
            className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
            inputMode="text"
            name="four"
            {...formik.getFieldProps("four")}
            onInput={(e) =>
              e.target.value.length == 1 ? fifthRef.current.focus() : null
            }
          />
          <input
            ref={fifthRef}
            type="text"
            data-inputmask="'mask': '9', 'placeholder': ''"
            maxLength={1}
            className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
            inputMode="text"
            name="five"
            {...formik.getFieldProps("five")}
            onInput={(e) =>
              e.target.value.length == 1 ? sixRef.current.focus() : null
            }
          />
          <input
            ref={sixRef}
            type="text"
            data-inputmask="'mask': '9', 'placeholder': ''"
            maxLength={1}
            className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
            inputMode="text"
            name="six"
            {...formik.getFieldProps("six")}
          />
        </div>
      </div>

      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bolder text-dark">
          {intl.formatMessage({ id: "password" })}
        </label>
        <input
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.password && formik.errors.password },
            {
              "is-valid": formik.touched.password && !formik.errors.password,
            }
          )}
          type="password"
          name="password"
          autoComplete="off"
        />

        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.password}</span>
          </div>
        )}
      </div>

      <div className="fv-row mb-10">
        <div className="d-flex justify-content-between mt-n5">
          <div
            className="d-flex flex-row mb-2"
            style={{ width: "100%", justifyContent: "space-between" }}
          >
            <label className="form-label fw-bolder text-dark fs-6 mb-0">
              {intl.formatMessage({ id: "confirm_password" })}
            </label>
          </div>
        </div>
        <input
          type="password"
          autoComplete="off"
          {...formik.getFieldProps("confirm_password")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid":
                formik.touched.confirm_password &&
                formik.errors.confirm_password,
            },
            {
              "is-valid":
                formik.touched.confirm_password &&
                !formik.errors.confirm_password,
            }
          )}
        />
        {formik.touched.confirm_password && formik.errors.confirm_password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.confirm_password}</span>
            </div>
          </div>
        )}
      </div>

      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-primary  mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {loading ? (
            <span className="indicator-progress" style={{ display: "block" }}>
              {intl.formatMessage({ id: "please_wait" })}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          ) : (
            <span className="indicator-label">
              {intl.formatMessage({ id: "submit" })}
            </span>
          )}
        </button>
      </div>
    </form>
  );
}

import {
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  UNAUTHORIZED,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  token: JSON.parse(localStorage.getItem("token")),
  initURL: "",
  acdmYear: null,
  ptm_enable: null,
  is_discover: null,
  authUser: JSON.parse(localStorage.getItem("user")),
  xRF25: JSON.parse(localStorage.getItem("xRF25")),
  siblings: null,
  unauthorized: null,
  acdm_years: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_URL: {
      return { ...state, initURL: action.payload };
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        xRF25: null,
        initURL: "",
        unauthorized: null,
        acdmYear: null,
        ptm_enable: null,
        is_discover: null,
        siblings: null,
        acdm_years: null,
      };
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
        acdmYear: action.acd_year,
        ptm_enable: action.ptm,
        is_discover: action.discover,
        siblings: action.sibling,
        acdm_years: action.acdmYears,
      };
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload.token.access_token,
        xRF25: action.payload["2fa"],
        unauthorized: false,
      };
    }

    case UNAUTHORIZED: {
      return {
        ...state,
        unauthorized: true,
      };
    }

    default:
      return state;
  }
};

import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import KTSVG from "../../components/SvgLoader";
import CircularProgress from "../../components/CircularProgress";
import axios from "../../util/Api";
import { Link } from "react-router-dom";

import { Table, Modal } from "antd";
import moment from "moment";

const News = () => {
  const intl = useIntl();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popupData, setPopUpData] = useState({});
  const [modalShow, setModalShow] = useState(false);

  const [tableLoader, setTableLoader] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [query, setQuery] = useState("");
  const pageLimit = process.env.REACT_APP_TABLE_PAGE_SIZE;

  const columns = [
    {
      title: "Sr.No.",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (date) => {
        return moment(date).format("Do MMM, YYYY h:mm A");
      },
    },
    {
      title: "Attachement",
      render: (data) => {
        return data.attachment != "" ? (
          <a
            href={`${process.env.REACT_APP_STORAGE_PATH}/storage/download/news/${data.attachment}`}
            download
            target="_blank"
            className="btn btn-icon btn-flex btn-light-primary btn-sm me-1"
          >
            <KTSVG
              path="/media/icons/duotone/Files/Download.svg"
              className="svg-icon-3"
            />
          </a>
        ) : (
          <></>
        );
      },
    },
    {
      title: "Action",
      render: (data) => {
        return (
          <Link
            to="#"
            onClick={() => newsPopupShow(data)}
            className="btn btn-icon btn-flex btn-light-primary btn-sm me-1"
          >
            <KTSVG
              path="/media/icons/duotone/General/Visible.svg"
              className="svg-icon-3"
            />
          </Link>
        );
      },
    },
  ];

  useEffect(() => {
    setTableLoader(true);
    getData(1, query);
  }, []);

  const getData = (page, query) => {
    axios
      .post(`communication/news?page=${page}`, {
        search: query,
        limit: pageLimit,
      })
      .then(({ data }) => {
        setLoading(false);
        setTableLoader(false);
        if (data.result) {
          setNews(data.news.data);
          setTotalRecord(data.news.total);
        }
      });
  };

  const searchRecord = (query) => {
    setQuery(query);
    if (query.length > 2) {
      setTableLoader(true);
      getData(1, query);
    } else if (query.length == 0) {
      setTableLoader(true);
      getData(1, query);
    }
  };

  const handleTableChange = (pagination) => {
    setTableLoader(true);
    getData(pagination.current, query);
  };

  const newsPopupShow = (data) => {
    axios.post("communication/news_popup", { id: data.id }).then(({ data }) => {
      setPopUpData(data.news_info);
      setModalShow(true);
    });
  };

  return (
    <>
      <Modal
        title="View News Info"
        visible={modalShow}
        onCancel={() => setModalShow(false)}
        footer={null}
      >
        <div className="table-responsive">
          <table
            className="table align-middle table-striped fs-6 gy-5"
            id="kt_assignment_table"
          >
            <tbody className="fw-bold text-gray-600">
              <tr>
                <td>{"Title"}</td>
                <td>{popupData.subject}</td>
              </tr>
              <tr>
                <td>{"Attachment"}</td>
                <td>
                  {popupData.attachment ? (
                    <a
                      href={`${process.env.REACT_APP_STORAGE_PATH}/storage/download/news/${popupData.attachment}`}
                      download
                      target="_blank"
                      className="btn btn-icon btn-flex btn-light-primary btn-sm me-1"
                    >
                      <KTSVG
                        path="/media/icons/duotone/Files/Download.svg"
                        className="svg-icon-3"
                      />
                    </a>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
              <tr>
                <td>{"Description"}</td>
                <td
                  dangerouslySetInnerHTML={{ __html: popupData.description }}
                />
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>

      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "communication" })}
              <span className="h-20px border-gray-200 border-start mx-4"></span>
              <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li className="breadcrumb-item text-dark">
                  {intl.formatMessage({ id: "news" })}
                </li>
              </ul>
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            <div className={`card mb-5 mb-xxl-8 card-xxl-streatch`}>
              <div className="card-header border-0 pt-6">
                <div className="card-title">
                  <div className="d-flex align-items-center position-relative my-1">
                    <span className="svg-icon svg-icon-1 position-absolute ms-6">
                      <KTSVG
                        path="/media/icons/duotone/General/Search.svg"
                        className="svg-icon-1"
                      />
                    </span>

                    <input
                      type="text"
                      data-kt-assignment-table-filter="search"
                      className="form-control form-control-solid w-250px ps-15"
                      placeholder={intl.formatMessage({
                        id: "search",
                      })}
                      onChange={(e) => searchRecord(e.currentTarget.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="card-body pt-5">
                <div className="table-responsive">
                  <Table
                    rowKey={"id"}
                    dataSource={news}
                    columns={columns}
                    onChange={handleTableChange}
                    loading={tableLoader}
                    pagination={{
                      total: totalRecord,
                      defaultPageSize: pageLimit,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default News;

import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  UNAUTHORIZED,
} from "../../constants/ActionTypes";
import axios from "../../util/Api";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const userSignIn = (data) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    axios
      .post("auth/login", {
        student_username: data.admissionNumber,
        student_password: data.password,
        remember_me: data.remember_me ? 1 : 0,
      })
      .then(({ data }) => {
        if (data.result) {
          localStorage.setItem(
            "token",
            JSON.stringify(data.token.access_token)
          );
          localStorage.setItem("acdm_year", data.acd_year);
          if (data["2fa"] == false) {
            localStorage.setItem("xRF25", false);
          } else {
            localStorage.setItem("xRF25", true);
          }
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + data.token.access_token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("auth/me")
      .then(({ data }) => {
        if (data.result) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: USER_DATA,
            payload: data.user,
            acd_year: data.acd_year,
            ptm: data.ptm_enable,
            discover: data.isdiscover,
            sibling: data.student_sibling,
            acdmYears: data.my_acdm_years,
          });
        } else {
          console.log(data.error);
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        if (error.response.status === 401) {
          dispatch({ type: UNAUTHORIZED });
          dispatch({
            type: FETCH_ERROR,
            payload: "Session is not valid or expired!",
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: error.message });
        }
      });
  };
};

export const userSignOut = () => {
  return (dispatch) => {
    //dispatch({ type: FETCH_START });
    dispatch({ type: SIGNOUT_USER_SUCCESS });
    localStorage.removeItem("token");
    localStorage.removeItem("xRF25");
    localStorage.removeItem("acdm_year");
    /* axios
          .post("auth/logout")
          .then(({ data }) => {
            console.log("log out", data);
            if (data.result) {
              localStorage.removeItem("token");
              localStorage.removeItem("xRF25");
              dispatch({ type: FETCH_SUCCESS });
            } else {
              dispatch({ type: FETCH_ERROR, payload: data.error });
            }
          })
          .catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            console.log("Error****:", error.message);
          }); */
  };
};

export const setTokenData = (data) => {
  return (dispatch) => {
    dispatch({ type: USER_TOKEN_SET, payload: data });
  };
};

export const setToken = (token) => {
  return (dispatch) => {
    axios.defaults.headers.common["acd_year"] =
      localStorage.getItem("acdm_year");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  };
};

import React from "react";
import { useRouteMatch } from "react-router-dom";
import App from "../../routes/index";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";

const MainApp = () => {
  const match = useRouteMatch();

  return (
    <div className="d-flex flex-column flex-root" style={{ height: "100%" }}>
      <div className="page d-flex flex-row flex-column-fluid">
        <Sidebar />
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <Header />
          <div
            id="kt_content"
            className="content d-flex flex-column flex-column-fluid"
          >
            <App match={match} />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default MainApp;

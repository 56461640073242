import { SWITCH_LANGUAGE, SWITCH_THEME } from "../../constants/ActionTypes";

const initialSettings = {
  isDirectionRTL: false,
  locale: {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us",
  },
  theme: "light",
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
      };
    case SWITCH_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    default:
      return state;
  }
};

export default settings;

import { DatePicker, Table } from "antd";
import moment from "../../../util/moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CircularProgress from "../../../components/CircularProgress";
import KTSVG from "../../../components/SvgLoader";
import axios from "../../../util/Api";
import { Link } from "react-router-dom";

const QuestionPaper = () => {
  const intl = useIntl();
  const [tableLoader, setTableLoader] = useState(true);
  const [loading, setLoading] = useState(true);
  const [subjects, setSubjects] = useState([]);

  /* const columns = [
    {
      title: "Subject Name",
      dataIndex: "id",
      key: "id",
      render: (date) => {
        return moment(date).format("Do MMM, YYYY h:mm A");
      },
    },
    {
      title: "Download",
      render: (data) => {
        return;
      },
    },
  ]; */

  useEffect(() => {
    axios.post(`academics/student_corner/question_paper`).then(({ data }) => {
      setLoading(false);
      setTableLoader(false);
      if (data.result) {
        setSubjects(data.subject_list);
      }
    });
  }, []);

  return (
    <>
      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "academics" })}
              <span className="h-20px border-gray-200 border-start mx-4"></span>
              <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li className="breadcrumb-item text-dark">
                  {intl.formatMessage({ id: "student_corner" })}
                </li>
                <li className="breadcrumb-item px-3">
                  <span className="bullet bg-gray-200 w-5px h-2px"></span>
                </li>
                <li className="breadcrumb-item text-dark">
                  {intl.formatMessage({ id: "question_paper" })}
                </li>
              </ul>
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            <div className={`card mb-5 mb-xxl-8 card-xxl-streatch`}>
              <div className="card-toolbar px-10 py-5">
                <div className="d-flex overflow-auto h-55px">
                  <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                    <li className="nav-item">
                      <Link
                        className={`nav-link text-active-primary me-6 false false`}
                        to="/academics/student-corner/syllabus"
                      >
                        Syllabus
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link text-active-primary me-6 active`}
                        to="/academics/student-corner/question-paper"
                      >
                        Past Question Papers
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link text-active-primary me-6 false`}
                        to="/academics/student-corner/answerkey"
                      >
                        Sample Question Papers
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link text-active-primary me-6 false`}
                        to="/academics/student-corner/question-pattern"
                      >
                        Question Pattern
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="card-body pt-5">
                <div className="table-responsive">
                  <table className="table table-bordered table-stripedfs-6 gy-5">
                    <thead>
                      <tr className="bg-light">
                        <th className="ps-5">Subject Name</th>
                        <th>Download</th>
                      </tr>
                    </thead>

                    {subjects.map((d, i) => {
                      if (d.corner_data.length != 0) {
                        return (
                          <tbody className="fw-bold text-gray-600" key={i}>
                            <tr className="bg-light-primary">
                              <td className="text-primary ps-5" colSpan={2}>
                                {d.subject_full_name}
                              </td>
                            </tr>
                            {d.corner_data.map((data, index) => {
                              let action =
                                data.exam_id > 0
                                  ? data.exam_code != ""
                                    ? "(" +
                                      data.exam_title +
                                      " - " +
                                      data.exam_code +
                                      ")"
                                    : "(Evaluation " + data.exam_id + ")"
                                  : "";
                              return (
                                <tr key={index}>
                                  <td className="ps-10">
                                    - {data.title + " " + action}
                                  </td>
                                  <td>
                                    {data.attachments.map((d, i) => {
                                      if (
                                        d.file_exist == 1 &&
                                        d.attachment != ""
                                      ) {
                                        return (
                                          <a
                                            key={i}
                                            href={`${process.env.REACT_STORAGE_PATH}/uploads/student-corner/${d.attachment}`}
                                            download
                                            target="_blank"
                                            className="btn btn-icon btn-flex btn-light-primary btn-sm me-1"
                                          >
                                            <KTSVG
                                              path="/media/icons/duotone/Files/Download.svg"
                                              className="svg-icon-3"
                                            />
                                          </a>
                                        );
                                      }
                                    })}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        );
                      }
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QuestionPaper;

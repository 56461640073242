import { DatePicker, Table } from "antd";
import moment from "../../../util/moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CircularProgress from "../../../components/CircularProgress";
import KTSVG from "../../../components/SvgLoader";
import axios from "../../../util/Api";

const Attendance = () => {
  const intl = useIntl();
  const [tableLoader, setTableLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attendances, setAttendances] = useState([]);
  const [student, setStudent] = useState({});
  const [date, setDate] = useState([]);
  const { RangePicker } = DatePicker;

  const columns = [
    {
      title: "Date",
      dataIndex: "att_date",
      key: "att_date",
      width: "70%",
      render: (date) => {
        return moment(date).format("D MMM, YYYY");
      },
    },
    {
      title: "Attendance",
      width: "30%",
      render: (data) => {
        return data.presents.includes(student.student_id) ? (
          <div className="text-success">Present</div>
        ) : (
          <div className="text-danger">Absent</div>
        );
      },
    },
  ];

  useEffect(() => {
    setDate([moment().startOf("month"), moment().endOf("month")]);
  }, []);

  const getAttendance = () => {
    if (date.length != 0) {
      setLoading(true);
      setTableLoader(true);
      axios
        .post(`academics/attendance/get_attendance`, {
          attendance_start_date: moment(date[0]).format("D-M-YYYY"),
          attendance_end_date: moment(date[1]).format("D-M-YYYY"),
        })
        .then(({ data }) => {
          setLoading(false);
          setTableLoader(false);
          if (data.result) {
            let attData = data.attendances.map((d, i) => {
              d["id"] = i;
              return d;
            });
            setAttendances(attData);
            setStudent(data.student);
          }
        });
    }
  };

  return (
    <>
      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "academics" })}
              <span className="h-20px border-gray-200 border-start mx-4"></span>
              <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li className="breadcrumb-item text-dark">
                  {intl.formatMessage({ id: "attendance" })}
                </li>
              </ul>
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            <div className={`card mb-5 mb-xxl-8 card-xxl-streatch`}>
              <div className="card-header border-0 pt-6">
                <div className="card-title">
                  <div className="d-flex align-items-center position-relative my-1">
                    <RangePicker
                      className="rounded"
                      style={{ height: 42 }}
                      format={"DD-MM-YYYY"}
                      value={date}
                      onChange={(value) => setDate(value)}
                    />
                    <button
                      onClick={getAttendance}
                      type="button"
                      className="btn btn-primary ms-5"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>

              <div className="card-body pt-5">
                <div className="table-responsive">
                  <Table
                    rowKey={"id"}
                    dataSource={attendances}
                    columns={columns}
                    loading={tableLoader}
                    pagination={false}
                    footer={() => {
                      if (attendances.length != 0) {
                        return (
                          <div className="row">
                            <div className="d-flex align-items-center bg-light-info rounded p-5 me-3 col-md-3">
                              <div className="flex-grow-1 me-2">
                                <div className="fw-bolder text-gray-800 fs-6">
                                  Total Days Present:
                                </div>
                              </div>
                              <span className="fw-bolder text-info py-1">
                                {student.total_present}
                              </span>
                            </div>
                            <div className="d-flex align-items-center bg-light-danger rounded p-5 me-3 col-md-3">
                              <div className="flex-grow-1 me-2">
                                <div className="fw-bolder text-gray-800 fs-6">
                                  Total Days Absent:
                                </div>
                              </div>
                              <span className="fw-bolder text-danger py-1">
                                {student.total_absent}
                              </span>
                            </div>
                            <div className="d-flex align-items-center bg-light-success rounded p-5 me-3 col-md-3">
                              <div className="flex-grow-1 me-2">
                                <div className="fw-bolder text-gray-800 fs-6">
                                  Attendance(%):
                                </div>
                              </div>
                              <span className="fw-bolder text-success py-1">
                                {(
                                  (student.total_present / student.total_att) *
                                  100
                                ).toString()}
                              </span>
                            </div>
                            {/*  <div>
                              Total Days Present: {student.total_present}
                            </div>
                            <div>Total Days Absent: {student.total_absent}</div>
                            <div>
                              Attendance(%):{" "}
                              {(student.total_present / student.total_att) *
                                100}
                            </div> */}
                          </div>
                        );
                      } else {
                        return null;
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Attendance;

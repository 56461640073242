import { Table, Collapse } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useIntl } from "react-intl";
import CircularProgress from "../../components/CircularProgress";
import KTSVG from "../../components/SvgLoader";
import axios from "../../util/Api";
import { toAbsoluteUrl } from "../../util/AssetHelper";

const PayFees = () => {
  const intl = useIntl();
  const [siblings, setSiblings] = useState({});
  const [totalFees, setTotalFees] = useState(0.0);
  const [selectedFees, setSelectedFees] = useState([]);
  const [loading, setLoading] = useState(true);

  const { Panel } = Collapse;

  useEffect(() => {
    axios.post(`online_fee_payment/sibling_fees`).then(({ data }) => {
      setLoading(false);
      if (data.result) {
        setSiblings(data.sibling_fees);
      }
    });
  }, []);

  const payFees = () => {
    let student = {};

    selectedFees.map((d) => {
      let _key = d.split("_");
      if (student[_key[0]] != undefined) student[_key[0]].push(_key[1]);
      else student[_key[0]] = [_key[1]];
    });

    let data = {
      fees: student,
    };

    axios.post("online_fee_payment/pay_sibling_fees", data).then(({ data }) => {
      if (data.result) {
        window.open(data.payment_result.payment_link, "_blank");
      }
    });
  };

  return (
    <>
      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "online_fee_payment" })}
              <span className="h-20px border-gray-200 border-start mx-4"></span>
              <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li className="breadcrumb-item text-dark">
                  {intl.formatMessage({ id: "pay_fees" })}
                </li>
              </ul>
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            <div className="row mb-10">
              {Object.keys(siblings).map((k, i) => {
                return (
                  <div className="col-md-4" key={i}>
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex flex-center flex-column">
                          <div className="symbol symbol-100px symbol-circle mb-5">
                            <img
                              src={
                                siblings[k].siblings_info
                                  .student_profile_image == ""
                                  ? toAbsoluteUrl("/media/avatars/blank.png")
                                  : `${process.env.REACT_APP_STORAGE_PATH}/uploads/student/thumbs/${siblings[k].siblings_info.student_profile_image}`
                              }
                              alt="image"
                            />
                          </div>

                          <a
                            href="#"
                            className="fs-3 text-gray-800 text-hover-primary fw-bolder mb-1"
                          >
                            {siblings[k].siblings_info.full_name}
                          </a>

                          <div className="fs-5 fw-bold text-muted mb-6">
                            {`Admission No. ${siblings[k].siblings_info.student_register_no}`}
                          </div>

                          <div className="d-flex flex-wrap flex-center">
                            <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                              <div className="fs-5 fw-bolder text-gray-700">
                                <span className="w-75px">
                                  {parseFloat(
                                    siblings[k].fees_info.total.reduce(
                                      (a, b) => a + b,
                                      0
                                    )
                                  ).toFixed(3)}
                                </span>
                              </div>
                              <div className="fw-bold text-muted">
                                Total Fees
                              </div>
                            </div>

                            <div className="border border-gray-300 border-dashed rounded py-3 px-3 mx-4 mb-3">
                              <div className="fs-5 fw-bolder text-gray-700">
                                <span className="w-50px">
                                  {parseFloat(
                                    siblings[k].total_discount
                                  ).toFixed(3)}
                                </span>
                              </div>
                              <div className="fw-bold text-muted">Discount</div>
                            </div>

                            <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                              <div className="fs-5 fw-bolder text-gray-700">
                                <span className="w-50px">
                                  {parseFloat(
                                    siblings[k].fees_info.total_paid
                                      ? siblings[k].fees_info.total_paid.reduce(
                                          (a, b) => a + b,
                                          0
                                        )
                                      : 0
                                  ).toFixed(3)}
                                </span>
                              </div>
                              <div className="fw-bold text-muted">Paid</div>
                            </div>
                            <div className="border border-gray-300 border-dashed rounded py-3 px-3 mx-4 mb-3">
                              <div className="fs-5 fw-bolder text-gray-700">
                                <span className="w-50px">
                                  {parseFloat(
                                    siblings[k].fees_info.total_unpaid.reduce(
                                      (a, b) => a + b,
                                      0
                                    )
                                  ).toFixed(3)}
                                </span>
                              </div>
                              <div className="fw-bold text-muted">
                                Balance Due
                              </div>
                            </div>
                            <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                              <div className="fs-5 fw-bolder text-gray-700">
                                <span className="w-50px">
                                  {parseFloat(siblings[k].wallet).toFixed(3)}
                                </span>
                              </div>
                              <div className="fw-bold text-muted">Wallet</div>
                            </div>
                          </div>

                          <Collapse
                            expandIconPosition={"left"}
                            className="w-100 dashed-collapse"
                          >
                            {siblings[k].fees.map((data, index) => {
                              let total = siblings[k].fees_info[
                                `term${index + 1}`
                              ].reduce((a, b) => a + b, 0);
                              return (
                                <Panel
                                  header={
                                    data.title +
                                    " - " +
                                    `(${parseFloat(total).toFixed(3)})`
                                  }
                                  key={index}
                                  extra={
                                    <label className="form-check form-check-custom form-check-solid form-check-sm ms-5">
                                      <input
                                        className="form-check-input me-3"
                                        type="checkbox"
                                        name={"fees"}
                                        value={`${k}_${index}`}
                                        checked={selectedFees.includes(
                                          `${k}_${index}`
                                        )}
                                        onChange={(e) => {
                                          let cloneFees = [...selectedFees];
                                          let index = cloneFees.findIndex(
                                            (x) => x == e.currentTarget.value
                                          );
                                          if (index > -1) {
                                            let data = cloneFees.filter(
                                              (x) => x != e.currentTarget.value
                                            );
                                            cloneFees = data;
                                          } else {
                                            let currentSelected =
                                              e.currentTarget.value.split("_");
                                            for (
                                              let index = 0;
                                              index <=
                                              parseInt(currentSelected[1]);
                                              index++
                                            ) {
                                              const select =
                                                currentSelected[0] +
                                                "_" +
                                                index.toString();
                                              if (!cloneFees.includes(select)) {
                                                cloneFees.push(select);
                                              }
                                            }
                                          }

                                          let total = 0;

                                          cloneFees.forEach((element) => {
                                            let _key = element.split("_");
                                            for (const key in siblings) {
                                              if (key == _key[0]) {
                                                let fee = siblings[key].fees[
                                                  _key[1]
                                                ].items.reduce((a, b) => ({
                                                  amount: a.amount + b.amount,
                                                }));

                                                total += fee.amount;
                                              }
                                            }
                                          });

                                          setTotalFees(total);
                                          setSelectedFees(cloneFees);
                                        }}
                                      />
                                    </label>
                                  }
                                >
                                  {data.items.map((d, i) => {
                                    return (
                                      <div className="mb-0" key={i}>
                                        <table className="table table-hover mb-0 fs-6 fw-bold gs-0 gy-2 gx-2">
                                          <tbody>
                                            <tr>
                                              <td className="text-gray-400 ps-3">
                                                {d.title}
                                              </td>
                                              <td className="text-gray-800 pe-3 text-end">
                                                {parseFloat(d.amount).toFixed(
                                                  3
                                                )}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    );
                                  })}
                                </Panel>
                              );
                            })}
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div
              className={`card mb-5 mb-xxl-8 border border-dashed border-gray-300 card-rounded`}
            >
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="fs-3 fw-bolder text-muted pe-3">
                    TOTAL FEES :
                  </div>
                  <div className="fs-xl-2x fs-2 fw-boldest pe-3">
                    {"KD " + parseFloat(totalFees).toFixed(3)}
                  </div>
                  <button
                    type="button"
                    onClick={payFees}
                    className="btn btn-primary font-weight-bolder py-4 mr-3 mr-sm-14 my-1"
                  >
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PayFees;

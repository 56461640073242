import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CircularProgress from "../../components/CircularProgress";
import axios from "../../util/Api";

const Dashboard = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [meeting, setMeeting] = useState({});

  useEffect(() => {
    axios.post(`dashboard/meeting`).then(({ data }) => {
      setLoading(false);
      if (data.result) {
        setMeeting(data.meeting);
      }
    });
  }, []);

  return (
    <>
      {/* Toolbar Start */}
      <div className="toolbar d-none d-lg-flex" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "dashboard" })}
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}
      {loading ? (
        <CircularProgress />
      ) : (
        <div className="container-fluid d-flex flex-column-fluid" id="kt_post">
          {meeting == null ? (
            <div
              style={{
                fontSize: "32px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              {intl.formatMessage({ id: "dashboard_welcome_message" })}
            </div>
          ) : (
            <div className={`card mb-5 mb-xxl-8 card-xxl-streatch w-100`}>
              <div className="card-header border-0 pt-6">
                <div className="card-title w-100">
                  <h4 className="mb-5">Ongoing/Upcoming Live Classrooms</h4>
                </div>
              </div>
              <div className="card-body pt-5">
                <div className="row d-flex justify-content-space-between align-items-center">
                  <div className="col-sm-6">
                    <dl className="row">
                      <dt className="col-sm-3 py-3">Teacher</dt>
                      <dd className="col-sm-9 py-3 text-warning">
                        {meeting.full_name}
                      </dd>

                      <dt className="col-sm-3 py-3">Topic</dt>
                      <dd className="col-sm-9 py-3">{meeting.topic}</dd>

                      <dt className="col-sm-3 py-3">Subject</dt>
                      <dd className="col-sm-9 py-3 text-primary">
                        {meeting.subject_title}
                      </dd>

                      <dt className="col-sm-3 py-3">Start Time</dt>
                      <dd className="col-sm-9 py-3">
                        {moment(meeting.date_begin).format(
                          "Do MMM, YYYY h:mm A"
                        )}
                      </dd>

                      <dt className="col-sm-3 py-3">End Time</dt>
                      <dd className="col-sm-9 py-3">
                        {moment(meeting.date_end).format("Do MMM, YYYY h:mm A")}
                      </dd>
                    </dl>
                  </div>
                  <div className="col-sm-6">
                    {meeting.meeting_status != "" ? (
                      <h3 className="text-success">{meeting.meeting_status}</h3>
                    ) : meeting.meeting_flag == "zoom" ? (
                      <div className="d-flex align-items-center justify-content-space-between">
                        <a
                          className="btn btn-primary"
                          href={`${meeting.zoom_url}`}
                          target="_blank"
                        >
                          Join in Zoom
                        </a>
                        <h5 className="ps-5 pe-5">OR</h5>
                        <a
                          className="btn btn-success"
                          href={`${meeting.class_url}`}
                          target="_blank"
                        >
                          Join Class
                        </a>
                      </div>
                    ) : (
                      <a
                        className="btn btn-success"
                        href={`${meeting.class_url}`}
                        target="_blank"
                      >
                        Join Class
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Dashboard;

import { Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CircularProgress from "../../components/CircularProgress";
import KTSVG from "../../components/SvgLoader";
import axios from "../../util/Api";

const CalendarEvents = () => {
  const intl = useIntl();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableLoader, setTableLoader] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [query, setQuery] = useState("");
  const pageLimit = process.env.REACT_APP_TABLE_PAGE_SIZE;

  const columns = [
    {
      title: "Sr.No.",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Detail",
      dataIndex: "detail",
      key: "detail",
    },
    {
      title: "Start Date",
      dataIndex: "event_start_date",
      render: (date) => {
        return moment(date).format("Do MMM, YYYY");
      },
    },
    {
      title: "End Date",
      dataIndex: "event_end_date",
      render: (date) => {
        return moment(date).format("Do MMM, YYYY");
      },
    },
    {
      title: "Attachment",
      render: (data) => {
        return data.event_attachment != "" ? (
          <a
            href={`${process.env.REACT_STORAGE_PATH}/download/calendar-event/${data.event_attachment}`}
            download
            target="_blank"
            className="btn btn-icon btn-flex btn-light-primary btn-sm me-1"
          >
            <KTSVG
              path="/media/icons/duotone/Files/Download.svg"
              className="svg-icon-3"
            />
          </a>
        ) : (
          <></>
        );
      },
    },
  ];

  useEffect(() => {
    setTableLoader(true);
    getData(1, query);
  }, []);

  const getData = (page, query) => {
    axios
      .post(`calender-events/manage-calenderevents?page=${page}`, {
        search: query,
        limit: pageLimit,
      })
      .then(({ data }) => {
        setLoading(false);
        setTableLoader(false);
        if (data.result) {
          setEvents(data.calender_events.data);
          setTotalRecord(data.calender_events.total);
        }
      });
  };

  const searchRecord = (query) => {
    setQuery(query);
    if (query.length > 2) {
      setTableLoader(true);
      getData(1, query);
    } else if (query.length == 0) {
      setTableLoader(true);
      getData(1, query);
    }
  };

  const handleTableChange = (pagination) => {
    setTableLoader(true);
    getData(pagination.current, query);
  };

  return (
    <>
      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "calendar_events" })}
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            <div className={`card mb-5 mb-xxl-8 card-xxl-streatch`}>
              <div className="card-header border-0 pt-6">
                <div className="card-title">
                  <div className="d-flex align-items-center position-relative my-1">
                    <span className="svg-icon svg-icon-1 position-absolute ms-6">
                      <KTSVG
                        path="/media/icons/duotone/General/Search.svg"
                        className="svg-icon-1"
                      />
                    </span>

                    <input
                      type="text"
                      data-kt-assignment-table-filter="search"
                      className="form-control form-control-solid w-250px ps-15"
                      placeholder={intl.formatMessage({
                        id: "search",
                      })}
                      onChange={(e) => searchRecord(e.currentTarget.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="card-body pt-5">
                <div className="table-responsive">
                  <Table
                    rowKey={"id"}
                    dataSource={events}
                    columns={columns}
                    onChange={handleTableChange}
                    loading={tableLoader}
                    pagination={{
                      total: totalRecord,
                      defaultPageSize: pageLimit,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CalendarEvents;

import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import CircularProgress from "../../../components/CircularProgress";
import KTSVG from "../../../components/SvgLoader";
import axios from "../../../util/Api";
import { Modal, Table, Upload, Spin } from "antd";
import { DropdownTopbarItemToggler } from "../../../components/DropdownTopbarItemToggler";
import { Dropdown } from "react-bootstrap";

const Emm = () => {
  const intl = useIntl();
  const [tableLoader, setTableLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalRecord, setTotalRecord] = useState(0);
  const [query, setQuery] = useState("");
  const [emm, setEmm] = useState([]);
  const [subjects, setSubjects] = useState({});
  const [search, setSearch] = useState(false);
  const [searchSubject, setSearchSubject] = useState("");
  const [modalShow, setModalShow] = useState({ isShow: false, data: "" });
  const [selectedStatus, setSelectedStatus] = useState("");
  const [uploadPopup, setUploadPopup] = useState({ isShow: false, data: {} });
  const [historyPopup, setHistoryPopup] = useState({ isShow: false, data: {} });
  const [commentPopup, setCommentPopup] = useState({ isShow: false, data: {} });
  const [attacthments, setAttachements] = useState([]);
  const [asDescription, setASDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [attachmentError, setAttachmentError] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const pageLimit = process.env.REACT_APP_TABLE_PAGE_SIZE;

  const statuses = {
    pending: "Pending",
    0: "Submitted",
    1: "Approved",
    2: "Rejected",
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "annotation_id",
      key: "annotation_id",
    },
    {
      title: "Title",
      dataIndex: "annotation_title",
      key: "annotation_title",
    },
    {
      title: "Teacher",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Subject",
      dataIndex: "subject_title",
      key: "subject_title",
    },
    {
      title: "Dead Line",
      dataIndex: "deadline",
      key: "deadline",
      render: (deadline) => {
        return moment(deadline).format("Do MMM, YYYY h:mm A");
      },
    },
    {
      title: "Attachment",
      render: (data) => {
        return (
          <div>
            {data.annotation_link != "" ? (
              <a
                href={`${
                  data.annotation_link.includes("http")
                    ? data.annotation_link
                    : "http://" + data.annotation_link
                }`}
                target="_blank"
                className="btn btn-icon btn-flex btn-light-info btn-sm me-1"
              >
                <KTSVG
                  path="/media/icons/duotone/General/Attachment1.svg"
                  className="svg-icon-3"
                />
              </a>
            ) : (
              <></>
            )}
            {data.attachments.map((d, i) => {
              return (
                <a
                  key={i}
                  href={`${process.env.REACT_APP_STORAGE_PATH}/storage/annotation/${d}`}
                  download
                  target="_blank"
                  className="btn btn-icon btn-flex btn-light-primary btn-sm me-1"
                >
                  <KTSVG
                    path="/media/icons/duotone/Files/Download.svg"
                    className="svg-icon-3"
                  />
                </a>
              );
            })}
          </div>
        );
      },
    },
    {
      title: "Status",
      render: (data) => {
        return (
          <span
            className={`badge badge-light-${data.status_class} fs-7 fw-bold`}
          >
            {data.status_txt}
          </span>
        );
      },
    },
    {
      title: "Action",
      render: (data) => {
        return (
          <div>
            {
              <Link
                to="#"
                onClick={() => setModalShow({ isShow: true, data: data })}
                className="btn btn-icon btn-flex btn-light-info btn-sm me-1"
              >
                <KTSVG
                  path="/media/icons/duotone/General/Visible.svg"
                  className="svg-icon-3"
                />
              </Link>
            }
            {data.upload_annotation == 1 && (
              <Link
                to="#"
                onClick={() => setUploadPopup({ isShow: true, data: data })}
                className="btn btn-icon btn-flex btn-light-primary btn-sm me-1"
              >
                <KTSVG
                  path="/media/icons/duotone/Files/Upload.svg"
                  className="svg-icon-3"
                />
              </Link>
            )}
            {data.view_comment == 1 && (
              <Link
                to="#"
                onClick={() => setCommentPopup({ isShow: true, data: data })}
                className="btn btn-icon btn-flex btn-light-primary btn-sm me-1"
              >
                <KTSVG
                  path="/media/icons/duotone/Communication/Chat5.svg"
                  className="svg-icon-3"
                />
              </Link>
            )}
            {data.view_history == 1 && (
              <Link
                to="#"
                onClick={() => {
                  getHistoryData(data);
                }}
                className="btn btn-icon btn-flex btn-light-success btn-sm me-1"
              >
                <KTSVG
                  path="/media/icons/duotone/Code/Time-schedule.svg"
                  className="svg-icon-3"
                />
              </Link>
            )}
          </div>
        );
      },
    },
  ];

  const getHistoryData = (data) => {
    axios
      .post("academics/emm/view_history", {
        annotation_id: data.annotation_id,
        teacher_id: data.annotation_teacher_id,
        reply_id: data.reply_id,
        title: data.annotation_title,
      })
      .then(({ data }) => {
        if (data.result) {
          setHistoryPopup({ isShow: true, data: data.history });
        }
      });
  };

  useEffect(() => {
    setTableLoader(true);
    getData(1, query);
  }, []);

  const getData = (page, query) => {
    axios
      .post(`academics/emm/list?page=${page}`, {
        search: query,
        limit: pageLimit,
      })
      .then(({ data }) => {
        setLoading(false);
        setTableLoader(false);
        if (data.result) {
          setTotalRecord(data.list.total);
          setEmm(data.list.data);
          setSubjects(data.subject);
        }
      });
  };

  const searchRecord = (query) => {
    if (query != "" || searchSubject != "" || selectedStatus != "") {
      setQuery(query);
      setSearch(true);
      setTableLoader(true);
      searchResult(1, query, searchSubject, selectedStatus);
    } else {
      setQuery(query);
      setSearch(false);
      setTableLoader(true);
      getData(1, query);
    }
  };

  const searchResult = (page, query, subjectId, status) => {
    axios
      .post(`academics/emm/search?page=${page}`, {
        search: query,
        limit: pageLimit,
        subject_id: subjectId,
        status: status,
      })
      .then(({ data }) => {
        setLoading(false);
        setTableLoader(false);
        if (data.result) {
          setTotalRecord(data.list.total);
          setEmm(data.list.data);
        }
      });
  };

  const submitFilter = () => {
    searchResult(1, query, searchSubject, selectedStatus);
  };

  const handleTableChange = (pagination) => {
    setTableLoader(true);
    if (search) {
      searchResult(pagination.current, query, searchSubject, selectedStatus);
    } else {
      getData(pagination.current, query);
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleChange = ({ fileList }) => {
    setAttachements(fileList);
  };

  const submitAnswersheet = (id) => {
    let files = attacthments.map((d) => d.originFileObj);
    let formData = new FormData();
    files.forEach((element, index) => {
      formData.append(`annotation[${index}]`, element);
    });
    formData.append("annotation_description", asDescription);
    setSpinner(true);
    axios
      .post(`academics/emm/upload_answersheet/${id}`, formData)
      .then(({ data }) => {
        setSpinner(false);
        if (data.result) {
          setUploadError("");
          setASDescription("");
          setAttachements([]);
          setUploadPopup({ isShow: false, data: {} });
          let cloneEmm = [...emm];
          let updatedEmms = cloneEmm.map((d, i) => {
            if (d.annotation_id == id) {
              d.view_history = 1;
              d.upload_annotation = 0;
              d.reply_id = data.reply_id;
              d.status_class = data.status_class;
              d.status_txt = data.status_txt;
              return d;
            } else {
              return d;
            }
          });
          setEmm(updatedEmms);
        } else {
          setUploadError(data.message);
        }
      });
  };

  return (
    <>
      <Modal
        title={`Teacher Comment`}
        visible={commentPopup.isShow}
        onCancel={() => {
          setCommentPopup({ isShow: false, data: {} });
        }}
        footer={null}
        width={1000}
      >
        <div className="table-responsive">
          <table
            className="table align-middle table-striped fs-6 gy-5"
            id="kt_assignment_table"
          >
            <tbody className="fw-bold text-gray-600">
              <tr>
                <td colSpan={"6"}>Comment</td>
                <td>{commentPopup.data.teacher_comment}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>

      <Modal
        title={`View Assignment Submit History`}
        visible={historyPopup.isShow}
        onCancel={() => {
          setHistoryPopup({ isShow: false, data: {} });
        }}
        footer={null}
        width={1000}
      >
        <div className="table-responsive">
          <table
            className="table align-middle table-striped fs-6 gy-5"
            id="kt_assignment_table"
          >
            <tbody className="fw-bold text-gray-600">
              <tr>
                <td colSpan={"6"}>
                  EM Title : {historyPopup.data.annotation_title}
                </td>
              </tr>
              <tr>
                <td>Name</td>
                <td>Role</td>
                <td>Comment</td>
                <td>Attachment</td>
                <td>Status</td>
                <td>Date</td>
              </tr>
              <tr>
                <td>{historyPopup.data.updated_by_name}</td>
                <td>{historyPopup.data.updated_by_role}</td>
                <td>{historyPopup.data.comment}</td>
                <td>
                  {historyPopup?.data?.attachments?.map((path, i) => {
                    return (
                      <a
                        key={i}
                        href={`${process.env.REACT_APP_STORAGE_PATH}/storage/annotation/${path}`}
                        download
                        target="_blank"
                        className="btn btn-icon btn-flex btn-light-primary btn-sm me-1"
                      >
                        <KTSVG
                          path="/media/icons/duotone/Files/Download.svg"
                          className="svg-icon-3"
                        />
                      </a>
                    );
                  })}
                </td>
                <td>
                  {historyPopup.data.status == "0"
                    ? "Submitted"
                    : historyPopup.data.status == "1"
                    ? "Approved"
                    : "Rejected"}
                </td>
                <td>
                  {moment(historyPopup.data.update_date).format(
                    "Do MMM, YYYY h:mm A"
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>

      <Modal
        title={`Upload answer sheet :: ${uploadPopup.data.annotation_title}`}
        visible={uploadPopup.isShow}
        onCancel={() => {
          setDescriptionError(false);
          setAttachmentError(false);
          setUploadError("");
          setASDescription("");
          setAttachements([]);
          setUploadPopup({ isShow: false, data: {} });
        }}
        footer={null}
        maskClosable={false}
      >
        <Spin spinning={spinner}>
          <div>
            <div>
              <div className="mb-3">Description*</div>
              <textarea
                className="form-control mb-3"
                placeholder={"Description"}
                rows="3"
                onChange={(e) => setASDescription(e.currentTarget.value)}
                value={asDescription}
              />
              <div className="mb-3">Attachment*</div>
              <Upload
                className="mb-3"
                showUploadList={{
                  showPreviewIcon: false,
                }}
                accept={".pdf"}
                listType="picture-card"
                fileList={attacthments}
                customRequest={dummyRequest}
                onChange={handleChange}
                multiple={true}
              >
                {"+ Upload"}
              </Upload>
            </div>

            {descriptionError && (
              <div className="text-white bg-danger mb-3 py-5 ps-5">
                The annotation description field is required.
              </div>
            )}

            {attachmentError && (
              <div className="text-white bg-danger mb-3 py-5 ps-5">
                The attachments field is required.
              </div>
            )}

            {uploadError != "" && (
              <div className="text-white bg-danger mb-3 py-5 ps-5">
                {uploadError}
              </div>
            )}

            <div className="text-danger mb-3">
              Note: Only PDF files are supported. <br />
              File size should be less than 100 MB.
            </div>
            <div className="d-flex f-d-row align-items-center justify-content-center mt-5">
              <button
                className="btn btn-primary me-5"
                onClick={() => {
                  setDescriptionError(false);
                  setAttachmentError(false);
                  setUploadError("");
                  if (asDescription.trim() == "" && attacthments.length == 0) {
                    setDescriptionError(true);
                    setAttachmentError(true);
                  } else if (asDescription.trim() == "") {
                    setDescriptionError(true);
                  } else if (attacthments.length == 0) {
                    setAttachmentError(true);
                  } else {
                    submitAnswersheet(uploadPopup.data.annotation_id);
                  }
                }}
              >
                Submit
              </button>
              <button
                className="btn btn-danger"
                onClick={() => {
                  setDescriptionError(false);
                  setAttachmentError(false);
                  setUploadError("");
                  setASDescription("");
                  setAttachements([]);
                  setUploadPopup({ isShow: false, data: {} });
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Spin>
      </Modal>

      <Modal
        title="View Assignment"
        visible={modalShow.isShow}
        onCancel={() => {
          setModalShow({ isShow: false, data: "" });
        }}
        footer={null}
      >
        <div className="table-responsive">
          <table
            className="table align-middle table-striped fs-6 gy-5"
            id="kt_assignment_table"
          >
            <tbody className="fw-bold text-gray-600">
              <tr>
                <td>Title</td>
                <td>{modalShow.data.annotation_title}</td>
              </tr>
              <tr>
                <td>Subject</td>
                <td>{modalShow.data.subject_title}</td>
              </tr>
              <tr>
                <td>Lesson</td>
                <td>{modalShow.data.lesson_title}</td>
              </tr>
              <tr>
                <td>Marks</td>
                <td>{modalShow.data.annotation_lesson_marks}</td>
              </tr>
              <tr>
                <td>Assigned On</td>
                <td className="text-success">
                  {moment(modalShow.data.annotation_add_date).format(
                    "Do MMM, YYYY h:mm A"
                  )}
                </td>
              </tr>
              <tr>
                <td>Dead Line</td>
                <td className="text-danger">
                  {moment(modalShow.data.annotation_dead_line).format(
                    "Do MMM, YYYY h:mm A"
                  )}
                </td>
              </tr>
              <tr>
                <td>Description</td>
                <td>{modalShow.data.annotation_description}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>

      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "academics" })}
              <span className="h-20px border-gray-200 border-start mx-4"></span>
              <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li className="breadcrumb-item text-dark">
                  {intl.formatMessage({ id: "emm" })}
                </li>
              </ul>
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            <div className={`card mb-5 mb-xxl-8 card-xxl-streatch`}>
              <div className="card-header border-0 pt-6">
                <div className="card-title">
                  <div className="d-flex align-items-center position-relative my-1">
                    <span className="svg-icon svg-icon-1 position-absolute ms-6">
                      <KTSVG
                        path="/media/icons/duotone/General/Search.svg"
                        className="svg-icon-1"
                      />
                    </span>

                    <input
                      type="text"
                      data-kt-assignment-table-filter="search"
                      className="form-control form-control-solid w-250px ps-15"
                      placeholder={"Search"}
                      onChange={(e) => searchRecord(e.currentTarget.value)}
                    />
                  </div>
                </div>

                <div className="card-toolbar">
                  <div
                    className="d-flex justify-content-end"
                    data-kt-assignment-table-toolbar="base"
                  >
                    <Dropdown
                      drop="down"
                      className="d-flex align-items-center ms-1 ms-lg-3"
                      alignright={"true"}
                    >
                      <Dropdown.Toggle
                        as={DropdownTopbarItemToggler}
                        id="dropdown-toggle-my-cart"
                      >
                        <button
                          type="button"
                          className="btn btn-light-primary me-3"
                        >
                          <span className="svg-icon svg-icon-2">
                            <KTSVG
                              path="/media/icons/duotone/Text/Filter.svg"
                              className="svg-icon-1"
                            />
                          </span>
                          {intl.formatMessage({ id: "filter" })}
                        </button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-300px">
                        <div>
                          <div className="px-7 py-5">
                            <div className="fs-4 text-dark fw-bolder">
                              {intl.formatMessage({ id: "filter_option" })}
                            </div>
                          </div>

                          <div className="separator border-gray-200"></div>

                          <div className="px-7 py-5">
                            <div className="mb-10">
                              <label className="form-label fs-5 fw-bold mb-3">
                                {intl.formatMessage({ id: "subject" })}:
                              </label>

                              <select
                                className="form-select form-select-solid fw-bolder select2-hidden-accessible"
                                data-kt-select2="true"
                                data-placeholder="Select Subject"
                                data-allow-clear="true"
                                data-kt-assignment-table-filter="subject"
                                aria-hidden="true"
                                tabIndex={-1}
                                value={searchSubject}
                                onChange={(e) =>
                                  setSearchSubject(e.currentTarget.value)
                                }
                              >
                                <option value={""}>Select Subject</option>
                                {Object.keys(subjects).map((data, index) => {
                                  return (
                                    <option key={index} value={data}>
                                      {subjects[data]}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>

                            <div className="mb-10">
                              <label className="form-label fs-5 fw-bold mb-3">
                                {intl.formatMessage({ id: "status" })}:
                              </label>

                              <div
                                className="d-flex flex-column flex-wrap fw-bold"
                                data-kt-assignment-table-filter="status"
                              >
                                <label className="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="status"
                                    value={""}
                                    checked={selectedStatus == ""}
                                    onChange={(e) =>
                                      setSelectedStatus(e.currentTarget.value)
                                    }
                                  />
                                  <span className="form-check-label text-gray-600">
                                    {"Select Status"}
                                  </span>
                                </label>

                                {Object.keys(statuses).map((data, index) => {
                                  return (
                                    <label
                                      key={index}
                                      className="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5"
                                    >
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="status"
                                        value={data.toString()}
                                        checked={
                                          selectedStatus == data.toString()
                                        }
                                        onChange={(e) =>
                                          setSelectedStatus(
                                            e.currentTarget.value
                                          )
                                        }
                                      />
                                      <span className="form-check-label text-gray-600">
                                        {statuses[data]}
                                      </span>
                                    </label>
                                  );
                                })}
                              </div>
                            </div>

                            <div className="d-flex justify-content-end">
                              <button
                                type="button"
                                className="btn btn-light btn-active-light-primary me-2"
                                data-kt-menu-dismiss="true"
                                data-kt-assignment-table-filter="reset"
                                onClick={() => {
                                  setSearchSubject("");
                                  setSelectedStatus("");
                                }}
                              >
                                {intl.formatMessage({ id: "reset" })}
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-kt-menu-dismiss="true"
                                data-kt-assignment-table-filter="filter"
                                onClick={() => submitFilter()}
                              >
                                {intl.formatMessage({ id: "apply" })}
                              </button>
                            </div>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>

              <div className="card-body pt-5">
                <div className="table-responsive">
                  <Table
                    rowKey={"annotation_id"}
                    dataSource={emm}
                    columns={columns}
                    onChange={handleTableChange}
                    loading={tableLoader}
                    pagination={{
                      total: totalRecord,
                      defaultPageSize: pageLimit,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Emm;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import axios from "../../util/Api";

const loginSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  confirm_password: Yup.string().required("Confirm password is required"),
});

const initialValues = {
  password: "",
  confirm_password: "",
};

export function SetupNewPassword() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState({ isError: false, error: "" });
  const intl = useIntl();
  const history = useHistory();
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (values.password === values.confirm_password) {
        setLoading(true);
        axios
          .post("auth/change_password", { new_password: values.password })
          .then(({ data }) => {
            if (data.result) {
              formik.setStatus(true);
              setSubmitting(false);
              setLoading(false);
              setHasErrors({ isError: false, error: "" });
              history.push("/");
            } else {
              setSubmitting(false);
              setLoading(false);
              setHasErrors({ isError: true, error: data.message });
            }
          });
      } else {
        setSubmitting(false);
        formik.setFieldError(
          "confirm_password",
          "Confirm password must match with password"
        );
      }
    },
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">
          {intl.formatMessage({ id: "change_password" })}
        </h1>
      </div>

      {formik.status && (
        <div className="mb-10 bg-light-info p-8 rounded">
          <div className="text-info">Password Changed Successfully.</div>
        </div>
      )}

      {hasErrors.isError === true && (
        <div className="mb-lg-10 alert alert-danger">
          <div className="alert-text font-weight-bold">{hasErrors.error}</div>
        </div>
      )}

      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bolder text-dark">
          {intl.formatMessage({ id: "password" })}
        </label>
        <input
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.password && formik.errors.password },
            {
              "is-valid": formik.touched.password && !formik.errors.password,
            }
          )}
          type="password"
          name="password"
          autoComplete="off"
        />

        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.password}</span>
          </div>
        )}
      </div>

      <div className="fv-row mb-10">
        <div className="d-flex justify-content-between mt-n5">
          <div
            className="d-flex flex-row mb-2"
            style={{ width: "100%", justifyContent: "space-between" }}
          >
            <label className="form-label fw-bolder text-dark fs-6 mb-0">
              {intl.formatMessage({ id: "confirm_password" })}
            </label>
          </div>
        </div>
        <input
          type="password"
          autoComplete="off"
          {...formik.getFieldProps("confirm_password")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid":
                formik.touched.confirm_password &&
                formik.errors.confirm_password,
            },
            {
              "is-valid":
                formik.touched.confirm_password &&
                !formik.errors.confirm_password,
            }
          )}
        />
        {formik.touched.confirm_password && formik.errors.confirm_password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.confirm_password}</span>
            </div>
          </div>
        )}
      </div>

      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-primary  mb-5"
          disabled={formik.isSubmitting}
        >
          {!loading && (
            <span className="indicator-label">
              {intl.formatMessage({ id: "submit" })}
            </span>
          )}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {intl.formatMessage({ id: "please_wait" })}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
}

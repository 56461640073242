/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { toAbsoluteUrl } from "../../util/AssetHelper";
import { ForgotPassword } from "./ForgotPassword";
import { SetupNewPassword } from "./NewPassword";
import SignIn from "./SignIn";
import { TwoStepVerification } from "./TwoStepVerification";
import { ForgotPasswordSendMail } from "./ForgotPasswordSendMail";
import { ForgotPasswordVerifyCode } from "./ForgotPasswordVerifyCode";
import { useSelector } from "react-redux";

const AuthPage = () => {
  const { token, xRF25 } = useSelector(({ auth }) => auth);
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (token && (xRF25 == false || xRF25 == null)) {
      if (!location.pathname == "/auth/new-password") {
        history.push("/");
      }
    }
  }, [token]);
  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${toAbsoluteUrl(
          "/media/illustrations/development-hd.png"
        )})`,
      }}
    >
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        <a href="#" className="mb-12">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/icsk-logo.png")}
            className="h-100px"
          />
        </a>

        <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
          <Switch>
            <Route path="/auth/signin" component={SignIn} />
            <Route path="/auth/forgot-password" component={ForgotPassword} />
            <Route
              path="/auth/forgot-password-send-mail"
              component={ForgotPasswordSendMail}
            />
            <Route
              path="/auth/forgot-password-verify-code"
              component={ForgotPasswordVerifyCode}
            />
            <Route path="/auth/new-password" component={SetupNewPassword} />
            <Route
              path="/auth/two-step-verification"
              component={TwoStepVerification}
            />
            <Redirect from="/auth" exact={true} to="/auth/signin" />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default AuthPage;

/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import ClientCaptcha from "react-client-captcha";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { userSignIn } from "../../appRedux/actions/Auth";
import '../../assets/fonts/franconia/stylesheet.css';

const loginSchema = Yup.object().shape({
    admissionNumber: Yup.string().required("Admission number is required"),
    password: Yup.string().required("Password is required"),
    captcha: Yup.string().required("Captcha is required"),
});

const initialValues = {
    admissionNumber: "",
    password: "",
    remember_me: false,
    captcha: "",
};

const SignIn = () => {
    const { loading, error } = useSelector(({ common }) => common);
    const { token, xRF25 } = useSelector(({ auth }) => auth);
    const [hasErrors, setHasErrors] = useState({ isError: false, error: "" });
    const [captchaCode, setCaptchaCode] = useState("");
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: (values, { setFieldError, setSubmitting, resetForm }) => {
            if (captchaCode !== values.captcha) {
                document.getElementById('retryButton').click();
                setFieldError("captcha", "Invalid Captcha!");
                setSubmitting(false);
            } else {
                dispatch(userSignIn(values));
                resetForm();
            }
        },
    });

    useEffect(() => {
        if (!loading && token != null) {
            if (!xRF25) {
                history.push("/");
            } else {
                history.push("/auth/two-step-verification");
            }
        } else if (error != "") {
            formik.setSubmitting(false);
            document.getElementById('retryButton').click();
            formik.resetForm();
            setHasErrors({ error: error, isError: true });
        }
    }, [loading, token, xRF25]);

    return (
        <form
            className="form w-100"
            onSubmit={formik.handleSubmit}
            noValidate
            id="kt_login_signin_form"
        >
            <div className="text-center mb-10">
                <h1 className="text-dark mb-3">
                    {intl.formatMessage({ id: "signin_icsk" })}
                </h1>
                <div className="text-gray-400 fw-bold fs-4">
                    {intl.formatMessage({ id: "enter_admission_no_and_password" })}
                </div>
            </div>

            {formik.status ? (
                <div className="mb-lg-10 alert alert-danger">
                    <div className="alert-text font-weight-bold">{formik.status}</div>
                </div>
            ) : null}

            {hasErrors.isError === true && (
                <div className="mb-lg-10 alert alert-danger">
                    <div className="alert-text font-weight-bold">{hasErrors.error}</div>
                </div>
            )}

            <div className="fv-row mb-10">
                <label className="form-label fs-6 fw-bolder text-dark">
                    {intl.formatMessage({ id: "admission_number" })}
                </label>
                <input
                    {...formik.getFieldProps("admissionNumber")}
                    className={clsx(
                        "form-control form-control-lg form-control-solid",
                        {
                            "is-invalid": formik.touched.admissionNumber && formik.errors.admissionNumber,
                        }
                    )}
                    type="text"
                    name="admissionNumber"
                    autoComplete="off"
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                        }
                    }}
                />
                {formik.touched.admissionNumber && formik.errors.admissionNumber && (
                    <div className="fv-plugins-message-container">
                        <span role="alert" className="text-danger">{formik.errors.admissionNumber}</span>
                    </div>
                )}
            </div>

            <div className="fv-row mb-10">
                <div className="d-flex justify-content-between mt-n5">
                    <div
                        className="d-flex flex-row mb-2"
                        style={{ width: "100%", justifyContent: "space-between" }}
                    >
                        <label className="form-label fw-bolder text-dark fs-6 mb-0">
                            {intl.formatMessage({ id: "password" })}
                        </label>

                        <Link
                            to="/auth/forgot-password"
                            className="link-primary fs-6 fw-bolder"
                            style={{ justifyContent: "end" }}
                        >
                            {intl.formatMessage({ id: "forgot_password" })}
                        </Link>
                    </div>
                </div>
                <input
                    type="password"
                    autoComplete="off"
                    {...formik.getFieldProps("password")}
                    className={clsx(
                        "form-control form-control-lg form-control-solid",
                        {
                            "is-invalid": formik.touched.password && formik.errors.password,
                        }
                    )}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                        }
                    }}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                            <span role="alert" className="text-danger">{formik.errors.password}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className="captcha-container">
                <ClientCaptcha
                    fontFamily={'Franconia'}
                    retryIconSize={18}
                    charsCount={5}
                    captchaCode={(code) => setCaptchaCode(code)}
                />
            </div>

            <div className="fv-row mb-10 mt-5">
                <label className="form-label fs-6 fw-bolder text-dark">
                    {intl.formatMessage({ id: "enter_captcha" })}
                </label>
                <input
                    {...formik.getFieldProps("captcha")}
                    className={clsx(
                        "form-control form-control-lg form-control-solid",
                        {
                            "is-invalid": formik.touched.captcha && formik.errors.captcha,
                        }
                    )}
                    type="text"
                    name="captcha"
                    autoComplete="off"
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                        }
                    }}
                />
                {formik.touched.captcha && formik.errors.captcha && (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                            <span role="alert" className="text-danger">{formik.errors.captcha}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className="fv-row mb-10">
                <label className="form-check form-check-custom form-check-solid form-check-inline">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        style={{ marginRight: 8 }}
                        {...formik.getFieldProps("remember_me")}
                    />
                    <span className="form-check-label fw-bold text-gray-700 fs-6 mb-0">
                        {intl.formatMessage({ id: "remember_me" })}
                    </span>
                </label>
            </div>

            <div className="text-center">
                <button
                    type="submit"
                    id="kt_sign_in_submit"
                    className="btn btn-lg btn-primary w-100 mb-5"
                    disabled={formik.isSubmitting}
                >
                    {loading ? (
                        <span className="indicator-progress" style={{ display: "block" }}>
                            {intl.formatMessage({ id: "please_wait" })}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    ) : (
                        <span className="indicator-label">
                            {intl.formatMessage({ id: "sign_in" })}
                        </span>
                    )}
                </button>
            </div>
        </form>
    );
};

export default SignIn;

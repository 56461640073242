import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Academics from "./Academics";
import Communication from "./Communication";
import Dashboard from "./Dashboard";
import BooksFeePayment from "./BookFeePayment";
import Meetings from "./LiveClassroom";
import Achievements from "./Achievement";
import CalendarEvents from "./EventCalendar";
import Notifications from "./Notifications";
import StudentProfile from "./StudentProfile";
import Feedback from "./Feedback";
import TuritoRegistration from "./TuritoRegistration";
import Discover from "./Discover";
import OnlineFeePayment from "./OnlineFeePayment";
import PhotoGallery from "./PhotoGallery";
import PtmFeedback from "./PtmFeedback";

const App = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${match.url}turito-registration`}
        component={TuritoRegistration}
      />
      <Route exact path="/" component={Dashboard} />
      <Route path={`${match.url}live-classroom`} component={Meetings} />
      <Route
        path={`${match.url}book-fee-payment`}
        component={BooksFeePayment}
      />
      <Route path={`${match.url}academics`} component={Academics} />
      <Route path={`${match.url}communication`} component={Communication} />
      <Route
        path={`${match.url}online-fee-payment`}
        component={OnlineFeePayment}
      />
      <Route path={`${match.url}achievements`} component={Achievements} />
      <Route path={`${match.url}photo-gallery`} component={PhotoGallery} />
      <Route path={`${match.url}calendar-events`} component={CalendarEvents} />
      <Route path={`${match.url}discoveri`} component={Discover} />
      <Route path={`${match.url}notifications`} component={Notifications} />
      <Route path={`${match.url}student-profile`} component={StudentProfile} />
      <Route path={`${match.url}feedback`} component={Feedback} />
      <Route path={`${match.url}ptm-feedback`} component={PtmFeedback} />
    </Switch>
  );
};

export default App;

import { Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CircularProgress from "../../components/CircularProgress";
import KTSVG from "../../components/SvgLoader";
import axios from "../../util/Api";
import { Empty } from 'antd';

const PhotoGallery = () => {
  const intl = useIntl();
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.post(`photo-gallery/photo-gallery`).then(({ data }) => {
      setLoading(false);
      if (data.result) {
        setPhotos(data.gallery);
      }
    });
  }, []);

  const getIdFromUrl = (url) => {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  };

  return (
    <>
      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "photo_gallery" })}
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            <div className={`card mb-5 mb-xxl-8 card-xxl-streatch`}>
              <div className="card-body pt-5">
                {
                    photos.length != 0 ? <div className="table-responsive">
                    {photos.map((data, index) => {
                      return (
                        <table
                          className="table-bordered table-striped datatable-fixed-header"
                          key={index}
                        >
                          <tbody>
                            <tr>
                              <td>{moment(data).format("Do MMM, YYYY")}</td>
                            </tr>
                            {data.meta_data.map((d, i) => {
                              let attachment = d.v.image;
                              let video_id = getIdFromUrl(d.v.video_url);
                              let filename = "";
                              let filename_thumbs = "";
                              if (attachment != "") {
                                filename =
                                  process.env.REACT_APP_STORAGE_PATH +
                                  "teacher-gallery/" +
                                  attachment;
                                filename_thumbs =
                                  process.env.REACT_APP_STORAGE_PATH +
                                  "teacher-gallery/thumbs/" +
                                  attachment;
                              } else if (video_id != "") {
                                filename = d.v.video_url;
                                filename_thumbs =
                                  "http://img.youtube.com/vi/" +
                                  video_id +
                                  "/2.jpg";
                              }
                              return (
                                <tr className="text-center">
                                  <td className="px-10 py-10">
                                    <a>
                                      {video_id != "" ? (
                                        <div className="container-video">
                                          <img
                                            src={filename_thumbs}
                                            alt=""
                                            width="110px"
                                            className="image-video"
                                            height="110px"
                                          />
                                          {/* <img
                                            src="assets/images/video_play.png"
                                            className="overlay"
                                            alt=""
                                            width="50px"
                                            height="50px" 
                                          /> */}
                                        </div>
                                      ) : (
                                        <img
                                          src={filename_thumbs}
                                          alt=""
                                          width="110px"
                                          height="110px"
                                        />
                                      )}
                                      <p>{d.v.caption}</p>
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      );
                    })}
                  </div> : <Empty />
                }
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PhotoGallery;

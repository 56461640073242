import { Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import CircularProgress from "../../components/CircularProgress";
import KTSVG from "../../components/SvgLoader";
import JoditEditor from "jodit-react";
import axios from "../../util/Api";

const ReplyMessage = () => {
  const location = useLocation();
  const history = useHistory();
  const [messages, setMessages] = useState({});
  const [loading, setLoading] = useState(true);
  const [messageData, setMessageData] = useState();
  const [attacthments, setAttachements] = useState([]);
  const [activeLink, setActiveLink] = useState("Inbox");

  const intl = useIntl();

  useEffect(() => {
    setMessages(location.state.messageData);
    setActiveLink(location.state.activeLink);
    setLoading(false);
  }, []);

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleChange = ({ fileList }) => {
    setAttachements(fileList);
  };

  const replyMessage = () => {
    let formData = new FormData();
    formData.append(
      "attachment",
      attacthments.length != 0 ? attacthments[0].originFileObj : ""
    );
    formData.append("reply_id", messages.id);
    formData.append("message", messageData);

    axios.post("communication/reply_message", formData).then(({ data }) => {
      if (data.result) {
        history.push("/communication/teacher-messages");
      }
    });
  };

  return (
    <>
      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "communication" })}
              <span className="h-20px border-gray-200 border-start mx-4"></span>
              <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li className="breadcrumb-item text-muted">
                  <a
                    onClick={() => {
                      history.push({
                        pathname: "/communication/teacher-messages",
                        state: { activeLink: "Inbox" },
                      });
                    }}
                    className="text-muted text-hover-primary"
                  >
                    {intl.formatMessage({ id: "teacher_messages" })}
                  </a>
                </li>
                <li className="breadcrumb-item px-3">
                  <span className="bullet bg-gray-200 w-5px h-2px"></span>
                </li>
                <li className="breadcrumb-item text-dark">
                  {intl.formatMessage({ id: "reply_message" })}
                </li>
              </ul>
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            <div className="">
              <div className="d-flex flex-lg-row flex-column">
                <div
                  className="flex-row-auto offcanvas-mobile mb-5 w-lg-200px w-xxl-275px"
                  id="kt-inbox-aside"
                >
                  <div className="card card-custom card-stretch">
                    <div className="card-body px-5">
                      <div className="navi navi-hover navi-active navi-link-rounded navi-bold navi-icon-center navi-light-icon">
                        <div className="navi-item my-2">
                          <a
                            href="#"
                            className={`navi-link ${
                              activeLink === "Inbox" ? "active" : ""
                            }`}
                            onClick={() => {
                              history.push({
                                pathname: "/communication/teacher-messages",
                                state: { activeLink: "Inbox" },
                              });
                            }}
                          >
                            <span className="navi-icon me-4">
                              <span className="svg-icon svg-icon-lg">
                                <KTSVG
                                  path="/media/icons/duotone/Communication/Mail-heart.svg"
                                  className="svg-icon-1"
                                />
                              </span>
                            </span>
                            <span className="navi-text fw-bolder font-size-lg">
                              Inbox
                            </span>
                          </a>
                        </div>
                        <div className="navi-item my-2">
                          <a
                            href="#"
                            onClick={() => {
                              history.push({
                                pathname: "/communication/teacher-messages",
                                state: { activeLink: "Archive" },
                              });
                            }}
                            className={`navi-link ${
                              activeLink === "Archive" ? "active" : ""
                            }`}
                          >
                            <span className="navi-icon me-4">
                              <span className="svg-icon svg-icon-lg">
                                <KTSVG
                                  path="/media/icons/duotone/Communication/Archive.svg"
                                  className="svg-icon-1"
                                />
                              </span>
                            </span>
                            <span className="navi-text fw-bolder font-size-lg">
                              Archive
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="flex-row-fluid ms-lg-8 d-block"
                  id="kt-inbox-list"
                >
                  <div className="card card-custom card-stretch">
                    <div className="card-body table-responsive px-5">
                      <div>
                        <h2>Reply Message</h2>
                        <h1>{messages.sender_name.full_name}</h1>
                      </div>
                      <div className="table-responsive">
                        <table
                          className="table align-middle table-row-dashed fs-6 gy-5"
                          id="kt_assignment_table"
                        >
                          <tbody className="fw-bold text-gray-600">
                            <tr>
                              <td>
                                <div>
                                  <JoditEditor
                                    value={messageData}
                                    config={{ readonly: false, height: 400 }}
                                    onBlur={(newContent) => {
                                      setMessageData(newContent);
                                    }}
                                    onChange={(newContent) => {}}
                                  />
                                  <Upload
                                    className="mt-5"
                                    showUploadList={{
                                      showPreviewIcon: false,
                                    }}
                                    accept={".jpg, .jpeg, .png"}
                                    listType="picture-card"
                                    fileList={attacthments}
                                    customRequest={dummyRequest}
                                    onChange={handleChange}
                                    maxCount={1}
                                  >
                                    {"+ Upload"}
                                  </Upload>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan={2}>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <button
                                    type="button"
                                    onClick={() => replyMessage()}
                                    className="btn btn-light-primary me-3"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReplyMessage;

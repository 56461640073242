import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import ClientCaptcha from "react-client-captcha";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../util/Api";
import { fetchStart, fetchSuccess } from "../../appRedux/actions/Common";

const initialValues = {
  admissionNumber: "",
  captcha: "",
};

const forgotPasswordSchema = Yup.object().shape({
  admissionNumber: Yup.string().required("Admission number is required"),
  captcha: Yup.string().required("Captcha is required"),
});

export function ForgotPassword() {
  const history = useHistory();
  const { loading } = useSelector(({ common }) => common);
  const dispatch = useDispatch();
  const [captchaCode, setCaptchaCode] = useState("");
  const intl = useIntl();
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setFieldError, setSubmitting }) => {
      if (captchaCode !== values.captcha) {
        setFieldError("captcha", "Invalid Captcha!");
        setSubmitting(false);
      } else {
        dispatch(fetchStart());
        axios
          .post("auth/forgot_password", {
            student_username: values.admissionNumber,
          })
          .then(({ data }) => {
            dispatch(fetchSuccess());
            setSubmitting(false);
            if (data.result) {
              history.push({
                pathname: "/auth/forgot-password-send-mail",
                state: data.student,
              });
            } else {
              formik.setStatus(data.error);
            }
          });
      }
    },
  });

  return (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">
            {intl.formatMessage({ id: "forgot_password" })}
          </h1>

          <div className="text-gray-400 fw-bold fs-4">
            {intl.formatMessage({ id: "enter_register_number" })}
          </div>
        </div>

        {formik.status ? (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : null}

        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            {intl.formatMessage({ id: "admission_number" })}
          </label>
          <input
            type="text"
            placeholder=""
            autoComplete="off"
            {...formik.getFieldProps("admissionNumber")}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid":
                  formik.touched.admissionNumber &&
                  formik.errors.admissionNumber,
              },
              {
                "is-valid":
                  formik.touched.admissionNumber &&
                  !formik.errors.admissionNumber,
              }
            )}
          />
          {formik.touched.admissionNumber && formik.errors.admissionNumber && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.admissionNumber}</span>
              </div>
            </div>
          )}
        </div>

        <div className="captcha-container">
          <ClientCaptcha
            fontFamily={"Franconia"}
            charsCount={5}
            retryIconSize={18}
            captchaCode={(code) => setCaptchaCode(code)}
          />
        </div>

        <div className="fv-row mb-10 mt-5">
          <label className="form-label fs-6 fw-bolder text-dark">
            {intl.formatMessage({ id: "enter_captcha" })}
          </label>
          <input
            {...formik.getFieldProps("captcha")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid": formik.touched.captcha && formik.errors.captcha,
              },
              {
                "is-valid": formik.touched.captcha && !formik.errors.captcha,
              }
            )}
            type="text"
            name="captcha"
            autoComplete="off"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          />
          {formik.touched.captcha && formik.errors.captcha && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.captcha}</span>
              </div>
            </div>
          )}
        </div>

        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="submit"
            id="kt_password_reset_submit"
            className="btn btn-lg btn-primary fw-bolder me-4"
          >
            {loading ? (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "please_wait" })}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            ) : (
              <span className="indicator-label">
                {intl.formatMessage({ id: "submit" })}
              </span>
            )}
          </button>
          <Link to="/auth/signin">
            <button
              type="button"
              id="kt_login_password_reset_form_cancel_button"
              className="btn btn-lg btn-light-primary fw-bolder"
              disabled={formik.isSubmitting}
            >
              {intl.formatMessage({ id: "back_to_login" })}
            </button>
          </Link>{" "}
        </div>
      </form>
    </>
  );
}

import { Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import CircularProgress from "../../components/CircularProgress";
import KTSVG from "../../components/SvgLoader";
import axios from "../../util/Api";
import moment from "../../util/moment";

const PaymentHistory = () => {
  const intl = useIntl();
  const [tableLoader, setTableLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fees, setFees] = useState([]);
  const [query, setQuery] = useState("");
  const [totalRecord, setTotalRecord] = useState(0);
  const [modalShow, setModalShow] = useState({ isShow: false, data: "" });
  const pageLimit = process.env.REACT_APP_TABLE_PAGE_SIZE;

  const columns = [
    {
      title: "Sr.No.",
      dataIndex: "id",
      key: "id",
      render: (data, r, index) => {
        return index + 1;
      },
    },
    {
      title: "Order Number",
      dataIndex: "order_number",
      key: "order_number",
    },
    {
      title: "Transaction Id",
      dataIndex: "tranid",
      key: "tranid",
    },
    {
      title: "Auth",
      dataIndex: "auth",
      key: "auth",
    },
    {
      title: "Result",
      dataIndex: "payment_result",
      key: "payment_result",
    },
    {
      title: "Amount",
      dataIndex: "order_amount",
      key: "order_amount",
    },
    {
      title: "Paid Date",
      dataIndex: "payment_date",
      key: "payment_date",
      render: (date) => {
        return moment(date).format("Do MMM, YYYY h:mm A");
      },
    },
    {
      title: "Action",
      render: (data) => {
        return (
          <Link
            to="#"
            onClick={() => popupShow(data)}
            className="btn btn-icon btn-flex btn-light-primary btn-sm me-1"
          >
            <KTSVG
              path="/media/icons/duotone/General/Visible.svg"
              className="svg-icon-3"
            />
          </Link>
        );
      },
    },
  ];

  useEffect(() => {
    setTableLoader(true);
    getData(1, query);
  }, []);

  const getData = (page, query) => {
    axios
      .post(`online_fee_payment/payment_history?page=${page}`, {
        search: query,
        limit: pageLimit,
      })
      .then(({ data }) => {
        setLoading(false);
        setTableLoader(false);
        if (data.result) {
          setFees(data.payment_history.data);
          setTotalRecord(data.payment_history.total);
        }
      });
  };

  const searchRecord = (query) => {
    setQuery(query);
    if (query.length > 2) {
      setTableLoader(true);
      getData(1, query);
    } else if (query.length == 0) {
      setTableLoader(true);
      getData(1, query);
    }
  };

  const handleTableChange = (pagination) => {
    setTableLoader(true);
    getData(pagination.current, query);
  };

  const popupShow = (data) => {
    axios
      .post("online_fee_payment/view_history", { id: data.id })
      .then(({ data }) => {
        setModalShow({ isShow: true, data: data.fee_meta_details });
      });
  };

  return (
    <>
      <Modal
        title="Fees Detail"
        visible={modalShow.isShow}
        onCancel={() => setModalShow({ isShow: false, data: "" })}
        footer={null}
      >
        <div className="table-responsive">
          <table
            className="table align-middle fs-6 gy-5"
            id="kt_assignment_table"
          >
            <tbody className="fw-bold text-gray-600">
              <tr className="bg-primary text-white">
                <td className="text-center" colSpan={2}>
                  {modalShow?.data?.student_info}
                </td>
              </tr>
              {modalShow?.data?.fee_meta?.map((data, index) => {
                return (
                  <tr key={index}>
                    <td className="ps-5">
                      <div className="d-flex justify-content-space-between">
                        <div>{data.fee_type}</div>
                        <div>
                          {data.installment > 0
                            ? `(${data.installment} inst.)`
                            : ""}
                        </div>
                      </div>
                    </td>
                    <td>KD {parseFloat(data.amount).toFixed(3)}</td>
                  </tr>
                );
              })}
              <tr className="text-white">
                <td className="ps-5 text-warning">{"Total Fees"}</td>
                <td className="text-warning">
                  KD{" "}
                  {
                    parseFloat(modalShow?.data?.fee_meta?.reduce((a, b) => {
                        return {
                          amount: a.amount + b.amount,
                        };
                      })?.amount).toFixed(3)
                  }
                </td>
              </tr>
              <tr>
                <td className="ps-5">{"Payment Status"}</td>
                <td>{modalShow?.data?.fee_info?.payment_result}</td>
              </tr>
              <tr>
                <td className="ps-5">{"Payment Method"}</td>
                <td>
                  Knet{" "}
                  {modalShow?.data?.fee_info?.auth != ""
                    ? `(${modalShow?.data?.fee_info?.auth})`
                    : ""}
                </td>
              </tr>
              <tr>
                <td className="ps-5">{"Payment ID"}</td>
                <td>{modalShow?.data?.fee_info?.payment_id}</td>
              </tr>
              <tr>
                <td className="ps-5">{"Transaction ID"}</td>
                <td>{modalShow?.data?.fee_info?.tranid}</td>
              </tr>
              <tr>
                <td className="ps-5">{"Track ID"}</td>
                <td>{modalShow?.data?.fee_info?.trackid}</td>
              </tr>
              <tr>
                <td className="ps-5">{"Reference ID"}</td>
                <td>{modalShow?.data?.fee_info?.ref}</td>
              </tr>
              <tr>
                <td className="ps-5">{"Payment Date"}</td>
                <td>
                  {moment(modalShow?.data?.fee_info?.payment_date).format(
                    "Do MMM, YYYY h:mm A"
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>

      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "online_fee_payment" })}
              <span className="h-20px border-gray-200 border-start mx-4"></span>
              <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li className="breadcrumb-item text-dark">
                  {intl.formatMessage({ id: "payment_history" })}
                </li>
                <li className="breadcrumb-item px-3">
                  <span className="bullet bg-gray-200 w-5px h-2px"></span>
                </li>
                <li className="breadcrumb-item text-dark">
                  {intl.formatMessage({ id: "online_payment_history" })}
                </li>
              </ul>
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            <div className={`card mb-5 mb-xxl-8 card-xxl-streatch`}>
              <div className="card-toolbar px-10 py-5">
                <div className="d-flex overflow-auto h-55px">
                  <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                    <li className="nav-item">
                      <Link
                        className={`nav-link text-active-primary me-6 false `}
                        to="/online-fee-payment/history/student-fees"
                      >
                        {intl.formatMessage({
                          id: "fee",
                        })}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link text-active-primary me-6 false active`}
                        to="/online-fee-payment/history/payment-history"
                      >
                        {intl.formatMessage({
                          id: "online_payment_history",
                        })}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="card-header border-0 pt-6">
                <div className="card-title">
                  <div className="d-flex align-items-center position-relative my-1">
                    <span className="svg-icon svg-icon-1 position-absolute ms-6">
                      <KTSVG
                        path="/media/icons/duotone/General/Search.svg"
                        className="svg-icon-1"
                      />
                    </span>

                    <input
                      type="text"
                      data-kt-assignment-table-filter="search"
                      className="form-control form-control-solid w-250px ps-15"
                      placeholder={intl.formatMessage({
                        id: "search",
                      })}
                      onChange={(e) => searchRecord(e.currentTarget.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="card-body pt-5">
                <div className="table-responsive">
                  <Table
                    rowKey={"id"}
                    dataSource={fees}
                    columns={columns}
                    onChange={handleTableChange}
                    loading={tableLoader}
                    pagination={{
                      total: totalRecord,
                      defaultPageSize: pageLimit,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentHistory;

import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import KTSVG from "../../components/SvgLoader";
import CircularProgress from "../../components/CircularProgress";
import axios from "../../util/Api";
import { Link } from "react-router-dom";
import { Table, Modal } from "antd";
import moment from "moment";

const Circulars = () => {
  const intl = useIntl();
  const [circulars, setCirculars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState({ isShow: false, data: "" });
  const [deleteModal, setDeleteModal] = useState({ isShow: false, data: "" });

  const [tableLoader, setTableLoader] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [query, setQuery] = useState("");
  const pageLimit = process.env.REACT_APP_TABLE_PAGE_SIZE;

  const columns = [
    {
      title: "Sr.No.",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Circulars",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Circular Added On",
      dataIndex: "add_date",
      render: (date) => {
        return moment(date).format("Do MMM, YYYY h:mm A");
      },
    },
    {
      title: "Action",
      render: (data) => {
        return (
          <div>
            <Link
              to="#"
              onClick={() => setModalShow({ isShow: true, data: data })}
              className="btn btn-icon btn-flex btn-light-primary btn-sm me-1"
            >
              <KTSVG
                path="/media/icons/duotone/General/Visible.svg"
                className="svg-icon-3"
              />
            </Link>
            <Link
              to="#"
              onClick={() => {
                setDeleteModal({ isShow: true, data: data.id });
              }}
              className="btn btn-icon btn-flex btn-light-danger btn-sm me-1"
            >
              <KTSVG
                path="/media/icons/duotone/General/Trash.svg"
                className="svg-icon-3"
              />
            </Link>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setTableLoader(true);
    getData(1, query);
  }, []);

  const getData = (page, query) => {
    axios
      .post(`communication/circulars?page=${page}`, {
        search: query,
        limit: pageLimit,
      })
      .then(({ data }) => {
        setLoading(false);
        setTableLoader(false);
        if (data.result) {
          setCirculars(data.circulars.data);
          setTotalRecord(data.circulars.total);
        }
      });
  };

  const searchRecord = (query) => {
    setQuery(query);
    if (query.length > 2) {
      setTableLoader(true);
      getData(1, query);
    } else if (query.length == 0) {
      setTableLoader(true);
      getData(1, query);
    }
  };

  const handleTableChange = (pagination) => {
    setTableLoader(true);
    getData(pagination.current, query);
  };

  const deleteCircular = (id) => {
    axios.post(`communication/delete_circular/${id}`).then(({ data }) => {
      if (data.result) {
        let data = circulars.filter((data) => data.id != id);
        setCirculars(data);
        setDeleteModal({ isShow: false, data: "" });
      }
    });
  };

  return (
    <>
      <Modal
        title="Circular Detail"
        visible={modalShow.isShow}
        onCancel={() => setModalShow({ isShow: false, data: "" })}
        footer={null}
      >
        <div className="table-responsive">
          <table
            className="table align-middle table-striped fs-6 gy-5"
            id="kt_assignment_table"
          >
            <tbody className="fw-bold text-gray-600">
              <tr>
                <td>{"Title"}</td>
                <td>{modalShow.data.title}</td>
              </tr>
              <tr>
                <td>{"Circular Date"}</td>
                <td>
                  {moment(modalShow.data.add_date).format(
                    "Do MMM, YYYY"
                  )}
                </td>
              </tr>
              <tr>
                <td
                  colSpan={2}
                  dangerouslySetInnerHTML={{ __html: modalShow.data.content }}
                />
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>

      <Modal
        title="Confirm"
        visible={deleteModal.isShow}
        onOk={() => deleteCircular(deleteModal.data)}
        onCancel={() => setDeleteModal({ isShow: false, data: "" })}
      >
        <p>Are you sure you want to delete this?</p>
      </Modal>

      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "communication" })}
              <span className="h-20px border-gray-200 border-start mx-4"></span>
              <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li className="breadcrumb-item text-dark">
                  {intl.formatMessage({ id: "circulars" })}
                </li>
              </ul>
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            <div className={`card mb-5 mb-xxl-8 card-xxl-streatch`}>
              <div className="card-header border-0 pt-6">
                <div className="card-title">
                  <div className="d-flex align-items-center position-relative my-1">
                    <span className="svg-icon svg-icon-1 position-absolute ms-6">
                      <KTSVG
                        path="/media/icons/duotone/General/Search.svg"
                        className="svg-icon-1"
                      />
                    </span>

                    <input
                      type="text"
                      data-kt-assignment-table-filter="search"
                      className="form-control form-control-solid w-250px ps-15"
                      placeholder={intl.formatMessage({
                        id: "search",
                      })}
                      onChange={(e) => searchRecord(e.currentTarget.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="card-body pt-5">
                <div className="table-responsive">
                  <Table
                    rowKey={"id"}
                    dataSource={circulars}
                    columns={columns}
                    onChange={handleTableChange}
                    loading={tableLoader}
                    pagination={{
                      total: totalRecord,
                      defaultPageSize: pageLimit,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Circulars;

import { Modal } from "antd";
import axios from "../../util/Api";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { setTokenData, userSignOut } from "../../appRedux/actions/Auth";
import { DropdownTopbarItemToggler } from "../../components/DropdownTopbarItemToggler";
import KTSVG from "../../components/SvgLoader";
import { toAbsoluteUrl } from "../../util/AssetHelper";
const Header = () => {
  const intl = useIntl();

  const dispatch = useDispatch();
  const { authUser, acdmYear, siblings, acdm_years } = useSelector(
    ({ auth }) => auth
  );

  const userDropDownMenu = useRef(null);
  const userClickRef = useRef(null);
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const [acdmModal, setAcdmModal] = useState(false);
  const [selectedYear, setSelectedYear] = useState(
    localStorage.getItem("acdm_year").replace("_", "-")
  );
  const acdm_year = localStorage.getItem("acdm_year");

  const toggleAside = (e) => {
    if (document.getElementById("kt_aside").classList.contains("drawer-on")) {
      document.getElementById("kt_aside").classList.remove("drawer-on");
    } else {
      document.getElementById("kt_aside").classList.add("drawer-on");
    }
  };

  /* const onUserMenuClick = () => {
    if (userDropDownMenu.current.classList.contains("show")) {
      userDropDownMenu.current.classList.remove("show");
    } else {
      userDropDownMenu.current.classList.add("show");
    }
  }; */
  const hideUserMenu = () => {
    userDropDownMenu.current.classList.remove("show");
    document.getElementById("kt_aside").classList.remove("drawer-on");
  };
  const handleClickOutside = (event) => {
    if (
      userDropDownMenu.current &&
      !userDropDownMenu.current.contains(event.target) &&
      userClickRef.current &&
      !userClickRef.current.contains(event.target)
    ) {
      hideUserMenu();
    }
    if (
      !document.getElementById("kt_aside").contains(event.target) &&
      document.getElementById("kt_aside").classList.contains("drawer-on")
    ) {
      document.getElementById("kt_aside").classList.remove("drawer-on");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const signOut = () => {
    dispatch(userSignOut());
  };

  const siblingLogin = (reg_number) => {
    axios
      .post("auth/sibling_login", { student_register_no: reg_number })
      .then(({ data }) => {
        if (data.result) {
          setModalShow(false);
          dispatch(userSignOut());
          localStorage.setItem(
            "token",
            JSON.stringify(data.token.access_token)
          );
          localStorage.setItem("acdm_year", data.acd_year);
          dispatch(setTokenData(data));
        }
      });
  };

  const changeAcdmYear = () => {
    axios
      .post("auth/change_academic_year", { academic_year: selectedYear })
      .then(({ data }) => {
        if (data.result) {
          setAcdmModal(false);
          dispatch(userSignOut());
          localStorage.setItem(
            "token",
            JSON.stringify(data.token.access_token)
          );
          localStorage.setItem("acdm_year", data.acd_year);
          dispatch(setTokenData(data));
        }
      });
  };

  return (
    <>
      <Modal
        title="Switch to other Sibling"
        visible={modalShow}
        onCancel={() => setModalShow(false)}
        footer={null}
      >
        <div className="table-responsive">
          <table
            className="table align-middle fs-6 gy-5"
            id="kt_assignment_table"
          >
            <tbody className="fw-bold text-gray-600">
              {siblings.map((data, index) => {
                return (
                  <tr key={index}>
                    <td className="w-50px">
                      <img
                        className="symbol symbol-circle h-50px w-50px"
                        src={
                          data.student_profile_image != ""
                            ? `/uploads/student/thumbs/${data.student_profile_image}`
                            : toAbsoluteUrl("/media/avatars/blank.png")
                        }
                        alt="Profile"
                      />
                    </td>
                    <td>{data.full_name}</td>
                    <td>
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        className="d-flex align-items-center text-info"
                        onClick={() => siblingLogin(data.student_register_no)}
                      >
                        <a className="btn btn-sm btn-primary">
                          <KTSVG
                            path="/media/icons/duotone/Navigation/Arrow-to-right.svg"
                            className="svg-icon-3"
                          />
                          {" Login"}
                        </a>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal>

      <Modal
        title="Change Academic Year"
        visible={acdmModal}
        onCancel={() => setAcdmModal(false)}
        footer={null}
      >
        <div className="table-responsive">
          <table
            className="table align-middle fs-6 gy-5"
            id="kt_assignment_table"
          >
            <tbody className="fw-bold text-gray-600">
              <tr>
                <td>Academic Year</td>
                <td>
                  <select
                    className="form-select form-select-solid fw-bolder select2-hidden-accessible"
                    data-kt-select2="true"
                    data-placeholder="Select Subject"
                    data-allow-clear="true"
                    data-kt-assignment-table-filter="subject"
                    aria-hidden="true"
                    onChange={(e) => setSelectedYear(e.currentTarget.value)}
                    defaultValue={acdm_year.replace("_", "-")}
                  >
                    {acdm_years.map((data, index) => {
                      return (
                        <option key={index} value={data}>
                          {data}
                        </option>
                      );
                    })}
                  </select>
                </td>
                <td>
                  <a
                    className="btn btn-sm btn-primary"
                    onClick={changeAcdmYear}
                  >
                    Submit
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>

      <div
        id="kt_header"
        className="header align-items-stretch"
        style={{ backgroundColor: "white" }}
      >
        <div className="container-fluid d-flex align-items-stretch justify-content-between">
          <div
            className="d-flex align-items-center d-lg-none me-2"
            title="Show header menu"
          >
            <div
              className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
              id="kt_header_menu_mobile_toggle"
              onClick={(e) => toggleAside(e)}
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M22 11.5C22 12.3284 21.3284 13 20.5 13H3.5C2.6716 13 2 12.3284 2 11.5C2 10.6716 2.6716 10 3.5 10H20.5C21.3284 10 22 10.6716 22 11.5Z"
                      fill="black"
                    />
                    <path
                      opacity="0.5"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.5 20C15.3284 20 16 19.3284 16 18.5C16 17.6716 15.3284 17 14.5 17H3.5C2.6716 17 2 17.6716 2 18.5C2 19.3284 2.6716 20 3.5 20H14.5ZM8.5 6C9.3284 6 10 5.32843 10 4.5C10 3.67157 9.3284 3 8.5 3H3.5C2.6716 3 2 3.67157 2 4.5C2 5.32843 2.6716 6 3.5 6H8.5Z"
                      fill="black"
                    />
                  </g>
                </svg>
              </span>
            </div>
          </div>
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
            <Link to="#" className="d-lg-none">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/icsk-logo.png")}
                className="h-30px"
              />
            </Link>
          </div>

          <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
            <div
              className="d-flex align-items-stretch"
              id="kt_header_nav"
            ></div>

            <div className="d-flex align-items-stretch flex-shrink-0">
              <div className="d-flex align-items-stretch flex-shrink-0">
                <div className="d-flex align-items-center ms-1 ms-lg-3">
                  {siblings.length != 0 && (
                    <a
                      className="btn-primary btn btn-sm me-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => setModalShow(true)}
                    >
                      <span>
                        <KTSVG
                          path="/media/icons/duotone/Communication/Group.svg"
                          className="svg-icon-3"
                        />
                      </span>
                      Swith to other Sibling
                    </a>
                  )}
                  <span className="svg-icon svg-icon-1 d-none d-lg-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <path
                          d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z"
                          fill="#000000"
                        />
                        <rect
                          fill="#FFFFFF"
                          x="13"
                          y="8"
                          width="3"
                          height="3"
                          rx="1"
                        />
                        <path
                          d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                      </g>
                    </svg>
                  </span>
                  <span className="d-none d-lg-block">
                    {intl.formatMessage({ id: "academic_year" })}&nbsp;
                  </span>
                  <span
                    className="badge badge-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => setAcdmModal(true)}
                  >
                    {acdm_year ? acdm_year : ""}
                  </span>
                </div>

                <div className="d-flex align-items-center ms-1 ms-lg-8">
                  <div
                    onClick={() =>
                      history.push("/communication/teacher-messages")
                    }
                    className="btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px"
                  >
                    <span className="svg-icon svg-icon-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <path
                          d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                          fill="#000000"
                        />
                        <circle
                          fill="#000000"
                          opacity="0.3"
                          cx="19.5"
                          cy="17.5"
                          r="2.5"
                        />
                      </svg>
                    </span>

                    <span
                      className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle animation-blink"
                      style={{ top: "63%", left: "68%" }}
                    ></span>
                  </div>
                </div>

                <div className="d-flex align-items-center ms-1 ms-lg-3">
                  <div
                    className="btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px"
                    data-kt-menu-trigger="click"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                    data-kt-menu-flip="bottom"
                    onClick={() => history.push("/notifications")}
                  >
                    <span className="svg-icon svg-icon-1">
                      <KTSVG
                        path="/media/icons/duotone/General/Notifications1.svg"
                        className="svg-icon-1"
                      />
                    </span>
                  </div>
                </div>
                <Dropdown
                  drop="down"
                  className="d-flex align-items-center ms-1 ms-lg-3"
                  alignright={"true"}
                >
                  <Dropdown.Toggle
                    as={DropdownTopbarItemToggler}
                    id="dropdown-toggle-my-cart"
                  >
                    <img
                      src={
                        authUser.student_profile_image != ""
                          ? `/uploads/student/thumbs/${authUser.student_profile_image}`
                          : toAbsoluteUrl("/media/avatars/blank.png")
                      }
                      alt="Profile"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px">
                    <div className="menu-item px-3">
                      <div className="menu-content d-flex align-items-center px-3">
                        <div className="symbol symbol-50px me-5">
                          <img
                            src={
                              authUser.student_profile_image != ""
                                ? `${process.env.REACT_APP_STORAGE_PATH}/uploads/student/thumbs/${authUser.student_profile_image}`
                                : toAbsoluteUrl("/media/avatars/blank.png")
                            }
                            alt="Profile"
                          />
                        </div>

                        <div className="d-flex flex-column">
                          <div className="fw-bolder d-flex align-items-center fs-5">
                            {authUser
                              ? authUser.full_name +
                                "(" +
                                authUser.student_username +
                                ")"
                              : ""}
                          </div>
                          <Link
                            to="#"
                            className="fw-bold text-muted text-hover-primary fs-7"
                          >
                            {intl.formatMessage({ id: "student" })}:{" "}
                            {authUser
                              ? authUser.class_title +
                                " " +
                                authUser.section_title
                              : ""}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="separator my-2"></div>

                    <div className="menu-item px-5">
                      <Link to="/auth/new-password" className="menu-link px-5">
                        {intl.formatMessage({ id: "change_password" })}
                      </Link>
                    </div>

                    <div className="menu-item px-5">
                      <Link to="#" onClick={signOut} className="menu-link px-5">
                        {intl.formatMessage({ id: "signout" })}
                      </Link>
                    </div>
                    {/* <div className="separator my-2"></div>

                  <div className="menu-item px-5">
                    <div className="menu-content px-5">
                      <label
                        className="form-check form-switch form-check-custom form-check-solid pulse pulse-success"
                        htmlFor="kt_user_menu_dark_mode_toggle"
                      >
                        <input
                          className="form-check-input w-30px h-20px"
                          type="checkbox"
                          value="1"
                          name="skin"
                          id="kt_user_menu_dark_mode_toggle"
                          data-kt-url="../../demo1/dist/index.html"
                        />
                        <span className="pulse-ring ms-n1"></span>
                        <span className="form-check-label text-gray-600 fs-7">
                          {intl.formatMessage({ id: "dark_mode" })}
                        </span>
                      </label>
                    </div>
                  </div> */}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { loadState } from "../../connectivity/localStorage";
import createRootReducer from "../reducers";

export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);

const middlewares = [thunk, routeMiddleware];

const persistedState = loadState();

export default function configureStore() {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    persistedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ...middlewares
      )
    )
  );
  return store;
}

import React, { useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Link, useLocation, NavLink } from "react-router-dom";
import KTSVG from "../../components/SvgLoader";
import { checkIsActive } from "../../helpers/RouterHelpers";

const Sidebar = () => {
  const { authUser, ptm_enable, is_discover } = useSelector(({ auth }) => auth);
  const intl = useIntl();
  const location = useLocation();
  const toggleRef = useRef(null);
  const bookFeeRef = useRef(null);
  const onlineFeeRef = useRef(null);
  const communicationRef = useRef(null);
  const academicsRef = useRef(null);
  const libraryRef = useRef(null);

  const CLASSLIST = ["9", "10", "11", "12"];
  const ENABLE_VOTING_CLASS = 11;
  const ENABLE_VOTING_FOR = 11;

  //const isActive = checkIsActive(pathname, to)

  const onToggleClick = () => {
    if (toggleRef.current.classList.contains("active")) {
      toggleRef.current.classList.remove("active");
      document.body.setAttribute("data-kt-aside-minimize", "off");
    } else {
      toggleRef.current.classList.add("active");
      document.body.setAttribute("data-kt-aside-minimize", "on");
    }
  };

  const handleResize = () => {
    if (window.innerWidth <= 780) {
      document
        .getElementById("kt_aside")
        .classList.add("drawer", "drawer-start");
    } else {
      document
        .getElementById("kt_aside")
        .classList.remove("drawer", "drawer-start");
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location.pathname, url)
      ? ` ${
          !hasSubmenu && "menu-item-active"
        } show menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <div
      id="kt_aside"
      className="aside aside-dark aside-hoverable drawer drawer-start"
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle"
    >
      <div className="aside-logo flex-column-auto" id="kt_aside_logo">
        <Link to="#">
          <h2 className="text-white">{authUser ? authUser.branch_name : ""}</h2>
        </Link>

        <div
          id="kt_aside_toggle"
          className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
          data-kt-toggle="true"
          data-kt-toggle-state="active"
          data-kt-toggle-target="body"
          data-kt-toggle-name="aside-minimize"
          onClick={onToggleClick}
          ref={toggleRef}
        >
          <span className="svg-icon svg-icon-1 rotate-180">
            <KTSVG
              path="/media/icons/duotone/Navigation/Angle-double-left.svg"
              className="svg-icon-1 rotate-180"
            />
          </span>
        </div>
      </div>

      <div className="aside-menu overflow-auto">
        <div
          className="hover-scroll-overlay-y my-5 my-lg-5"
          id="kt_aside_menu_wrapper"
          data-kt-scroll="true"
          data-menu-scroll="1"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-height="auto"
          data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
          data-kt-scroll-wrappers="#kt_aside_menu"
          data-kt-scroll-offset="0"
        >
          <div
            className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
            id="#kt_aside_menu"
            data-kt-menu="true"
          >
            <div className="menu-item">
              <div className="menu-content pb-2">
                <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                  {intl.formatMessage({ id: "navigation" })}
                </span>
              </div>
            </div>

            {/* Turito Registration Begin */}
            {CLASSLIST.includes(authUser.class_title) ? (
              <div className="menu-item">
                <NavLink
                  className={`menu-link without-sub`}
                  to="/turito-registration"
                >
                  <span className="menu-icon">
                    <KTSVG
                      path={
                        "/media/icons/duotone/Communication/Address-card.svg"
                      }
                      className="svg-icon-2"
                    />
                  </span>
                  <span className="menu-title">
                    {intl.formatMessage({ id: "turito_registration" })}
                  </span>
                </NavLink>
              </div>
            ) : (
              <></>
            )}

            {/* Consent Form Begin */}
            {/* {(authUser.branch_id == 13 && authUser.class_title == "7") ||
            (authUser.branch_id == 3 && authUser.class_title == "6") ||
            (authUser.branch_id == 3 && authUser.class_title == "7") ? (
              <div className="menu-item">
                <NavLink
                  className={`menu-link without-sub`}
                  to="/turito-registration"
                >
                  <span className="menu-icon">
                    <KTSVG
                      path={"/media/icons/duotone/Files/File.svg"}
                      className="svg-icon-2"
                    />
                  </span>
                  <span className="menu-title">
                    {intl.formatMessage({ id: "consent_form" })}
                  </span>
                </NavLink>
              </div>
            ) : (
              <></>
            )} */}

            {/* Dashboard Begin */}
            <div className="menu-item">
              <Link
                className={`menu-link without-sub ${
                  location.pathname === "/" ? "active" : ""
                }`}
                to="/"
              >
                <span className="menu-icon">
                  <KTSVG
                    path={"/media/icons/duotone/Home/Home.svg"}
                    className="svg-icon-2"
                  />
                </span>
                <span className="menu-title">
                  {intl.formatMessage({ id: "dashboard" })}
                </span>
              </Link>
            </div>

            {/* Senete Election Begin */}
            {/*  {authUser.branch_id == ENABLE_VOTING_FOR &&
            authUser.class_title == ENABLE_VOTING_CLASS.toString() ? (
              <div className="menu-item">
                <Link
                  className={`menu-link without-sub`}
                  to="/"
                  target="_blank"
                >
                  <span className="menu-icon">
                    <KTSVG
                      path={"/media/icons/duotone/Interface/Doughnut.svg"}
                      className="svg-icon-2"
                    />
                  </span>
                  <span className="menu-title">
                    {intl.formatMessage({ id: "senate_election" })}
                  </span>
                </Link>
              </div>
            ) : (
              <></>
            )} */}

            {/* Feed  Begin */}
            {/* <div className="menu-item">
              <Link
                className={`menu-link without-sub`}
                to="/"
                target="_blank"
              >
                <span className="menu-icon">
                  <KTSVG
                    path={"/media/icons/duotone/Home/RSS.svg"}
                    className="svg-icon-2"
                  />
                </span>
                <span className="menu-title">
                  {intl.formatMessage({ id: "feed" })}
                </span>
              </Link>
            </div> */}

            {/* Live Classroom Begin */}
            <div className="menu-item">
              <NavLink className={`menu-link without-sub`} to="/live-classroom">
                <span className="menu-icon">
                  <KTSVG
                    path={"/media/icons/duotone/Devices/Video-camera.svg"}
                    className="svg-icon-2"
                  />
                </span>
                <span className="menu-title">
                  {intl.formatMessage({ id: "live_classroom" })}
                </span>
              </NavLink>
            </div>

            {/* Online Exam Begin */}
            <div className="menu-item">
              <a
                className={`menu-link without-sub`}
                href="http://tma.icskcloud.com/home"
                target="_blank"
              >
                <span className="menu-icon">
                  <KTSVG
                    path={"/media/icons/duotone/Home/Library.svg"}
                    className="svg-icon-2"
                  />
                </span>
                <span className="menu-title">
                  {intl.formatMessage({ id: "online_exam" })}
                </span>
              </a>
            </div>

            {/* Book Fee Payment Begin */}
            <div
              ref={bookFeeRef}
              onClick={(e) => {
                if (
                  e.target.parentNode === bookFeeRef.current.firstChild &&
                  bookFeeRef.current.classList.contains("show")
                ) {
                  bookFeeRef.current.classList.remove("show");
                } else {
                  bookFeeRef.current.classList.add("show");
                }
              }}
              className={`menu-item menu-accordion ${getMenuItemActive(
                "/book-fee-payment",
                true
              )}`}
            >
              <span
                className={`menu-link ${
                  location.pathname.includes("book-fee-payment") ? "active" : ""
                }`}
              >
                <span className="menu-icon">
                  <KTSVG
                    path={"/media/icons/duotone/Home/Book.svg"}
                    className="svg-icon-2"
                  />
                </span>
                <span className="menu-title">
                  {intl.formatMessage({ id: "book_fee_payment" })}
                </span>
                <span className="menu-arrow"></span>
              </span>
              <div className="menu-sub menu-sub-accordion">
                <div className="menu-item">
                  <NavLink
                    className={`menu-link without-sub`}
                    to="/book-fee-payment/payments"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">
                      {intl.formatMessage({ id: "fee_payment" })}
                    </span>
                  </NavLink>
                </div>
                <div className="menu-item">
                  <NavLink
                    className={`menu-link without-sub`}
                    to="/book-fee-payment/history"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">
                      {intl.formatMessage({ id: "fee_history" })}
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>

            {/* Academics Begin */}
            <div
              ref={academicsRef}
              onClick={(e) => {
                if (
                  e.target.parentNode === academicsRef.current.firstChild &&
                  academicsRef.current.classList.contains("show")
                ) {
                  academicsRef.current.classList.remove("show");
                } else {
                  academicsRef.current.classList.add("show");
                }
              }}
              className={`menu-item menu-accordion ${getMenuItemActive(
                "/academics",
                true
              )}`}
            >
              <span
                className={`menu-link ${
                  location.pathname.includes("academics") ? "active" : ""
                }`}
              >
                <span className="menu-icon">
                  <KTSVG
                    path={"/media/icons/duotone/Home/Book-open.svg"}
                    className="svg-icon-2"
                  />
                </span>
                <span className="menu-title">
                  {intl.formatMessage({ id: "academics" })}
                </span>
                <span className="menu-arrow"></span>
              </span>
              <div className="menu-sub menu-sub-accordion">
                <div className="menu-item">
                  <NavLink
                    className={`menu-link without-sub`}
                    to="/academics/academic-report"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">
                      {intl.formatMessage({ id: "academic_report" })}
                    </span>
                  </NavLink>
                </div>
                <div className="menu-item">
                  <NavLink
                    className={`menu-link without-sub`}
                    to="/academics/assignments"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">
                      {intl.formatMessage({ id: "assignment" })}
                    </span>
                  </NavLink>
                </div>
                <div className="menu-item">
                  <NavLink
                    className={`menu-link without-sub`}
                    to="/academics/reference-material"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">
                      {intl.formatMessage({ id: "reference_material" })}
                    </span>
                  </NavLink>
                </div>

                <div className="menu-item">
                  <NavLink
                    className={`menu-link without-sub`}
                    to="/academics/class-notes"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">
                      {intl.formatMessage({ id: "class_notes" })}
                    </span>
                  </NavLink>
                </div>

                <div className="menu-item">
                  <NavLink
                    className={`menu-link without-sub`}
                    to="/academics/student-corner"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">
                      {intl.formatMessage({ id: "student_corner" })}
                    </span>
                  </NavLink>
                </div>

                <div className="menu-item">
                  <NavLink
                    className={`menu-link without-sub`}
                    to="/academics/timetable"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">
                      {intl.formatMessage({ id: "timetable" })}
                    </span>
                  </NavLink>
                </div>

                <div className="menu-item">
                  <NavLink
                    className={`menu-link without-sub`}
                    to="/academics/attendance"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">
                      {intl.formatMessage({ id: "attendance" })}
                    </span>
                  </NavLink>
                </div>

                <div className="menu-item">
                  <NavLink
                    className={`menu-link without-sub`}
                    to="/academics/emm"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">
                      {intl.formatMessage({ id: "emm" })}
                    </span>
                  </NavLink>
                </div>

                <div className="menu-item">
                  <NavLink
                    className={`menu-link without-sub`}
                    to="/academics/emm2"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">
                      {intl.formatMessage({ id: "emm2" })}
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>

            {/* Communication Begin */}
            <div
              ref={communicationRef}
              onClick={(e) => {
                if (
                  e.target.parentNode === communicationRef.current.firstChild &&
                  communicationRef.current.classList.contains("show")
                ) {
                  communicationRef.current.classList.remove("show");
                } else {
                  communicationRef.current.classList.add("show");
                }
              }}
              className={`menu-item menu-accordion ${getMenuItemActive(
                "/communication",
                true
              )}`}
            >
              <span
                className={`menu-link ${
                  location.pathname.includes("communication") ? "active" : ""
                }`}
              >
                <span className="menu-icon">
                  <KTSVG
                    path={"/media/icons/duotone/Interface/Comment.svg"}
                    className="svg-icon-2"
                  />
                </span>
                <span className="menu-title">
                  {intl.formatMessage({ id: "communication" })}
                </span>
                <span className="menu-arrow"></span>
              </span>

              <div className="menu-sub menu-sub-accordion">
                <div className="menu-item">
                  <NavLink
                    className={`menu-link without-sub`}
                    to="/communication/circulars"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">
                      {intl.formatMessage({ id: "circulars" })}
                    </span>
                  </NavLink>
                </div>
                <div className="menu-item">
                  <NavLink
                    className={`menu-link without-sub`}
                    to="/communication/teacher-messages"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">
                      {intl.formatMessage({ id: "teacher_messages" })}
                    </span>
                  </NavLink>
                </div>
                <div className="menu-item">
                  <NavLink
                    className={`menu-link without-sub`}
                    to="/communication/principal-message"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">
                      {intl.formatMessage({ id: "principal_message" })}
                    </span>
                  </NavLink>
                </div>
                <div className="menu-item">
                  <NavLink
                    className={`menu-link without-sub`}
                    to="/communication/quick-contacts"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">
                      {intl.formatMessage({ id: "quick_contacts" })}
                    </span>
                  </NavLink>
                </div>
                <div className="menu-item">
                  <NavLink
                    className={`menu-link without-sub`}
                    to="/communication/news"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">
                      {intl.formatMessage({ id: "news" })}
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>

            {/* Online Fee Payment Begin */}
            <div
              ref={onlineFeeRef}
              onClick={(e) => {
                if (
                  e.target.parentNode === onlineFeeRef.current.firstChild &&
                  onlineFeeRef.current.classList.contains("show")
                ) {
                  onlineFeeRef.current.classList.remove("show");
                } else {
                  onlineFeeRef.current.classList.add("show");
                }
              }}
              className={`menu-item menu-accordion ${getMenuItemActive(
                "/online-fee-payment",
                true
              )}`}
            >
              <span
                className={`menu-link ${
                  location.pathname.includes("online-fee-payment")
                    ? "active"
                    : ""
                }`}
              >
                <span className="menu-icon">
                  <KTSVG
                    path={"/media/icons/duotone/Shopping/Money.svg"}
                    className="svg-icon-2"
                  />
                </span>
                <span className="menu-title">
                  {intl.formatMessage({ id: "online_fee_payment" })}
                </span>
                <span className="menu-arrow"></span>
              </span>
              <div className="menu-sub menu-sub-accordion">
                <div className="menu-item">
                  <NavLink
                    className={`menu-link without-sub`}
                    to="/online-fee-payment/pay-fees"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">
                      {intl.formatMessage({ id: "pay_fees" })}
                    </span>
                  </NavLink>
                </div>
                <div className="menu-item">
                  <NavLink
                    className={`menu-link without-sub`}
                    to="/online-fee-payment/history"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">
                      {intl.formatMessage({ id: "payment_history" })}
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>

            {/* Achievement Begin */}
            <div className="menu-item">
              <NavLink className={`menu-link without-sub`} to="/achievements">
                <span className="menu-icon">
                  <KTSVG
                    path={"/media/icons/duotone/Shopping/Chart-line1.svg"}
                    className="svg-icon-2"
                  />
                </span>
                <span className="menu-title">
                  {intl.formatMessage({ id: "achievements" })}
                </span>
              </NavLink>
            </div>

            {/* Photo Gallery Begin */}

            <div className="menu-item">
              <NavLink className={`menu-link without-sub`} to="/photo-gallery">
                <span className="menu-icon">
                  <KTSVG
                    path={"/media/icons/duotone/Files/Pictures1.svg"}
                    className="svg-icon-2"
                  />
                </span>
                <span className="menu-title">
                  {intl.formatMessage({ id: "photo_gallery" })}
                </span>
              </NavLink>
            </div>

            {/* Digital Libraties Begin */}
            <div
              ref={libraryRef}
              onClick={(e) => {
                if (
                  e.target.parentNode === libraryRef.current.firstChild &&
                  libraryRef.current.classList.contains("show")
                ) {
                  libraryRef.current.classList.remove("show");
                } else {
                  libraryRef.current.classList.add("show");
                }
              }}
              className={`menu-item menu-accordion`}
            >
              <span className={`menu-link`}>
                <span className="menu-icon">
                  <KTSVG
                    path={"/media/icons/duotone/Shopping/Chart-bar1.svg"}
                    className="svg-icon-2"
                  />
                </span>
                <span className="menu-title">
                  {intl.formatMessage({ id: "digital_libraries" })}
                </span>
                <span className="menu-arrow"></span>
              </span>

              <div className="menu-sub menu-sub-accordion">
                <div className="menu-item">
                  <a
                    className={`menu-link without-sub`}
                    href="http://168.187.145.198/cgi-bin/koha/opac-main.pl?logout.x=1"
                    target="_blank"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">
                      {intl.formatMessage({ id: "online_catlog" })}
                    </span>
                  </a>
                </div>
                <div className="menu-item">
                  <a
                    className={`menu-link without-sub`}
                    href="http://168.187.145.198/cgi-bin/koha/opac-user.pl"
                    target="_blank"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">
                      {intl.formatMessage({ id: "my_library" })}
                    </span>
                  </a>
                </div>
              </div>
            </div>

            {/* Calendar Events Begin */}
            <div className="menu-item">
              <NavLink
                className={`menu-link without-sub`}
                to="/calendar-events"
              >
                <span className="menu-icon">
                  <KTSVG
                    path={"/media/icons/duotone/Shopping/Chart-bar2.svg"}
                    className="svg-icon-2"
                  />
                </span>
                <span className="menu-title">
                  {intl.formatMessage({ id: "events_calendar" })}
                </span>
              </NavLink>
            </div>

            {/* Discoveri Begin */}
            {is_discover > 0 && (
              <div className="menu-item">
                <NavLink className={`menu-link without-sub`} to="/discoveri">
                  <span className="menu-icon">
                    <KTSVG
                      path={"/media/icons/duotone/Design/Layers.svg"}
                      className="svg-icon-2"
                    />
                  </span>
                  <span className="menu-title">
                    {intl.formatMessage({ id: "discoveri" })}
                  </span>
                </NavLink>
              </div>
            )}

            {/* Notifications Begin */}
            <div className="menu-item">
              <NavLink className={`menu-link without-sub`} to="/notifications">
                <span className="menu-icon">
                  <KTSVG
                    path={"/media/icons/duotone/Communication/Mail.svg"}
                    className="svg-icon-2"
                  />
                </span>
                <span className="menu-title">
                  {intl.formatMessage({ id: "notifications" })}
                </span>
              </NavLink>
            </div>

            {/* Student Profile Begin */}
            <div className="menu-item">
              <NavLink
                className={`menu-link without-sub`}
                to="/student-profile"
              >
                <span className="menu-icon">
                  <KTSVG
                    path={"/media/icons/duotone/General/User.svg"}
                    className="svg-icon-2"
                  />
                </span>
                <span className="menu-title">
                  {intl.formatMessage({ id: "student_profile" })}
                </span>
              </NavLink>
            </div>

            {/* Feedback Begin */}
            {ptm_enable > 0 ? (
              <div className="menu-item">
                <NavLink className={`menu-link without-sub`} to="/ptm-feedback">
                  <span className="menu-icon">
                    <KTSVG
                      path={"/media/icons/duotone/Communication/Chat.svg"}
                      className="svg-icon-2"
                    />
                  </span>
                  <span className="menu-title">
                    {intl.formatMessage({ id: "ptm_feedback" })}
                  </span>
                </NavLink>
              </div>
            ) : (
              <div className="menu-item">
                <NavLink className={`menu-link without-sub`} to="/feedback">
                  <span className="menu-icon">
                    <KTSVG
                      path={"/media/icons/duotone/Communication/Chat.svg"}
                      className="svg-icon-2"
                    />
                  </span>
                  <span className="menu-title">
                    {intl.formatMessage({ id: "feedback" })}
                  </span>
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

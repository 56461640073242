import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import KTSVG from "../../components/SvgLoader";
import axios from "../../util/Api";
import { Modal, Table } from "antd";
import { Link } from "react-router-dom";
import CircularProgress from "../../components/CircularProgress";
import moment from "moment";

const BookFeeHistory = () => {
  const intl = useIntl();
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState({ isShow: false, data: "" });

  const [tableLoader, setTableLoader] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [query, setQuery] = useState("");
  const pageLimit = process.env.REACT_APP_TABLE_PAGE_SIZE;

  const titles = {
    islamic: "Islamic",
    non_islamic: "Non Islamic",
    science: "Science",
    commerce: "Commerce",
    humanities: "Humanities",
    notebook: "Notebook",
  };

  const statuses = {
    CAPTURED: "Success",
    "NOT CAPTURED": "Failed (Not Captured)",
    CANCELED: "Cancelled",
    FAILED: "Failed",
    processing: "In Progress",
  };

  const columns = [
    {
      title: "Sr.No.",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Paid Date",
      dataIndex: "payment_date",
      render: (date) => {
        return moment(date).format("Do MMM, YYYY h:mm A");
      },
    },
    {
      title: "Fee",
      dataIndex: "order_amount",
      key: "order_amount",
    },
    {
      title: "Status",
      render: (data) => {
        return statuses[data.payment_result];
      },
    },
    {
      title: "Action",
      render: (data) => {
        return (
          <Link
            to="#"
            onClick={() => popupShow(data)}
            className="btn btn-icon btn-flex btn-light-primary btn-sm me-1"
          >
            <KTSVG
              path="/media/icons/duotone/General/Visible.svg"
              className="svg-icon-3"
            />
          </Link>
        );
      },
    },
  ];

  useEffect(() => {
    setTableLoader(true);
    getData(1, query);
  }, []);

  const getData = (page, query) => {
    axios
      .post(`notebook/fee_history?page=${page}`, {
        search: query,
        limit: pageLimit,
      })
      .then(({ data }) => {
        setLoading(false);
        setTableLoader(false);
        if (data.result) {
          setPayments(data.fees_history.data);
          setTotalRecord(data.fees_history.total);
        }
      });
  };

  const searchRecord = (query) => {
    if (query == "0") {
      setQuery("");
      setTableLoader(true);
      getData(1, "");
    } else {
      setQuery(query);
      setTableLoader(true);
      getData(1, query);
    }
  };

  const handleTableChange = (pagination) => {
    setTableLoader(true);
    getData(pagination.current, query);
  };

  const popupShow = (data) => {
    axios.post("notebook/view_fees", { order_id: data.id }).then(({ data }) => {
      setModalShow({ isShow: true, data: data.data });
    });
  };

  return (
    <>
      {modalShow.isShow ? (
        <Modal
          title="Fees Details"
          visible={modalShow.isShow}
          onCancel={() => {
            setModalShow({ isShow: false, data: "" });
          }}
          footer={null}
        >
          <div className="table-responsive">
            <table
              className="table align-middle fs-6 gy-5"
              id="kt_assignment_table"
            >
              <tbody className="fw-bold text-gray-600">
                <tr className="bg-primary text-white">
                  <td className="text-center" colSpan={2}>
                    {modalShow.data.student_info.map((data, index) => {
                      return `${data.student_username}-${data.full_name}`;
                    })}
                  </td>
                </tr>
                {modalShow.data.order_metas.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td className="ps-5">{titles[data.fees_type]}</td>
                      <td>KD {data.fees}</td>
                    </tr>
                  );
                })}
                <tr className="bg-success text-white">
                  <td className="ps-5">{"Payment Status"}</td>
                  <td>
                    {modalShow.data.payment_info.map((data, index) => {
                      return `${statuses[data.payment_result]}`;
                    })}
                  </td>
                </tr>
                <tr>
                  <td className="ps-5">{"Total Amount"}</td>
                  <td>
                    {modalShow.data.payment_info.map((data, index) => {
                      return `KD ${parseFloat(data.order_amount).toFixed(3)}`;
                    })}
                  </td>
                </tr>
                <tr>
                  <td className="ps-5">{"Payment Method"}</td>
                  <td>
                    {modalShow.data.payment_info.map((data, index) => {
                      return `KNET ${data.auth != "" ? `(${data.auth})` : ""}`;
                    })}
                  </td>
                </tr>
                <tr>
                  <td className="ps-5">{"Transaction ID"}</td>
                  <td>
                    {modalShow.data.payment_info.map((data, index) => {
                      return `${data.tranid}`;
                    })}
                  </td>
                </tr>
                <tr>
                  <td className="ps-5">{"Track ID"}</td>
                  <td>
                    {modalShow.data.payment_info.map((data, index) => {
                      return `${data.trackid}`;
                    })}
                  </td>
                </tr>
                <tr>
                  <td className="ps-5">{"Reference ID"}</td>
                  <td>
                    {modalShow.data.payment_info.map((data, index) => {
                      return `${data.ref}`;
                    })}
                  </td>
                </tr>
                <tr>
                  <td className="ps-5">{"Payment Date"}</td>
                  <td>
                    {modalShow.data.payment_info.map((data, index) => {
                      return `${moment(data.payment_date).format(
                        "Do MMM, YYYY h:mm A"
                      )}`;
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal>
      ) : (
        <></>
      )}

      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "book_fee_payment" })}
              <span className="h-20px border-gray-200 border-start mx-4"></span>
              <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li className="breadcrumb-item text-dark">
                  {intl.formatMessage({ id: "fee_history" })}
                </li>
              </ul>
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            <div className={`card mb-5 mb-xxl-8 card-xxl-streatch`}>
              <div className="card-header border-0 pt-6">
                <div className="mb-3">
                  <select
                    className="form-select form-select-solid fw-bolder select2-hidden-accessible"
                    data-kt-select2="true"
                    data-placeholder="Select Subject"
                    data-allow-clear="true"
                    data-kt-assignment-table-filter="subject"
                    aria-hidden="true"
                    onChange={(e) => searchRecord(e.currentTarget.value)}
                  >
                    <option value={"0"} data-select2-id="select2-data-3-dyom">
                      Select Status
                    </option>
                    {Object.keys(statuses).map((data, index) => {
                      return (
                        <option key={index} value={data.toString()}>
                          {statuses[data]}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="card-body pt-5">
                <div className="table-responsive">
                  <Table
                    rowKey={"id"}
                    dataSource={payments}
                    columns={columns}
                    onChange={handleTableChange}
                    loading={tableLoader}
                    pagination={{
                      total: totalRecord,
                      defaultPageSize: pageLimit,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BookFeeHistory;

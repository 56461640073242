import { Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CircularProgress from "../../components/CircularProgress";
import KTSVG from "../../components/SvgLoader";
import axios from "../../util/Api";

const Discover = () => {
  const intl = useIntl();
  const [discover, setDiscover] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.post(`discoveri/view_discover`).then(({ data }) => {
      setLoading(false);
      if (data.result) {
        setDiscover(data.discoveri_details);
      }
    });
  }, []);

  return (
    <>
      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "discoveri" })}
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            <div className={`card mb-5 mb-xxl-8 card-xxl-streatch`}>
              <div className="card-body pt-5">
                <div className="table-responsive">
                  <table
                    className="table align-middle text-center table-striped fs-6 gy-5"
                    id="kt_assignment_table"
                  >
                    <thead className="fw-bold text-gray-600">
                      <tr>
                        <th>Sr.No.</th>
                        <th>Branch</th>
                        <th>Name</th>
                        <th>Admission No.</th>
                        <th>Class</th>
                        <th>Section</th>
                        <th>English</th>
                        <th>Maths</th>
                        <th>Science</th>
                        <th>GK</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {discover != null ? (
                        <tr>
                          <td>{discover.dis_id}</td>
                          <td>{discover.dis_branch}</td>
                          <td>{discover.dis_name}</td>
                          <td>{discover.dis_admno}</td>
                          <td>{discover.dis_class}</td>
                          <td>{discover.dis_section}</td>
                          <td>{discover.dis_eng}</td>
                          <td>{discover.dis_maths}</td>
                          <td>{discover.dis_evs}</td>
                          <td>{discover.dis_gk}</td>
                          <td>{discover.dis_total}</td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan={11} className="text-warning">
                            No Data Available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Discover;

import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import KTSVG from "../../components/SvgLoader";
import CircularProgress from "../../components/CircularProgress";
import axios from "../../util/Api";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Table, Modal } from "antd";
import moment from "moment";
import { toAbsoluteUrl } from "../../util/AssetHelper";

const TuritoRegistration = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [student, setStudent] = useState();
  const [selectedCourse, setSelectedCourse] = useState("0");
  const [courseFee, setCourseFee] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [courses, setCourses] = useState([]);
  const [selectedCourseData, setSelectedCourseData] = useState({});
  const [payments, setPayments] = useState([]);
  const [tableLoader, setTableLoader] = useState(false);

  const [error, setError] = useState({ value: false, message: "" });
  const [success, setSuccess] = useState({ value: false, message: "" });
  const intl = useIntl();

  const columns = [
    {
      title: "Sl.No.",
      render: (data, d, index) => index + 1,
    },
    {
      title: "Course",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Fees",
      dataIndex: "order_amount",
      key: "order_amount",
      render: (data) => parseFloat(data).toFixed(3),
    },
    {
      title: "Status",
      dataIndex: "payment_result",
      key: "payment_result",
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      render: (date) => {
        return moment(date).format("Do MMM - h:mm A");
      },
    },
  ];

  useEffect(() => {
    axios.post("turito_registration/details").then(({ data }) => {
      setLoading(false);
      setTableLoader(false);
      if (data.result) {
        setStudent(data.student_data);
        setCourses(data.courses);
        let modifiedPayments = data.payment_data.map((d, i) => {
          let obj = d;
          return { sl_no: i, ...obj };
        });
        setPayments(modifiedPayments);
      }
    });
  }, []);

  const submitFeedback = () => {
    setError({ value: false, message: "" });
    if (selectedCourse == "0") {
      setError({
        value: true,
        message: "Course selection is required.",
      });
    } else {
      axios
        .post("turito_registration/submit_registration", {
          course_id: selectedCourseData.course_id,
          payment:
            paymentMethod == "1"
              ? selectedCourseData.discount_price
              : selectedCourseData.installment_price,
          payment_type: paymentMethod,
        })
        .then(({ data }) => {
          if (data.result) {
            window.open(data.payment_link);
          }
        });
    }
  };

  const cancelForm = () => {
    setSelectedCourse("0");
    setCourseFee("");
    setSelectedCourseData({});
    setPaymentMethod("");
  };

  const setCourse = (e) => {
    if (e.currentTarget.value == "0") {
      setSelectedCourse("0");
      setCourseFee("");
      setSelectedCourseData({});
      setPaymentMethod("");
    } else {
      let course = courses.find((x) => x.title == e.currentTarget.value);
      setSelectedCourse(course.title);
      setCourseFee(course.course_fee);
      setSelectedCourseData(course);
      setPaymentMethod("1");
    }
  };

  return (
    <>
      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "turito_registration" })}
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {payments.length != 0 && (
            <div className="post d-flex flex-column-fluid" id="kt_post">
              <div id="kt_content_container" className="container-fluid">
                <div className={`card mb-5 mb-xxl-8 card-xxl-streatch`}>
                  <div className="card-body py-10">
                    <div className="table-responsive">
                      <Table
                        rowKey={"sl_no"}
                        dataSource={payments}
                        columns={columns}
                        loading={tableLoader}
                        pagination={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="post d-flex flex-column-fluid" id="kt_post">
            <div id="kt_content_container" className="container-fluid">
              <div className={`card mb-5 mb-xxl-8 card-xxl-streatch`}>
                <div className="card-body py-10">
                  <div className="col-md-6 pb-5">
                    <h6 className="mb-4 text-bold">Admission No *</h6>
                    <input
                      className="form-control"
                      placeholder={"Admission No."}
                      readOnly={true}
                      value={
                        student != undefined ? student.student_username : ""
                      }
                    />
                  </div>
                  <div className="col-md-6 pb-5">
                    <h6 className="mb-4 text-bold">Name of candidate *</h6>
                    <input
                      className="form-control"
                      placeholder={"Name"}
                      readOnly={true}
                      value={student != undefined ? student.full_name : ""}
                    />
                  </div>
                  <div className="col-md-6 pb-5">
                    <h6 className="mb-4 text-bold">Select Course *</h6>
                    <select
                      className="form-select form-select-solid fw-bolder select2-hidden-accessible"
                      onChange={(e) => setCourse(e)}
                      value={selectedCourse}
                    >
                      <option value={"0"} data-select2-id="select2-data-3-dyom">
                        Select Course
                      </option>
                      {courses.map((data, index) => {
                        return (
                          <option key={index} value={data.title} key={index}>
                            {data.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-6 pb-5">
                    <h6 className="mb-4 text-bold">Course Fee</h6>
                    <input
                      className="form-control"
                      placeholder={"Course Fee"}
                      readOnly={true}
                      value={courseFee}
                    />
                  </div>
                  <div className="col-md-6 pb-5">
                    <h6 className="mb-4 text-bold">Payment *</h6>
                    {selectedCourse != "0" && (
                      <div>
                        <label className="form-check form-check-custom form-check-solid form-check-inline mb-4">
                          <input
                            className="form-check-input mr-5"
                            type="radio"
                            value={"1"}
                            name="fees"
                            checked={paymentMethod == "1"}
                            onChange={(e) => setPaymentMethod("1")}
                          />
                          <span className="form-check-label fw-bold text-gray-700 fs-6 mb-0">
                            {`KD ${parseFloat(
                              selectedCourseData.discount_price
                            ).toFixed(3)} (Full payment with 5% discount)`}
                          </span>
                        </label>
                        <label className="form-check form-check-custom form-check-solid form-check-inline mb-4">
                          <input
                            className="form-check-input mr-5"
                            type="radio"
                            value={"2"}
                            name="fees"
                            checked={paymentMethod == "2"}
                            onChange={(e) => setPaymentMethod("2")}
                          />
                          <span className="form-check-label fw-bold text-gray-700 fs-6 mb-0">
                            {`KD ${parseFloat(
                              selectedCourseData.installment_price
                            ).toFixed(
                              3
                            )} (2 Installments 1st while registering, 2nd in 6 months)`}
                          </span>
                        </label>
                      </div>
                    )}
                  </div>
                  {error.value && (
                    <div className="text-white bg-danger py-5 px-5 mb-5">
                      {error.message}
                    </div>
                  )}
                  {success.value && (
                    <div className="text-white bg-success py-5 px-5 mb-5">
                      {success.message}
                    </div>
                  )}
                  <div className="d-flex justify-content-start">
                    <button
                      className="btn btn-primary me-2"
                      onClick={submitFeedback}
                    >
                      Submit
                    </button>
                    <button
                      className="btn btn-danger ms-2"
                      onClick={cancelForm}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TuritoRegistration;

import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import KTSVG from "../../components/SvgLoader";
import CircularProgress from "../../components/CircularProgress";
import axios from "../../util/Api";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Table, Modal, Empty } from "antd";
import moment from "moment";

const TeacherMessage = () => {
  const history = useHistory();
  const location = useLocation();
  const [messages, setMessages] = useState([]);
  const [archives, setArchives] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeLink, setActiveLink] = useState("Inbox");
  const intl = useIntl();

  useEffect(() => {
    axios.post("communication/communication").then(({ data }) => {
      setLoading(false);
      if (data.result) {
        setMessages(data.communictions);
        setArchives(data.archives);
      }
    });
  }, []);

  useEffect(() => {
    if (location.state && location.state.activeLink != null) {
      setActiveLink(location.state.activeLink);
    }
  }, []);

  return (
    <>
      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "communication" })}
              <span className="h-20px border-gray-200 border-start mx-4"></span>
              <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li className="breadcrumb-item text-dark">
                  {intl.formatMessage({ id: "teacher_messages" })}
                </li>
                <li className="breadcrumb-item px-3">
                  <span className="bullet bg-gray-200 w-5px h-2px"></span>
                </li>
                <li className="breadcrumb-item text-dark">{activeLink}</li>
              </ul>
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            <div className="">
              <div className="d-flex flex-lg-row flex-column">
                <div
                  className="flex-row-auto offcanvas-mobile mb-5 w-lg-200px w-xxl-275px"
                  id="kt-inbox-aside"
                >
                  <div className="card card-custom card-stretch">
                    <div className="card-body px-5">
                      <div className="navi navi-hover navi-active navi-link-rounded navi-bold navi-icon-center navi-light-icon">
                        <div className="navi-item my-2">
                          <Link
                            to="#"
                            className={`navi-link ${
                              activeLink === "Inbox" ? "active" : ""
                            }`}
                            onClick={() => {
                              setActiveLink("Inbox");
                            }}
                          >
                            <span className="navi-icon me-4">
                              <span className="svg-icon svg-icon-lg">
                                <KTSVG
                                  path="/media/icons/duotone/Communication/Mail-heart.svg"
                                  className="svg-icon-1"
                                />
                              </span>
                            </span>
                            <span className="navi-text fw-bolder font-size-lg">
                              Inbox
                            </span>
                          </Link>
                        </div>
                        <div className="navi-item my-2">
                          <Link
                            to="#"
                            onClick={() => {
                              setActiveLink("Archive");
                            }}
                            className={`navi-link ${
                              activeLink === "Archive" ? "active" : ""
                            }`}
                          >
                            <span className="navi-icon me-4">
                              <span className="svg-icon svg-icon-lg">
                                <KTSVG
                                  path="/media/icons/duotone/Communication/Archive.svg"
                                  className="svg-icon-1"
                                />
                              </span>
                            </span>
                            <span className="navi-text fw-bolder font-size-lg">
                              Archive
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="flex-row-fluid ms-lg-8 d-block"
                  id="kt-inbox-list"
                >
                  <div className="card card-custom card-stretch">
                    <div className="card-body table-responsive px-5">
                      <div>
                        <h2>{activeLink}</h2>
                      </div>
                      <div className="table-responsive">
                        <table
                          className="table align-middle table-row-dashed table-striped fs-6 gy-5"
                          id="kt_assignment_table"
                        >
                          {activeLink === "Inbox" ? (
                            <tbody className="fw-bold text-gray-600">
                              {messages.length == 0 && <Empty />}
                              {messages.map((data, index) => {
                                return (
                                  <tr
                                    key={index}
                                    onClick={() =>
                                      history.push({
                                        pathname:
                                          "/communication/teacher-messages/read-message",
                                        state: {
                                          activeLink: activeLink,
                                          messageData: data,
                                        },
                                      })
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <td>#</td>
                                    <td>{data.sender_name.full_name}</td>
                                    <td>{data.subject}</td>
                                    <td>
                                      {moment(data.add_date).format(
                                        "Do MMM,h:MM A"
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          ) : (
                            <></>
                          )}
                          {activeLink === "Archive" ? (
                            <tbody className="fw-bold text-gray-600">
                              {archives.length == 0 && <Empty />}
                              {archives.map((data, index) => {
                                return (
                                  <tr
                                    key={index}
                                    onClick={() =>
                                      history.push({
                                        pathname:
                                          "/communication/teacher-messages/read-message",
                                        state: {
                                          activeLink: activeLink,
                                          messageData: data,
                                        },
                                      })
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <td>#</td>
                                    <td>{data.sender_name.full_name}</td>
                                    <td>{data.subject}</td>
                                    <td>
                                      {moment(data.add_date).format(
                                        "Do MMM,h:MM A"
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          ) : (
                            <></>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TeacherMessage;

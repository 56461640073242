import moment from "moment";
import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useIntl } from "react-intl";
import { Link, useHistory, useLocation } from "react-router-dom";
import CircularProgress from "../../components/CircularProgress";
import KTSVG from "../../components/SvgLoader";
import axios from "../../util/Api";
import { toAbsoluteUrl } from "../../util/AssetHelper";

const ReadMessage = () => {
  const location = useLocation();
  const history = useHistory();
  const [messages, setMessages] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeLink, setActiveLink] = useState("Inbox");
  const [replyMessages, setReplyMessages] = useState([]);

  const intl = useIntl();

  useEffect(() => {
    axios
      .post(`communication/read_mail/${location.state.messageData.id}`)
      .then(({ data }) => {
        if (data.result) {
          setReplyMessages(data.reply_messages);
          setMessages(location.state.messageData);
          setActiveLink(location.state.activeLink);
          setLoading(false);
        }
      });
  }, []);

  const archieveMessage = (id) => {
    axios.post("communication/add_archive", { id: id }).then(({ data }) => {
      if (data.result) {
        history.push({
          pathname: "/communication/teacher-messages",
          state: { activeLink: "Archive" },
        });
      }
    });
  };

  return (
    <>
      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "communication" })}
              <span className="h-20px border-gray-200 border-start mx-4"></span>
              <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li className="breadcrumb-item text-muted">
                  <a
                    onClick={() => {
                      history.push({
                        pathname: "/communication/teacher-messages",
                        state: { activeLink: "Inbox" },
                      });
                    }}
                    className="text-muted text-hover-primary"
                  >
                    {intl.formatMessage({ id: "teacher_messages" })}
                  </a>
                </li>
                <li className="breadcrumb-item px-3">
                  <span className="bullet bg-gray-200 w-5px h-2px"></span>
                </li>
                <li className="breadcrumb-item text-dark">
                  {intl.formatMessage({ id: "read_message" })}
                </li>
              </ul>
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            <div className="">
              <div className="d-flex flex-lg-row flex-column">
                <div
                  className="flex-row-auto offcanvas-mobile mb-5 w-lg-200px w-xxl-275px"
                  id="kt-inbox-aside"
                >
                  <div className="card card-custom card-stretch">
                    <div className="card-body px-5">
                      <div className="navi navi-hover navi-active navi-link-rounded navi-bold navi-icon-center navi-light-icon">
                        <div className="navi-item my-2">
                          <a
                            href="#"
                            className={`navi-link ${
                              activeLink === "Inbox" ? "active" : ""
                            }`}
                            onClick={() => {
                              history.push({
                                pathname: "/communication/teacher-messages",
                                state: { activeLink: "Inbox" },
                              });
                            }}
                          >
                            <span className="navi-icon me-4">
                              <span className="svg-icon svg-icon-lg">
                                <KTSVG
                                  path="/media/icons/duotone/Communication/Mail-heart.svg"
                                  className="svg-icon-1"
                                />
                              </span>
                            </span>
                            <span className="navi-text fw-bolder font-size-lg">
                              Inbox
                            </span>
                          </a>
                        </div>
                        <div className="navi-item my-2">
                          <a
                            href="#"
                            onClick={() => {
                              history.push({
                                pathname: "/communication/teacher-messages",
                                state: { activeLink: "Archive" },
                              });
                            }}
                            className={`navi-link ${
                              activeLink === "Archive" ? "active" : ""
                            }`}
                          >
                            <span className="navi-icon me-4">
                              <span className="svg-icon svg-icon-lg">
                                <KTSVG
                                  path="/media/icons/duotone/Communication/Archive.svg"
                                  className="svg-icon-1"
                                />
                              </span>
                            </span>
                            <span className="navi-text fw-bolder font-size-lg">
                              Archive
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="flex-row-fluid ms-lg-8 d-block"
                  id="kt-inbox-list"
                >
                  <div className="card card-custom card-stretch">
                    <div className="card-body table-responsive px-5">
                      <div>
                        <h2>Read Message</h2>
                        <h1>{messages.subject}</h1>
                      </div>
                      <Accordion defaultActiveKey="-1">
                        {replyMessages.map((data, index) => {
                          return (
                            <Accordion.Item
                              key={index}
                              className="mb-3"
                              eventKey={index.toString()}
                            >
                              <Accordion.Header>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <div>From: {data.sender_name.full_name}</div>
                                  <div className="me-3">
                                    {moment(data.add_date).format(
                                      "Do MMM,h:MM A"
                                    )}
                                  </div>
                                </div>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: data.message,
                                    }}
                                  />
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {data.communication_attachment != "" ? (
                                      <a
                                        download
                                        target="_blank"
                                        href={`${process.env.REACT_APP_STORAGE_PATH}/storage/${data.communication_attachment}`}
                                        type="button"
                                        className="btn btn-light-primary"
                                      >
                                        Download
                                      </a>
                                    ) : (
                                      <div></div>
                                    )}
                                    <div>
                                      <button
                                        type="button"
                                        className="btn btn-light-primary me-3"
                                        onClick={() =>
                                          history.push({
                                            pathname:
                                              "/communication/teacher-messages/reply-message",
                                            state: {
                                              activeLink: activeLink,
                                              messageData: data,
                                            },
                                          })
                                        }
                                      >
                                        <span className="svg-icon svg-icon-lg">
                                          <KTSVG
                                            path="/media/icons/duotone/Communication/Reply.svg"
                                            className="svg-icon-3"
                                          />
                                        </span>
                                        Reply
                                      </button>

                                      <button
                                        type="button"
                                        onClick={() => archieveMessage(data.id)}
                                        className="btn btn-light-primary"
                                      >
                                        <span className="svg-icon svg-icon-lg">
                                          <KTSVG
                                            path="/media/icons/duotone/Communication/Archive.svg"
                                            className="svg-icon-3"
                                          />
                                        </span>
                                        Archive
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          );
                        })}
                      </Accordion>
                      <Accordion className="mb-3" defaultActiveKey="-2">
                        <Accordion.Item eventKey="-2">
                          <Accordion.Header>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <div>From: {messages.sender_name.full_name}</div>
                              <div className="me-3">
                                {moment(messages.add_date).format(
                                  "Do MMM,h:MM A"
                                )}
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: messages.message,
                                }}
                              />
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                {messages.communication_attachment != "" ? (
                                  <a
                                    download
                                    target="_blank"
                                    href={`${process.env.REACT_APP_STORAGE_PATH}/storage/${messages.communication_attachment}`}
                                    type="button"
                                    className="btn btn-light-primary"
                                  >
                                    Download
                                  </a>
                                ) : (
                                  <div></div>
                                )}
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-light-primary me-3"
                                    onClick={() =>
                                      history.push({
                                        pathname:
                                          "/communication/teacher-messages/reply-message",
                                        state: {
                                          activeLink: activeLink,
                                          messageData: messages,
                                        },
                                      })
                                    }
                                  >
                                    <KTSVG
                                      path="/media/icons/duotone/Communication/Reply.svg"
                                      className="svg-icon-3"
                                    />
                                    Reply
                                  </button>

                                  <button
                                    type="button"
                                    onClick={() => archieveMessage(messages.id)}
                                    className="btn btn-light-primary"
                                  >
                                    <KTSVG
                                      path="/media/icons/duotone/Communication/Archive.svg"
                                      className="svg-icon-3"
                                    />
                                    Archive
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReadMessage;

import { Table, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import CircularProgress from "../../../components/CircularProgress";
import { DropdownTopbarItemToggler } from "../../../components/DropdownTopbarItemToggler";
import KTSVG from "../../../components/SvgLoader";
import axios from "../../../util/Api";

const ReferenceMaterial = () => {
  const intl = useIntl();
  const [tableLoader, setTableLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalRecord, setTotalRecord] = useState(0);
  const [query, setQuery] = useState("");
  const [materials, setMaterials] = useState([]);
  const [subjects, setSubjects] = useState({});
  const [search, setSearch] = useState(false);
  const [searchSubject, setSearchSubject] = useState("");
  const [modalShow, setModalShow] = useState({ isShow: false, data: "" });
  const pageLimit = process.env.REACT_APP_TABLE_PAGE_SIZE;

  const columns = [
    {
      title: "Sl.No.",
      dataIndex: "document_id",
      key: "document_id",
    },
    {
      title: "Title",
      dataIndex: "document_title",
      key: "document_title",
    },
    {
      title: "Teacher",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Subject",
      dataIndex: "subject_title",
      key: "subject_title",
    },
    {
      title: "Attachment",
      render: (data) => {
        return (
          <div>
            {data.document_link != "" ? (
              <a
                href={`${
                  data.document_link.includes("http")
                    ? data.document_link
                    : "http://" + data.document_link
                }`}
                target="_blank"
                className="btn btn-icon btn-flex btn-light-info btn-sm me-1"
              >
                <KTSVG
                  path="/media/icons/duotone/General/Attachment1.svg"
                  className="svg-icon-3"
                />
              </a>
            ) : (
              <></>
            )}
            {data.attachments.map((d, i) => {
              return (
                <a
                  key={i}
                  href={`${process.env.REACT_APP_STORAGE_PATH}/storage/uploads/documents/${d}`}
                  target="_blank"
                  download
                  className="btn btn-icon btn-flex btn-light-primary btn-sm me-1"
                >
                  <KTSVG
                    path="/media/icons/duotone/General/Attachment1.svg"
                    className="svg-icon-3"
                  />
                </a>
              );
            })}
          </div>
        );
      },
    },
    {
      title: "Action",
      render: (data) => {
        return (
          <Link
            to="#"
            onClick={() => setModalShow({ isShow: true, data: data })}
            className="btn btn-icon btn-flex btn-light-primary btn-sm me-1"
          >
            <KTSVG
              path="/media/icons/duotone/General/Visible.svg"
              className="svg-icon-3"
            />
          </Link>
        );
      },
    },
  ];

  useEffect(() => {
    setTableLoader(true);
    getData(1, query);
  }, []);

  const getData = (page, query) => {
    axios
      .post(`academics/reference_material/list?page=${page}`, {
        search: query,
        limit: pageLimit,
      })
      .then(({ data }) => {
        setLoading(false);
        setTableLoader(false);
        if (data.result) {
          setTotalRecord(data.list.total);
          setMaterials(data.list.data);
          setSubjects(data.subject);
        }
      });
  };

  const searchRecord = (query) => {
    if (query != "" || searchSubject != "") {
      setQuery(query);
      setSearch(true);
      setTableLoader(true);
      searchResult(1, query, searchSubject);
    } else {
      setQuery(query);
      setSearch(false);
      setTableLoader(true);
      getData(1, query);
    }
  };

  const searchResult = (page, query, subjectId) => {
    axios
      .post(`academics/reference_material/search?page=${page}`, {
        search: query,
        limit: pageLimit,
        subject_id: subjectId,
      })
      .then(({ data }) => {
        setLoading(false);
        setTableLoader(false);
        if (data.result) {
          setTotalRecord(data.list.total);
          setMaterials(data.list.data);
        }
      });
  };

  const submitFilter = () => {
    searchResult(1, query, searchSubject);
  };

  const handleTableChange = (pagination) => {
    setTableLoader(true);
    if (search) {
      searchResult(pagination.current, query, searchSubject);
    } else {
      getData(pagination.current, query);
    }
  };

  return (
    <>
      {modalShow.isShow ? (
        <Modal
          title="View Document"
          visible={modalShow.isShow}
          onCancel={() => {
            setModalShow({ isShow: false, data: "" });
          }}
          footer={null}
        >
          <div className="table-responsive">
            <table
              className="table align-middle table-striped fs-6 gy-5"
              id="kt_assignment_table"
            >
              <tbody className="fw-bold text-gray-600">
                <tr>
                  <td>Document Title</td>
                  <td>{modalShow.data.document_title}</td>
                </tr>
                <tr>
                  <td>Subject</td>
                  <td>{modalShow.data.subject_title}</td>
                </tr>
                <tr>
                  <td>Assigned On</td>
                  <td className="text-success">
                    {moment(modalShow.data.assign_on).format(
                      "Do MMM, YYYY h:mm A"
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Document Link</td>
                  <td>
                    {modalShow.data.document_link != "" ? (
                      <a href={modalShow.data.document_link} target="_blank">
                        {modalShow.data.document_link}
                      </a>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Document Description</td>
                  <td>{modalShow.data.document_description}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal>
      ) : (
        <></>
      )}

      {/* Toolbar Start */}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              {intl.formatMessage({ id: "academics" })}
              <span className="h-20px border-gray-200 border-start mx-4"></span>
              <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li className="breadcrumb-item text-dark">
                  {intl.formatMessage({ id: "reference_material" })}
                </li>
              </ul>
            </h1>
          </div>
        </div>
      </div>
      {/* Toolbar end */}

      {loading ? (
        <CircularProgress />
      ) : (
        <div id="kt_content_container" className="container-fluid">
          <div className={`card mb-5 mb-xxl-8 card-xxl-streatch`}>
            <div className="card-header border-0 pt-6">
              <div className="card-title">
                <div className="d-flex align-items-center position-relative my-1">
                  <span className="svg-icon svg-icon-1 position-absolute ms-6">
                    <KTSVG
                      path="/media/icons/duotone/General/Search.svg"
                      className="svg-icon-1"
                    />
                  </span>

                  <input
                    type="text"
                    data-kt-assignment-table-filter="search"
                    className="form-control form-control-solid w-250px ps-15"
                    placeholder="Search"
                    onChange={(e) => searchRecord(e.currentTarget.value)}
                  />
                </div>
              </div>

              <div className="card-toolbar">
                <div
                  className="d-flex justify-content-end"
                  data-kt-assignment-table-toolbar="base"
                >
                  <Dropdown
                    drop="down"
                    className="d-flex align-items-center ms-1 ms-lg-3"
                    alignright={"true"}
                  >
                    <Dropdown.Toggle
                      as={DropdownTopbarItemToggler}
                      id="dropdown-toggle-my-cart"
                    >
                      <button
                        type="button"
                        className="btn btn-light-primary me-3"
                      >
                        <span className="svg-icon svg-icon-2">
                          <KTSVG
                            path="/media/icons/duotone/Text/Filter.svg"
                            className="svg-icon-1"
                          />
                        </span>
                        {intl.formatMessage({ id: "filter" })}
                      </button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-300px">
                      <div>
                        <div className="px-7 py-5">
                          <div className="fs-4 text-dark fw-bolder">
                            {intl.formatMessage({ id: "filter_option" })}
                          </div>
                        </div>

                        <div className="separator border-gray-200"></div>

                        <div className="px-7 py-5">
                          <div className="mb-10">
                            <label className="form-label fs-5 fw-bold mb-3">
                              {intl.formatMessage({ id: "subject" })}:
                            </label>

                            <select
                              className="form-select form-select-solid fw-bolder select2-hidden-accessible"
                              data-kt-select2="true"
                              data-placeholder="Select Subject"
                              data-allow-clear="true"
                              data-kt-assignment-table-filter="subject"
                              aria-hidden="true"
                              tabIndex={-1}
                              value={searchSubject}
                              onChange={(e) =>
                                setSearchSubject(e.currentTarget.value)
                              }
                            >
                              <option value="">Select Subject</option>
                              {Object.keys(subjects).map((data, index) => {
                                return (
                                  <option key={index} value={data}>
                                    {subjects[data]}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          <div className="d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn btn-light btn-active-light-primary me-2"
                              data-kt-menu-dismiss="true"
                              data-kt-assignment-table-filter="reset"
                              onClick={() => {
                                setSearchSubject("");
                              }}
                            >
                              {intl.formatMessage({ id: "reset" })}
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-kt-menu-dismiss="true"
                              data-kt-assignment-table-filter="filter"
                              onClick={() => submitFilter()}
                            >
                              {intl.formatMessage({ id: "apply" })}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="card-body pt-5">
              <div className="table-responsive">
                <Table
                  rowKey={"document_id"}
                  dataSource={materials}
                  columns={columns}
                  onChange={handleTableChange}
                  loading={tableLoader}
                  pagination={{
                    total: totalRecord,
                    defaultPageSize: pageLimit,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReferenceMaterial;

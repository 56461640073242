import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import ClientCaptcha from "react-client-captcha";
import axios from "../../util/Api";
import { useDispatch, useSelector } from "react-redux";
import { fetchStart, fetchSuccess } from "../../appRedux/actions/Common";

const initialValues = {
  email: "",
  captcha: "",
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
  captcha: Yup.string().required("Captcha is required"),
});

export function ForgotPasswordSendMail(props) {
  const history = useHistory();
  const location = useLocation();
  const { loading } = useSelector(({ common }) => common);
  const dispatch = useDispatch();
  const [captchaCode, setCaptchaCode] = useState("");
  const intl = useIntl();
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setFieldError, setSubmitting }) => {
      if (captchaCode !== values.captcha) {
        setFieldError("captcha", "Invalid Captcha!");
        setSubmitting(false);
      } else {
        dispatch(fetchStart());
        axios
          .post("auth/forgot_password_send_email", {
            email: values.email,
            access_key: location.state.access_key,
          })
          .then(({ data }) => {
            dispatch(fetchSuccess());
            setSubmitting(false);
            if (data.result) {
              history.push({
                pathname: "/auth/forgot-password-verify-code",
                state: location.state,
              });
            } else {
              formik.setStatus(data.error);
            }
          });
      }
    },
  });

  return (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">
            {intl.formatMessage({ id: "select_email" })}
          </h1>

          <div className="text-gray-400 fw-bold fs-4">
            {intl.formatMessage({ id: "please_select_email" })}
          </div>
        </div>

        {formik.status ? (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : null}

        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            {intl.formatMessage({ id: "email" })}
          </label>

          {location.state.parent_email != "" ? (
            <label className="form-check form-check-custom form-check-solid form-check-inline mb-4">
              <input
                className="form-check-input"
                style={{ marginRight: 8 }}
                {...formik.getFieldProps("email")}
                type="radio"
                value={location.state.parent_email}
                name="email"
                label={location.state.parent_email}
              />
              <span className="form-check-label fw-bold text-gray-700 fs-6 mb-0">
                {location.state.parent_email}
              </span>
            </label>
          ) : (
            <></>
          )}
          {location.state.mother_email != "" ? (
            <label className="form-check form-check-custom form-check-solid form-check-inline mb-4">
              <input
                className="form-check-input"
                style={{ marginRight: 8 }}
                {...formik.getFieldProps("email")}
                type="radio"
                value={location.state.mother_email}
                name="email"
                label={location.state.mother_email}
              />
              <span className="form-check-label fw-bold text-gray-700 fs-6 mb-0">
                {location.state.mother_email}
              </span>
            </label>
          ) : (
            <></>
          )}
          {location.state.student_email != "" ? (
            <label className="form-check form-check-custom form-check-solid form-check-inline mb-4">
              <input
                className="form-check-input"
                style={{ marginRight: 8 }}
                {...formik.getFieldProps("email")}
                type="radio"
                value={location.state.student_email}
                name="email"
                label={location.state.student_email}
              />
              <span className="form-check-label fw-bold text-gray-700 fs-6 mb-0">
                {location.state.student_email}
              </span>
            </label>
          ) : (
            <></>
          )}
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>

        <div className="captcha-container">
          <ClientCaptcha
            fontFamily={"Franconia"}
            charsCount={5}
            retryIconSize={18}
            captchaCode={(code) => setCaptchaCode(code)}
          />
        </div>

        <div className="fv-row mb-10 mt-5">
          <label className="form-label fs-6 fw-bolder text-dark">
            {intl.formatMessage({ id: "enter_captcha" })}
          </label>
          <input
            {...formik.getFieldProps("captcha")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid": formik.touched.captcha && formik.errors.captcha,
              },
              {
                "is-valid": formik.touched.captcha && !formik.errors.captcha,
              }
            )}
            type="text"
            name="captcha"
            autoComplete="off"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          />
          {formik.touched.captcha && formik.errors.captcha && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.captcha}</span>
              </div>
            </div>
          )}
        </div>

        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="submit"
            id="kt_password_reset_submit"
            className="btn btn-lg btn-primary fw-bolder me-4"
          >
            {loading ? (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "please_wait" })}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            ) : (
              <span className="indicator-label">
                {intl.formatMessage({ id: "submit" })}
              </span>
            )}
          </button>
          <Link to="/auth/signin">
            <button
              type="button"
              id="kt_login_password_reset_form_cancel_button"
              className="btn btn-lg btn-light-primary fw-bolder"
              disabled={formik.isSubmitting}
            >
              {intl.formatMessage({ id: "back_to_login" })}
            </button>
          </Link>
        </div>
      </form>
    </>
  );
}

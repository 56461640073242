import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Circulars from "./Circulars";
import News from "./News";
import PrincipalMessage from "./PrincipalMessage";
import QuickContacts from "./QuickContacts";
import ReadMessage from "./ReadMessage";
import ReplyMessage from "./ReplyMessage";
import TeacherMessage from "./TeacherMessages";

const Communication = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/circulars`} component={Circulars} />
      <Route
        exact
        path={`${match.url}/teacher-messages`}
        component={TeacherMessage}
      />
      <Route
        exact
        path={`${match.url}/teacher-messages/read-message`}
        component={ReadMessage}
      />
      <Route
        exact
        path={`${match.url}/teacher-messages/reply-message`}
        component={ReplyMessage}
      />
      <Route
        path={`${match.url}/principal-message`}
        component={PrincipalMessage}
      />
      <Route path={`${match.url}/quick-contacts`} component={QuickContacts} />
      <Route path={`${match.url}/news`} component={News} />
    </Switch>
  );
};

export default Communication;

import { combineReducers } from "redux";
import Common from "./Common";
import Auth from "./Auth";
import { connectRouter } from 'connected-react-router';
import Settings from "./Settings";

export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  common: Common
});